import { gqlMutationClient } from "~/lib/backend";

const markTransactionAsDeliveredAction = async (transactionId: string) => {
  const resp = await gqlMutationClient()({
    markTransactionAsDelivered: [
      {
        transactionId,
      },
      {
        __typename: true,
        "...on GraphQLError": {
          message: true,
        },
        "...on MutationMarkTransactionAsDeliveredSuccess": {
          data: {
            id: true,
          },
        },
      },
    ],
  });

  if (
    !resp.markTransactionAsDelivered ||
    resp.markTransactionAsDelivered.__typename === "GraphQLError"
  ) {
    throw new Error(
      resp.markTransactionAsDelivered?.message ?? "Unexpected error"
    );
  }
};

export default markTransactionAsDeliveredAction;
