import { GraphQLTypes } from "~/__generated__/backend/zeus";
import { gqlMutationClient } from "~/lib/backend";

const updateTransactionAction = async (
  updatedTransaction: GraphQLTypes["UpdateTransactionInput"]
) => {
  const resp = await gqlMutationClient()({
    updateTransaction: [
      {
        transaction: updatedTransaction,
      },
      {
        __typename: true,
        "...on GraphQLError": {
          message: true,
        },
        "...on MutationUpdateTransactionSuccess": {
          data: {
            id: true,
          },
        },
      },
    ],
  });

  if (
    !resp.updateTransaction ||
    resp.updateTransaction.__typename === "GraphQLError"
  ) {
    throw new Error(resp.updateTransaction?.message ?? "Unexpected error");
  }
};

export default updateTransactionAction;
