import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { Edit } from "react-feather";
import { toast } from "react-toastify";
import Button from "../design-system-components/Button";
import NumberInput from "../design-system-components/input/NumberInput";

type Props = {
  value?: string | number;
  onSubmit: (newValue: string) => Promise<void>;
  title?: string;
  subtitle?: string;
  children?: unknown;
  type: string;
  min?: number;
  max?: number;
  prefix?: string;
  suffix?: string;
};

export default function EditValueButton({
  title,
  value,
  onSubmit: onUpdate,
  type,
  min,
  max,
  subtitle,
  suffix,
  prefix,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [v, setV] = useState<string>(value?.toString() ?? "");
  const formRef = useRef<HTMLFormElement>(null);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  };

  const onSubmit = async (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const promise = onUpdate(v);
    toast.promise(promise, {
      pending: "Updating",
      success: {
        render: () => {
          setIsOpen(false);
          return "Update success";
        },
      },
      error: {
        render: ({ data }) => {
          return data instanceof Error ? data?.message : "An error happened";
        },
      },
    });
    await promise;
  };

  // We need a use effect and event listener because the focus is already used on the input
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && formRef.current) {
        formRef.current.requestSubmit();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  return (
    <>
      <button onClick={openModal}>
        <Edit size={16} />
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-modal" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25 backdrop-blur" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all space-y-8">
                  <Dialog.Title as="h2" className="text-heading-2">
                    {title ?? "Edit value"}
                  </Dialog.Title>

                  <form ref={formRef} onSubmit={onSubmit}>
                    {subtitle && <p className="font-semibold">{subtitle}</p>}
                    {type === "number" && (
                      <NumberInput
                        fieldName={title ?? ""}
                        value={v}
                        min={min}
                        max={max}
                        suffix={suffix}
                        prefix={prefix}
                        onChange={(e) => setV(e)}
                      />
                    )}

                    {type === "text" && (
                      <input
                        type={type}
                        value={v}
                        onChange={(e) => setV(e.target.value)}
                        className="border-0 border-b border-grey focus:border-blue-500 w-full outline-0 my-4"
                      />
                    )}

                    <div className="mt-4 flex flex-row justify-end w-full space-x-4">
                      <Button
                        variant="SECONDARY"
                        disabled={loading}
                        onClick={closeModal}
                      >
                        Cancel
                      </Button>

                      <button
                        type="submit"
                        disabled={loading}
                        className="button-primary"
                      >
                        {loading ? "... Updating" : "Update"}
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
