import { FC } from "react";

const SectionDataRow: FC<{
  label: string;
  value?: string;
  missing?: boolean;
}> = ({ label, value, missing }) => {
  return (
    <div className="flex flex-row items-center space-x-4">
      <div className="text-body text-dark-gray w-32">{label}</div>

      {!missing && <div className="text-body text-very-dark-gray">{value}</div>}

      {missing && (
        <div className="text-body bg-error/20 text-error px-2 py-0.5 rounded uppercase">
          Missing
        </div>
      )}
    </div>
  );
};

export default SectionDataRow;
