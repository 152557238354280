import { AddressSchema } from "@thedealersconcierge/lib/codecs/schema/address";
import { EmploymentSchema } from "@thedealersconcierge/lib/codecs/schema/employment";
import { nullableObject } from "@thedealersconcierge/lib/codecs/util";
import { z } from "zod";
import { TransactionHardCreditQueryType } from "./_queries/hardCreditQuery";

const LendingPortalEmploymentSchema = nullableObject(
  EmploymentSchema.partial().extend({
    fullAddress: z.string().optional(),
  })
);

export type LendingPortalEmploymentSchema = z.TypeOf<
  typeof LendingPortalEmploymentSchema
>;

const LendingPortalAddressSchema = nullableObject(
  AddressSchema.partial().extend({
    fullAddress: z.string().optional(),
  })
);

export type LendingPortalAddressSchema = z.TypeOf<
  typeof LendingPortalAddressSchema
>;

export const parseAddressesFromUnknown = (
  addresses: unknown
): LendingPortalAddressSchema[] => {
  try {
    return LendingPortalAddressSchema.array().parse(addresses);
  } catch (e) {
    console.error("COULD NOT PARSE ADDRESSES", e);
    return [];
  }
};

export const parseEmploymentsFromUnknown = (
  employemnts: unknown
): LendingPortalEmploymentSchema[] => {
  try {
    return LendingPortalEmploymentSchema.array().parse(employemnts);
  } catch (e) {
    console.error("COULD NOT PARSE EMPLOYMENTS", employemnts, e);
    return [];
  }
};

export const extractEmployerAddresses = (
  transaction?: TransactionHardCreditQueryType["transaction"]
) => {
  if (!transaction) return [];

  const buyerMap =
    transaction.buyer?.employments?.edges?.map(
      (edge) => edge.node?.employerAddress
    ) ?? [];

  const coBuyerMap =
    transaction.coBuyer?.employments?.edges?.map(
      (edge) => edge.node?.employerAddress
    ) ?? [];

  return [...buyerMap, ...coBuyerMap];
};
