import { DashboardUserRole } from "@thedealersconcierge/lib/codecs/tdc";
import stringify from "json-stable-stringify";
import {
  GraphQLTypes,
  InputType,
  Selector,
} from "~/__generated__/backend/zeus";
import { gqlQueryClient } from "~/lib/backend";
import { Pagination } from "../../_dashboardUtils";

const userDashboarSingularNodeSelector = () => {
  return Selector("DealershipUsersConnectionEdge")({
    node: {
      role: true,
      user: {
        id: true,
        firstName: true,
        lastName: true,
        email: true,
        phoneNumber: true,
        userGroup: [
          {
            first: 1,
          },
          {
            edges: {
              node: {
                role: true,
                isSuspended: true,
              },
            },
          },
        ],
      },
      isSuspended: true,
    },
    cursor: true,
  });
};

const userSelector = (
  roles: DashboardUserRole[],
  pagination?: Pagination,
  dealershipId?: string,
  isSuspended?: boolean
) => {
  return Selector("Query")({
    dealership: [
      { id: dealershipId },
      {
        users: [
          {
            rolesToInclude: roles,
            first:
              pagination?.direction === "after"
                ? pagination.pageSize
                : undefined,
            last:
              pagination?.direction === "before"
                ? pagination.pageSize
                : undefined,
            after:
              pagination?.direction === "after" ? pagination.cursor : undefined,
            before:
              pagination?.direction === "before"
                ? pagination.cursor
                : undefined,
            isSuspended: isSuspended,
          },
          {
            totalCount: true,
            edges: userDashboarSingularNodeSelector(),
          },
        ],
      },
    ],
  });
};

export const userQuery = (
  roles: DashboardUserRole[],
  pagination?: Pagination,
  dealershipId?: string,
  isSuspended?: boolean
) => ({
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  enabled: Boolean(dealershipId),
  queryKey: ["staff-list", dealershipId, roles.sort(), stringify(pagination)],
  queryFn: async () =>
    gqlQueryClient()(
      userSelector(roles, pagination, dealershipId, isSuspended)
    ),
});

export type UserQueryType = InputType<
  GraphQLTypes["Query"],
  ReturnType<typeof userSelector>
>;

export type UserDashboardSingularNode = InputType<
  GraphQLTypes["DealershipUsersConnectionEdge"],
  ReturnType<typeof userDashboarSingularNodeSelector>
>["node"];
