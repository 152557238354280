import {
  VehicleBodyType,
  VehicleCondition,
  VehiclePrincipalPriorUse,
} from "~/__generated__/backend/zeus";

/**
 * This is a helper function. When adding new enums, they have to be included here as  well
 *
 * @param s
 * @returns
 */
export const stringToVehiclePrincipalPriorUse = (
  s: string
): VehiclePrincipalPriorUse | undefined => {
  const VehiclePrincipalPriorUseMap: {
    [key: string]: VehiclePrincipalPriorUse;
  } = {
    COMMERCIAL: VehiclePrincipalPriorUse.COMMERCIAL,
    DEMO: VehiclePrincipalPriorUse.DEMO,
    FLEET: VehiclePrincipalPriorUse.FLEET,
    LIVERY_VEHICLE: VehiclePrincipalPriorUse.LIVERY_VEHICLE,
    OTHER: VehiclePrincipalPriorUse.OTHER,
    PRIVATE_PASSENGER_VEHICLE:
      VehiclePrincipalPriorUse.PRIVATE_PASSENGER_VEHICLE,
    RENTAL_VEHICLE: VehiclePrincipalPriorUse.RENTAL_VEHICLE,
    TAXI: VehiclePrincipalPriorUse.TAXI,
  };

  return VehiclePrincipalPriorUseMap[s];
};

/**
 * This is a helper function. When adding new enums, they have to be included here as  well
 *
 * @param s
 * @returns
 */
export const stringToVehicleCondition = (
  s: string
): VehicleCondition | undefined => {
  const VehicleConditionMap: { [key: string]: VehicleCondition } = {
    EXCELLENT: VehicleCondition.EXCELLENT,
    GOOD: VehicleCondition.GOOD,
    SATISFACTORY: VehicleCondition.SATISFACTORY,
    UNSATISFACTORY: VehicleCondition.UNSATISFACTORY,
  };

  return VehicleConditionMap[s];
};

/**
 * This is a helper function. When adding new enums, they have to be included here as  well
 *
 * @param s
 * @returns
 */
export const stringToVehicleBodyType = (
  s: string
): VehicleBodyType | undefined => {
  const VehicleBodyTypeMap: { [key: string]: VehicleBodyType } = {
    COUPE: VehicleBodyType.COUPE,
    MINIVAN: VehicleBodyType.MINIVAN,
    OTHER: VehicleBodyType.OTHER,
    SEDAN: VehicleBodyType.SEDAN,
    SUV: VehicleBodyType.SUV,
  };

  return VehicleBodyTypeMap[s];
};
