import { VehicleBodyTypeSchema } from "@thedealersconcierge/lib/codecs/tdc";

const vehicleBodyTypeOptions = [
  { value: "SEDAN", label: "Sedan" },
  { value: "SUV", label: "SUV" },
  { value: "COUPE", label: "Coupe" },
  { value: "MINIVAN", label: "Minivan" },
  { value: "OTHER", label: "Other" },
];

// Function to parse and validate the label
export const parseAndValidateBodyType = (label?: string) => {
  if (!label) return label;
  const option = vehicleBodyTypeOptions.find(
    (option) => option.label.toLowerCase() === label.toLowerCase()
  );
  const value = option ? option.value : null;

  try {
    const parsed = VehicleBodyTypeSchema.parse(value);
    return parsed;
  } catch (e) {
    console.error(`Invalid body type: ${label}`);
    return label;
  }
};

export default vehicleBodyTypeOptions;
