import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment } from "react";
import { X } from "react-feather";

export default function Modal({
  children,
  isOpen,
  title,
  onClose,
  className,
  containerClassName,
  isShowTitle = true,
}: {
  children?: any;
  isOpen: boolean;
  isShowTitle?: boolean;
  title: string;
  onClose: () => void;
  className?: string;
  containerClassName?: string;
}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-modal items-center justify-center"
        onClose={onClose}
      >
        <div className="fixed inset-0 bg-black/25 backdrop-blur" />

        <div className="flex items-center justify-center z-20 left-[10%] top-[10%] fixed w-4/5 h-4/5">
          <Dialog.Panel
            className={classNames("flex flex-col", containerClassName)}
          >
            {isShowTitle && (
              <div className="flex flex-row items-center justify-between space-x-12 bg-dark-gray rounded-t-2xl py-6 px-12 text-white">
                <div className="text-heading-2">{title}</div>

                <div
                  className="w-6 h-6 cursor-pointer"
                  onClick={() => onClose()}
                >
                  <X />
                </div>
              </div>
            )}
            <div
              className={classNames(
                "bg-white rounded-b-2xl flex-grow",
                className
              )}
            >
              {children}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  );
}
