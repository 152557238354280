import { ValidationError } from "@tanstack/react-form";
import classNames from "classnames";
import { FC } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

// eslint-disable-next-line react-refresh/only-export-components
export const parseNumberInputValue = (
  value?: string | number
): number | undefined => {
  if (value && typeof value === "string") {
    return parseFloat(value.replace(/[^\d.]/g, "")); // This replaces all non-numeric characters except for "."
  }

  if (value && typeof value === "number") {
    return value;
  }

  return undefined;
};

const NumberInput: FC<{
  value: string;
  fieldName: string;
  prefix?: string;
  suffix?: string;
  required?: boolean;
  error?: ValidationError;
  labelText?: string;
  subtitleText?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  containerClassName?: string;
  min?: number;
  max?: number;
  fixedDecimalScale?: boolean; // Enable this to enable 2 decimal places
}> = ({
  value,
  fieldName,
  prefix,
  suffix,
  required,
  error,
  labelText,
  subtitleText,
  placeholder,
  disabled,
  onChange,
  containerClassName,
  min,
  max,
  fixedDecimalScale = true,
}) => {
  // This will ensure that we display empty text box when there is no value
  // The empty text are constrained to the min value
  const numericValue = parseNumberInputValue(value);
  const minValue = min !== undefined ? min : 0;
  const displayValue =
    numericValue === undefined || numericValue <= minValue
      ? ""
      : numericValue.toString();

  const handleValueChange: NumericFormatProps["onValueChange"] = (values) => {
    const { value: numericValue } = values;
    if (onChange) {
      onChange(numericValue);
    }
  };

  const isAllowed: NumericFormatProps["isAllowed"] = ({ floatValue }) => {
    if (floatValue === undefined) return true;
    if (max !== undefined && floatValue > max) return false;
    if (min !== undefined && floatValue < min) return false;
    return true;
  };

  return (
    <div className={classNames("flex flex-col space-y-2", containerClassName)}>
      <div className="relative flex flex-row border-b-[0.5px] z-0 text-body-1">
        <label htmlFor={fieldName} className="hidden">
          {labelText}
        </label>

        <NumericFormat
          prefix={prefix}
          value={displayValue}
          onValueChange={handleValueChange}
          isAllowed={isAllowed}
          placeholder={placeholder}
          aria-placeholder={placeholder}
          disabled={disabled}
          decimalScale={2}
          fixedDecimalScale={fixedDecimalScale}
          thousandSeparator=","
          className="h-8 px-2 space-x-2 outline-none placeholder:text-inactive disabled:disabled-input bg-transparent"
          suffix={suffix}
        />
      </div>

      <div className="flex flex-row">
        {subtitleText && !error && (
          <p className="text-body-3 ml-2 text-tertiary">{subtitleText}</p>
        )}

        {required && !error && (
          <p className="text-body-3 ml-2 text-tertiary italic">(required)</p>
        )}

        {error && <p className="text-body-3 ml-2 text-negative">{error}</p>}
      </div>
    </div>
  );
};

export default NumberInput;
