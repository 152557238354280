import { captureException } from "@sentry/react";
import { useSuspenseQueries } from "@tanstack/react-query";
import { Button, DataStatus, Tooltip } from "@thedealersconcierge/components";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import { format } from "date-fns";
import { useAtom } from "jotai";
import { FC, useState } from "react";
import { toast } from "react-toastify";
import {
  FormSubmissionStatus,
  FormSubmissionType,
  TransactionLifecycle,
  TransactionStatus,
} from "~/__generated__/backend/zeus";
import markTransactionAsDeliveredAction from "~/actions/transactions/markTransactionAsDeliveredAction";
import Spinner from "~/components/Spinner";
import withSuspense from "~/components/withSuspense";
import { gqlQueryClient } from "~/lib/backend";
import { getDateFromUnkown } from "~/lib/helpers";
import { queryClient } from "~/lib/query";
import { DealershipQueryType } from "~/query/dealershipQuery";
import { dealershipAtom } from "~/state";
import {
  TransactionQueryType,
  refetchTransactionQuery,
} from "../_queries/transactionQuery";
import ReqSignaturesConfirmModal from "./ReqSignaturesConfirmModal";

const TransactionDocuments: FC<{
  transaction: TransactionQueryType["transaction"];
  dealership: DealershipQueryType["dealership"];
}> = ({ transaction, dealership }) => {
  const [isMarkingTransactionAsDelivered, setIsMarkingTransactionAsDelivered] =
    useState(false);
  const [prePurchaseModalIsOpen, setPrePurchaseModalIsOpen] = useState(false);
  const [postPurchaseModalIsOpen, setPostPurchaseModalIsOpen] = useState(false);
  const [activeDealershipPerms] = useAtom(dealershipAtom);

  // Left here intentionally to exemplify how to debug and test suspense
  // useSuspenseDebug(2000);

  const [statusQueryPrePurchase, statusQueryPostPurchase] = useSuspenseQueries({
    queries: [
      {
        queryKey: ["statusQueryPrePurchase", transaction?.id],
        queryFn: async () =>
          gqlQueryClient()({
            transaction: [
              { id: transaction?.id ?? "no-transaction-id" },
              {
                hardCreditApplDmsSubmittedAt: true,
                // To check if they have submitted hard credit applications
                buyer: {
                  hasSubmittedHardCreditApplication: true,
                },
                coBuyer: {
                  hasSubmittedHardCreditApplication: true,
                },
                // Check if there are any missing documents to be signed (for green check mark)
                formSubmissions: [
                  {
                    filter: {
                      type: {
                        equals: FormSubmissionType.PRE_PURCHASE,
                      },
                      status: {
                        not: { equals: FormSubmissionStatus.FINAL },
                      },
                    },
                  },
                  {
                    __directives: `@include(if:${permissionChecker("viewDealJacket", activeDealershipPerms?.activeDealershipPerms)})`,
                    totalCount: true,
                    edges: { node: { id: true, status: true, type: true } },
                  },
                ],
              },
            ],
          }),
      },
      {
        queryKey: ["statusQueryPostPurchase", transaction?.id],
        queryFn: async () =>
          gqlQueryClient()({
            transaction: [
              { id: transaction?.id ?? "no-transaction-id" },
              {
                // Check if there are any missing documents to be signed (for green check mark)
                formSubmissions: [
                  {
                    filter: {
                      type: {
                        equals: FormSubmissionType.POST_PURCHASE,
                      },
                      status: {
                        not: { equals: FormSubmissionStatus.FINAL },
                      },
                    },
                  },
                  {
                    totalCount: true,
                    edges: { node: { id: true, status: true, type: true } },
                  },
                ],
              },
            ],
          }),
      },
    ],
  });

  /**
   * Completed when they are requested and none are left to sign
   */

  const completedPrePurchaseDocuments =
    Boolean(transaction?.requestedPrePurchaseFormsAt) &&
    statusQueryPrePurchase.data?.transaction?.formSubmissions?.totalCount === 0
      ? "positive"
      : "negative";

  /**
   * Completed when they are requested and none are left to sign
   */

  const completedPostPurchaseDocuments =
    Boolean(transaction?.requestedPostPurchaseFormsAt) &&
    statusQueryPostPurchase.data.transaction?.formSubmissions?.totalCount === 0
      ? "positive"
      : "negative";

  const requiresHca =
    transaction?.financeType === "FINANCE" ||
    transaction?.financeType === "LEASE";
  const hasCobuyer = Boolean(statusQueryPrePurchase.data.transaction?.coBuyer);
  // Hard credit application is required when finance and lease
  const submittedRequiredHca = Boolean(
    requiresHca
      ? statusQueryPrePurchase.data.transaction?.buyer
          ?.hasSubmittedHardCreditApplication
      : true
  );
  const submittedRequiredHcaCoBuyer = Boolean(
    requiresHca && hasCobuyer
      ? statusQueryPrePurchase.data.transaction?.coBuyer
          ?.hasSubmittedHardCreditApplication
      : true
  );

  // Disable if documents has been requested or hard credit application has not been received
  const hasVehicleForPurchase = Boolean(transaction?.vehicle);
  const hasStaffAssigned =
    transaction?.salesPerson &&
    transaction.salesManager &&
    transaction.fniManager;
  const disablePrePurchaseButton =
    // Disable if we already requested
    Boolean(transaction?.requestedPrePurchaseFormsAt) ||
    // Transaction needs to hold a vehicle
    !hasVehicleForPurchase ||
    // The buyer needs to have submitted credit application
    !submittedRequiredHca ||
    // The co-buyer, if present, needs to have submitted credit application
    !submittedRequiredHcaCoBuyer ||
    // All staff, except BDC, has to be assigned
    !hasStaffAssigned;

  // same, but for post
  // Note: As prepurchase docs were completed things like a vehicle already exists and should not be
  // tested again.
  const disablePostPurchaseButton =
    Boolean(transaction?.requestedPostPurchaseFormsAt) ||
    completedPrePurchaseDocuments !== "positive";

  const canRequestSignature = permissionChecker(
    "requestSignatures",
    activeDealershipPerms?.activeDealershipPerms
  );
  const handleMarkTransactionAsDeliverd = async () => {
    try {
      setIsMarkingTransactionAsDelivered(true);

      if (!transaction?.id) {
        throw new Error("No transaction ID");
      }

      await markTransactionAsDeliveredAction(transaction.id);
      await queryClient.resetQueries({
        queryKey: ["transaction", transaction.id],
      });

      toast.success("Marked transaction as Delivered");
    } catch (error) {
      captureException(error);
      toast.error("Failed to mark transaction as Delivered");
    } finally {
      setIsMarkingTransactionAsDelivered(false);
    }
  };

  return (
    <>
      <ReqSignaturesConfirmModal
        forLifeCycle={TransactionLifecycle.PRE_PURCHASE}
        transaction={transaction}
        isOpen={prePurchaseModalIsOpen}
        transactionId={transaction?.id ?? "no-transaction-id"}
        onError={(msg) => {
          toast.error(`An error happened: ${msg}`);
          setPrePurchaseModalIsOpen(false);
        }}
        onSuccess={async () => {
          toast.success(
            "Successfully requested signatures for pre-purchase documents"
          );
          setPrePurchaseModalIsOpen(false);
          await Promise.all([
            refetchTransactionQuery(transaction?.id ?? "no-transaction-id"),
            statusQueryPrePurchase.refetch(),
          ]);
        }}
        onCancel={() => setPrePurchaseModalIsOpen(false)}
      />

      <ReqSignaturesConfirmModal
        forLifeCycle={TransactionLifecycle.POST_PURCHASE}
        transaction={transaction}
        isOpen={postPurchaseModalIsOpen}
        transactionId={transaction?.id ?? "no-transaction-id"}
        onError={(msg) => {
          toast.error(`An error happened: ${msg}`);
          setPostPurchaseModalIsOpen(false);
        }}
        onSuccess={async () => {
          toast.success(
            "Successfully requested signatures for post-purchase documents"
          );
          setPostPurchaseModalIsOpen(false);
          await Promise.all([
            refetchTransactionQuery(transaction?.id ?? "no-transaction-id"),
            statusQueryPostPurchase.refetch(),
          ]);
        }}
        onCancel={() => setPostPurchaseModalIsOpen(false)}
      />

      {dealership?.hasEnabledComplianceForms && (
        <div className="rounded-2xl bg-white shadow-md p-8 space-y-8">
          <div className="flex flex-row justify-between">
            <h2 className="text-heading-1">Forms</h2>

            {!dealership.hasEnabledPostPurchaseDocs &&
              transaction?.status === TransactionStatus.READY_FOR_FNI && (
                <Button
                  label='Mark as "Delivered"'
                  variant="SECONDARY"
                  isLoading={isMarkingTransactionAsDelivered}
                  onClick={handleMarkTransactionAsDeliverd}
                />
              )}
          </div>

          {/* In the future, maybe an error message? */}
          {/* <p className="text-sm font-light italic">
            The dealership does not have compliance forms activated. Contact The
            Dealers Concierge for more information.
          </p> */}

          <>
            {!submittedRequiredHca && (
              <p className="text-sm font-light italic">
                Requesting additional signatures will be enabled when the hard
                credit application has been submitted
              </p>
            )}

            <div className="flex flex-col gap-4">
              <div className="flex flex-row items-center">
                {/**
                 * Pre-purchase forms
                 */}
                <div className="flex flex-row space-x-6 w-1/2">
                  <div className="relative">
                    <DataStatus
                      status={
                        completedPrePurchaseDocuments === "positive"
                          ? "COMPLETE"
                          : "INCOMPLETE"
                      }
                    />
                  </div>

                  <div className="text-body text-dark-gray">
                    Pre-Purchase Forms
                  </div>
                </div>

                {canRequestSignature && (
                  <Tooltip
                    anchor={
                      <Button
                        label="Request Signatures"
                        variant="GHOST"
                        size="SMALL"
                        onClick={() => {
                          setPrePurchaseModalIsOpen(true);
                        }}
                        disabled={disablePrePurchaseButton}
                        dataTestId="request-pre-purchase-signature-button"
                      />
                    }
                    content={
                      <div className="flex flex-col space-y-2">
                        {Boolean(transaction?.requestedPrePurchaseFormsAt) && (
                          <p className="text-inverse">
                            Requested on{" "}
                            {format(
                              getDateFromUnkown(
                                transaction?.requestedPrePurchaseFormsAt
                              ),
                              "MM/dd/yyyy"
                            )}
                          </p>
                        )}

                        <DataStatus
                          label="Vehicle for purchase"
                          status={
                            hasVehicleForPurchase ? "COMPLETE" : "INCOMPLETE"
                          }
                          size="SMALL"
                          labelClassName="text-inverse"
                        />

                        <DataStatus
                          label="Staff assigned"
                          status={hasStaffAssigned ? "COMPLETE" : "INCOMPLETE"}
                          size="SMALL"
                          labelClassName="text-inverse"
                        />

                        {requiresHca && (
                          <DataStatus
                            label="Credit application submitted"
                            status={
                              statusQueryPrePurchase.data.transaction?.buyer
                                ?.hasSubmittedHardCreditApplication
                                ? "COMPLETE"
                                : "INCOMPLETE"
                            }
                            size="SMALL"
                            labelClassName="text-inverse"
                          />
                        )}

                        {requiresHca && hasCobuyer && (
                          <DataStatus
                            label="Credit application submitted - Co-Buyer"
                            status={
                              statusQueryPrePurchase.data.transaction?.coBuyer
                                ?.hasSubmittedHardCreditApplication
                                ? "COMPLETE"
                                : "INCOMPLETE"
                            }
                            size="SMALL"
                            labelClassName="text-inverse"
                          />
                        )}
                      </div>
                    }
                  />
                )}
              </div>

              {dealership.hasEnabledPostPurchaseDocs && (
                <div className="flex flex-row items-center">
                  {/**
                   * Post-purchase forms
                   */}
                  <div className="flex flex-row space-x-6 w-1/2">
                    <div className="relative">
                      <DataStatus
                        status={
                          completedPostPurchaseDocuments === "positive"
                            ? "COMPLETE"
                            : "INCOMPLETE"
                        }
                      />
                    </div>

                    <div className="text-body text-dark-gray col-span-3">
                      Post-Purchase Forms
                    </div>
                  </div>

                  {canRequestSignature && (
                    <Tooltip
                      anchor={
                        <Button
                          label="Request Signatures"
                          variant="GHOST"
                          size="SMALL"
                          onClick={() => {
                            setPostPurchaseModalIsOpen(true);
                          }}
                          disabled={disablePostPurchaseButton}
                          dataTestId="request-post-purchase-signature-button"
                        />
                      }
                      content={
                        <div className="flex flex-col space-y-2">
                          {Boolean(
                            transaction?.requestedPostPurchaseFormsAt
                          ) && (
                            <p className="text-inverse">
                              Requested on{" "}
                              {format(
                                getDateFromUnkown(
                                  transaction?.requestedPostPurchaseFormsAt
                                ),
                                "MM/dd/yyyy"
                              )}
                            </p>
                          )}

                          <DataStatus
                            label="Pre-purchase forms signed"
                            status={
                              completedPrePurchaseDocuments === "positive"
                                ? "COMPLETE"
                                : "INCOMPLETE"
                            }
                            size="SMALL"
                            labelClassName="text-inverse"
                          />
                        </div>
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default withSuspense(
  TransactionDocuments,
  <div className="rounded-2xl bg-white shadow-md flex size-full justify-center align-middle">
    <Spinner />
  </div>
);
