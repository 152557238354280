import { parseISO } from "date-fns";
import { parsePhoneNumber } from "react-phone-number-input";

export const getInitials = (firstName?: string, lastName?: string) => {
  if (firstName && lastName) {
    return `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`.toUpperCase();
  }

  if (firstName && !lastName) {
    return firstName.substring(0, 1).toUpperCase();
  }

  if (!firstName && lastName) {
    return lastName.substring(0, 1).toUpperCase();
  }

  return "?";
};

export const getDateFromUnkown = (date: unknown): Date => {
  // If the object is a date, typeof returns "object"
  if (typeof date === "object") {
    return date as Date;
  }

  return parseISO(String(date));
};

export const formatPhoneNumber = (phoneNumber?: string) => {
  return phoneNumber
    ? parsePhoneNumber(phoneNumber)?.format(
        parsePhoneNumber(phoneNumber)?.country === "US"
          ? "NATIONAL"
          : "INTERNATIONAL"
      )
    : undefined;
};

export const tokenizeQuery = (query: string): string[] => {
  // Convert to lowercase, remove punctuation, and split by whitespace
  return query
    .toLowerCase()
    .replace(/[^\w\s]/gi, "")
    .split(/\s+/)
    .filter((token) => token.length > 0);
};
