import { z } from "zod";
import { nullableObject } from "../util";

export const AddressSchema = z.object({
  street: z.string(),
  apartmentDetails: z.string(),
  zipCode: z.string(),
  city: z.string(),
  state: z.string(),
  timelinePosition: z.number(),
  housingStatus: z.union([
    z.literal("OWNING"),
    z.literal("RENTING"),
    z.literal("LIVING_WITH_RELATIVES"),
    z.literal("OTHER"),
  ]),
  monthlyPayment: z.number(),
  durationYears: z.number(),
  durationMonths: z.number(),
});

export type AddressSchema = z.TypeOf<typeof AddressSchema>;

export const AdfXmlAddressSchema = nullableObject(
  AddressSchema.pick({
    street: true,
    apartmentDetails: true,
    zipCode: true,
    city: true,
    state: true,
    timelinePosition: true,
  })
);

export type AdfXmlAddressSchema = z.TypeOf<typeof AdfXmlAddressSchema>;
