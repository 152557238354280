import { Menu } from '@headlessui/react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import classNames from 'classnames';
import { FC, Fragment } from 'react';
import InputBase from '..';
import Dropdown from '../dropdown';
import { DropdownOption } from '../dropdown/dropdownConfig';
import { InputBackgroundType } from '../inputConfig';

const Select: FC<{
  options: DropdownOption[];
  value?: string;
  label: string;
  assistiveMessage: string;
  errorMessage?: string;
  placeholder: string;
  onSelect: (option: DropdownOption) => Promise<void> | void;
  required: boolean;
  disabled: boolean;
  backgroundType?: InputBackgroundType;
}> = ({
  options,
  value,
  label,
  assistiveMessage,
  errorMessage,
  placeholder,
  onSelect,
  required,
  disabled,
  backgroundType = 'DARK'
}) => {
  return (
    <Menu>
      <Menu.Button className="relative outline-none w-full">
        {({ open }) => {
          return (
            <Fragment>
              <InputBase
                inputComponent={
                  <Fragment>
                    <p
                      className={classNames(
                        'text-start body-01 w-full px-spacing-02 py-[6px] text-primary outline-none bg-transparent',
                        {
                          'text-tertiary':
                            disabled || !value || value.length === 0
                        }
                      )}
                    >
                      {value && value.length > 0
                        ? (options.find((o) => o.value === value)?.label ??
                          value)
                        : placeholder}
                    </p>

                    <div className="relative flex size-10 items-center justify-center">
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  </Fragment>
                }
                label={label}
                assistiveMessage={assistiveMessage}
                required={required}
                disabled={disabled}
                isBeingUpdated={open}
                errorMessage={errorMessage}
                backgroundType={backgroundType}
                inputContainerClassName={classNames({
                  'border-interactive': open
                })}
              />

              <Menu.Items className="absolute outline-none -mt-4 w-full z-dropdown">
                <Dropdown
                  options={options}
                  onSelect={onSelect}
                  className="w-full"
                />
              </Menu.Items>
            </Fragment>
          );
        }}
      </Menu.Button>
    </Menu>
  );
};

export default Select;
