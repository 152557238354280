import classNames from "classnames";
import { FC } from "react";
import SpinnerIcon from "./icons/SpinnerIcon";

const Spinner: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className="relative">
      <SpinnerIcon
        className={classNames(
          "w-10 aspect-square animate-spin text-gray-300 fill-primary-blue",
          className
        )}
      />
    </div>
  );
};

export default Spinner;
