import { ReactNode, useCallback, useState } from "react";
import Button from "../design-system-components/Button";

type ModalSize = "NORMAL" | "LARGE";

type ConfirmationModalProps = {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  content: ReactNode;
  size?: ModalSize;
};

// TODO: Might need to enable click outside of window closing
const ConfirmationModal = ({
  visible,
  onConfirm,
  onCancel,
  title,
  content,
  size = "NORMAL",
}: ConfirmationModalProps) => {
  if (!visible) return null;

  const modalWidthClass = size === "LARGE" ? "w-128" : "w-96";
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-25">
      <div className={`bg-white rounded-md p-6 shadow-lg ${modalWidthClass}`}>
        <h2 className="text-xl font-semibold mb-4">{title}</h2>
        <div className="mb-6">{content}</div>
        <div className="flex justify-end gap-4">
          <Button variant="DANGER" onClick={onCancel} size="SMALL">
            Cancel
          </Button>
          <Button variant="PRIMARY" onClick={onConfirm} size="SMALL">
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

type ShowModalProps = {
  title: string;
  content: ReactNode;
  size?: ModalSize;
};

/**
 * useConfirmationModal - Usage Example:
 *
 * 1. Initialize the hook within your component
 *    Make sure to deconstruct the "showModal" and "ConfirmationModalComponent":
 *
 *    const { showModal, ConfirmationModalComponent } = useConfirmationModal();
 *
 * 2. Render the `ConfirmationModalComponent` in your component's JSX (it can be anywhere):
 *    return (
 *      <div>
 *        <button onClick={handleDelete}>Delete Item</button>
 *        {ConfirmationModalComponent}
 *      </div>
 *    );
 *
 * 3. Use the `showModal` function to display the modal and get the value back as promise
 *    const confirmed = await showModal({
 *      title: 'Delete Item',
 *      content: 'Are you sure you want to delete this item?',
 *    });
 */

const useConfirmationModal = () => {
  const [visible, setVisible] = useState(false);

  // We store the callback so we can escalate the values later
  // This also enables us to return the value from promise when the user confirm or cancel the modal
  const [resolveCallback, setResolveCallback] = useState<
    ((value: boolean) => void) | null
  >(null);

  // Title and content needs to set as a state since this can be dynamic
  // In example in a page we can have more than 1 showModal
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalContent, setModalContent] = useState<ReactNode>("");
  const [modalSize, setModalSize] = useState<ModalSize>("NORMAL");

  const showModal = useCallback(
    ({ title, content, size }: ShowModalProps): Promise<boolean> => {
      setModalTitle(title);
      setModalContent(content);
      setModalSize(size ?? "NORMAL");
      setVisible(true);

      return new Promise<boolean>((resolve) => {
        setResolveCallback(() => resolve);
      });
    },
    []
  );

  const ConfirmationModalComponent = (
    <ConfirmationModal
      visible={visible}
      onConfirm={() => {
        setVisible(false);
        if (resolveCallback) resolveCallback(true);
      }}
      onCancel={() => {
        setVisible(false);
        if (resolveCallback) resolveCallback(false);
      }}
      title={modalTitle}
      content={modalContent}
      size={modalSize}
    />
  );

  // We return the showModal and the ConfirmationModalComponent that is already loaded with proper title and content
  return { showModal, ConfirmationModalComponent };
};

export { ConfirmationModal, useConfirmationModal };
