import i18n from "i18next";
import { initReactI18next } from "react-i18next";

void i18n.use(initReactI18next).init({
  debug: false, //!config.isProduction,

  // We use sentences as keys
  nsSeparator: false,
  keySeparator: false,
  fallbackLng: false,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
