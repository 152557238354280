import { useForm } from "@tanstack/react-form";
import { Button } from "@thedealersconcierge/components";
import { useState } from "react";
import { forgottenPasswordAction } from "~/actions/cognitoForgottenPasswordAction";
import { UiState } from "./types";

type Props = {
  setUiState: (state: UiState) => void;
  setEmail: (password: string) => void;
};

export default function ForgottenPassword({ setUiState, setEmail }: Props) {
  const [error, setError] = useState<null | string>(null);

  const form = useForm({
    defaultValues: {
      email: "",
    },
    onSubmit: async (value) => {
      try {
        await forgottenPasswordAction(value.email);
        setEmail(value.email);
        setUiState("FORGOT_PASSWORD_RESET_CODE_SEND");
      } catch (e: any) {
        setError(e.message);
      }
    },
  });

  return (
    <form.Provider>
      <form
        className="rounded-2xl shadow-2xl w-[444px] flex flex-col space-y-8 items-center p-6"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
      >
        <h2 className="text-2xl font-bold">Forgotten Password</h2>

        <p>Enter your email to receive instruction to reset your account.</p>

        <form.Field
          name="email"
          children={(field) => (
            <input
              name={field.name}
              value={field.state.value ?? ""}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              placeholder="Email"
              type="email"
              className="w-full border border-[#E9EBED] p-4 rounded-lg"
            />
          )}
        />

        {error && (
          <div className="w-full rounded-md bg-red-500 text-white font-semibold p-4">
            {error}
          </div>
        )}

        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit, isSubmitting]) => (
            <Button
              label="Reset Password"
              type="submit"
              disabled={!canSubmit}
              isLoading={isSubmitting}
            />
          )}
        />
      </form>
    </form.Provider>
  );
}
