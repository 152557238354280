import {
  GraphQLTypes,
  InputType,
  OrderBy,
  Selector,
} from "~/__generated__/backend/zeus";
import { gqlQueryClient } from "~/lib/backend";

const hardCreditUserSelector = Selector("Customer")({
  userId: true,
  firstName: true,
  lastName: true,
  middleName: true,
  hasCoBuyer: true,
  // Inviriant: Ordered by created
  hardCreditApplications: [
    {
      first: 1,
      orderBy: { createdAt: OrderBy.Desc },
    },
    {
      edges: {
        node: {
          id: true,
          createdAt: true,
          employments: true,
          residentialAddresses: true,
          homePhoneNumber: true,
        },
      },
    },
  ],
  employments: [
    {
      first: 20,
    },
    {
      edges: {
        node: {
          employerAddressId: true,
          employerAddress: {
            id: true,
            fullAddress: true,
          },
        },
      },
    },
  ],
  socialSecurityNumber: true,
  user: {
    email: true,
    phoneNumber: true,
  },
  birthdate: true,
});

// This selector is used for hard credit application details
const transactionHardCreditSelector = (transactionId: string) =>
  Selector("Query")({
    transaction: [
      {
        id: transactionId,
      },
      {
        dealership: {
          hasEnabledDealerTrack: true,
          hasEnabledRoute1: true,
        },
        id: true,
        buyerId: true,
        buyer: hardCreditUserSelector,
        coBuyerId: true,
        coBuyer: hardCreditUserSelector,
        hardCreditApplDmsSubmittedAt: true,
        customerSharedData: {
          coBuyerName: true,
        },
      },
    ],
  });

export const transactionHardCreditQuery = (transactionId: string) => ({
  queryKey: ["transactionHardCreditQuery", transactionId],
  queryFn: async () =>
    gqlQueryClient()(transactionHardCreditSelector(transactionId)),
});

export type TransactionHardCreditQueryType = InputType<
  GraphQLTypes["Query"],
  ReturnType<typeof transactionHardCreditSelector>
>;
