import { useSuspenseQuery } from "@tanstack/react-query";
import { DataStatus, Tooltip } from "@thedealersconcierge/components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ComplianceStatus, Selector } from "~/__generated__/backend/zeus";
import Banner from "~/components/Banner";
import Spinner from "~/components/Spinner";
import withSuspense from "~/components/withSuspense";
import { gqlQueryClient } from "~/lib/backend";

const selectAll = Selector("ComplianceField")({
  title: true,
  description: true,
  status: true,
});

const toStatus = (t: ComplianceStatus) => {
  switch (t) {
    case ComplianceStatus.OK:
      return "COMPLETE";
    case ComplianceStatus.WARN:
      return "REQUIRES_ATTENTION";
    case ComplianceStatus.ERROR:
      return "REQUIRES_ATTENTION";
    case ComplianceStatus.INFO:
      return "INCOMPLETE";
  }
};

const TransactionCompliance: FC<{
  transactionId: string;
  userId: string;
}> = ({ transactionId, userId }) => {
  const { t } = useTranslation();

  // Left here intentionally to exemplify how to debug and test suspense
  // useSuspenseDebug(1000);

  const compliance = useSuspenseQuery({
    // Refetch every 10 seconds
    // Note: remove this is we see performance issues
    refetchInterval: 10000,
    queryKey: ["compliance", transactionId],
    queryFn: async () => {
      return await gqlQueryClient()({
        customer: [
          {
            transactionId,
            userId,
          },
          {
            compliance: {
              id: selectAll,
              idQuizSummary: selectAll,
              ofacStatus: selectAll,
              redFlagStatus: selectAll,
              recalls: selectAll,
            },
          },
        ],
      });
    },
  });

  if (!compliance.data.customer) {
    return (
      <div className="rounded-2xl bg-white shadow-md p-8 space-y-8 size-full">
        <Banner variant="WARNING">
          <p>{t("There is some data you might not have access to see")}</p>
        </Banner>
      </div>
    );
  }

  return (
    <div className="rounded-2xl bg-white shadow-md p-8 space-y-8 size-full">
      <div className="flex flex-row space-x-2 items-center">
        <h2 className="text-heading-1">{t("Compliance")}</h2>
      </div>

      <div className="grid grid-cols-2 gap-4">
        {[
          compliance.data.customer.compliance.id,
          compliance.data.customer.compliance.idQuizSummary,
          compliance.data.customer.compliance.ofacStatus,
          compliance.data.customer.compliance.redFlagStatus,
          compliance.data.customer.compliance.recalls,
        ].map((e) => {
          return (
            <div
              key={e.title}
              className="flex flex-row justify-start items-center"
            >
              <Tooltip
                anchor={
                  <div>
                    <DataStatus status={toStatus(e.status)} />
                  </div>
                }
                content={
                  <div className="flex flex-col space-y-2">{e.description}</div>
                }
              />

              <div className="text-body text-dark-gray pl-6">{e.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withSuspense(
  TransactionCompliance,
  <div className="rounded-2xl bg-white shadow-md flex size-full justify-center align-middle">
    <Spinner />
  </div>
);
