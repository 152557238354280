import { FC } from "react";
import { getReadableHoustingStatus } from "~/lib/enumReadable";
import { LendingPortalAddressSchema } from "../_utils";

const CreditApplicationAddressDetailCard: FC<{
  address?: LendingPortalAddressSchema;
}> = ({ address }) => {
  const getFullAddress = () => {
    if (!address) return "-";
    return `${address.street} ${address.city}, ${address.state} ${address.zipCode}`;
  };

  if (!address) return;

  // This is done because inline condition will result in one of the timeline number to get printed
  // E.g: Current Address and Housing Status0
  const getAddressStatus = (pos: number | null | undefined) => {
    if (typeof pos !== "number") {
      console.error("FAILED TO GET ADDRESS TIMELINE POSITION", pos);
      return;
    }
    if (pos === 0) return "Current Address and Housing Status";
    if (pos === 1) return "Previous Address and Housing Status";
    if (pos === 2) return "2nd Previous Address and Housing Status";
    if (pos === 3) return "3rd Previous Address and Housing Status";
    if (pos > 3) return `${pos}th Previous Address and Housing Status`;
  };

  return (
    <div className="flex flex-col px-8 py-8 gap-8 rounded-lg shadow-border">
      <p className="text-heading-2 font-bold">
        {getAddressStatus(address.timelinePosition)}
      </p>

      <div className="grid grid-cols-5 gap-8">
        <div className="flex flex-col gap-2">
          <p className="text-body text-dark-gray">Address</p>
          <p>{getFullAddress()}</p>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-body text-dark-gray">Time at Address</p>
          <p>
            {address?.durationYears ?? "0"} years /{" "}
            {address?.durationMonths ?? "0"} months
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-body text-dark-gray">Housing Status</p>
          <p>{getReadableHoustingStatus(address.housingStatus)}</p>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-body text-dark-gray">Monthly Payment</p>
          <p>
            {address.monthlyPayment
              ? `$${address.monthlyPayment.toLocaleString()} / Month`
              : "$0"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CreditApplicationAddressDetailCard;
