import { Menu, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import classNames from "classnames";
import { useAtom } from "jotai";
import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import logoutAction from "~/actions/logoutAction";
import Button from "~/components/Button";
import Dropdown from "~/components/design-system-components/Dropdown";
import CarGarageIcon from "~/components/icons/CarGarageIcon";
import LogoIcon from "~/components/icons/LogoIcon";
import ProfileIcon from "~/components/icons/ProfileIcon";
import { getInitials } from "~/lib/helpers";
import meQuery from "~/query/meQuery";
import { Link, useModals } from "~/router";
import { dealershipAtom } from "~/state";

export default function Layout() {
  const modals = useModals();
  const { data } = useQuery(meQuery());
  const [dealership, setDealership] = useAtom(dealershipAtom);

  const me = data?.me;

  const showNewTransactionButton = permissionChecker(
    "createTransction",
    dealership?.activeDealershipPerms
  );

  const handleNewTransaction = () => {
    modals.open("/dashboard/transaction/create");
  };

  const changeDealership = (v: string) => {
    const dp = me?.dealershipPerms?.find((dshp) => dshp.dealershipId === v);

    if (!dp) {
      return;
    }

    setDealership((r) => ({
      ...r,
      activeDealershipPerms: {
        ...dp,
        dealershipId: dp.dealershipId ?? "no-dealership-id",
        role: dp.role ?? "SALES_PERSON", // This should never happen
        dealershipName: dp.dealershipName ?? "no-dealership-name",
      },
    }));
  };

  const canManageUser = permissionChecker(
    "manageUsers",
    dealership?.activeDealershipPerms
  );

  return (
    <main className="flex min-h-dvh max-h-dvh flex-col space-y-10 bg-[#F8F9FA] font-roboto px-16 overflow-y-scroll">
      <nav className="flex flex-col items-center space-y-11">
        <div className="flex flex-row">
          <div className="flex bg-very-light-blue w-6 h-6">
            <div className="bg-[#F8F9FA] flex flex-1 rounded-tr-full" />
          </div>

          <div className="font-medium text-primary-blue bg-very-light-blue py-2.5 px-6 rounded-b-3xl">
            {(me?.dealershipPerms?.length ?? 0) >= 2 ? (
              <Dropdown
                transparent
                size="SM"
                onSelect={(v: string) => {
                  changeDealership(v);
                }}
                value={dealership?.activeDealershipPerms.dealershipId}
                options={
                  me?.dealershipPerms?.map((dp) => {
                    return {
                      label: dp.dealershipName ?? "no-dealership-name",
                      value: dp.dealershipId ?? "no-dealership-id",
                    };
                  }) ?? []
                }
              />
            ) : (
              <p>{dealership?.activeDealershipPerms.dealershipName} </p>
            )}
          </div>

          <div className="flex bg-very-light-blue w-6 h-6">
            <div className="bg-[#F8F9FA] flex flex-1 rounded-tl-full" />
          </div>
        </div>

        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-row space-x-6">
            <Link to={"/dashboard"} className="relative w-24">
              <LogoIcon className="h-12 text-primary-blue" />
            </Link>

            {showNewTransactionButton && (
              <Button
                variant="PRIMARY"
                size="BIG"
                className="h-12"
                onClick={handleNewTransaction}
              >
                <div className="flex flex-row space-x-2 items-center">
                  <div
                    className="uppercase text-xl leading-none"
                    data-test-id="new-transaction-button"
                  >
                    New
                  </div>

                  <div className="text-2xl mb-1">+</div>
                </div>
              </Button>
            )}
          </div>

          <div className="flex flex-row space-x-6 items-center">
            <Link
              to={"/dashboard/customers"}
              className="flex flex-row space-x-2 px-3 py-2 rounded-full bg-light-gray"
            >
              <div className="relative">
                <ProfileIcon className="w-4 aspect-square text-dark-gray" />
              </div>

              <div className="text-base font-roboto font-medium text-dark-gray">
                Customers
              </div>
            </Link>

            {canManageUser && (
              <Link
                to={"/dashboard/users"}
                className="flex flex-row space-x-2 px-3 py-2 rounded-full bg-light-gray"
              >
                <div className="relative">
                  <CarGarageIcon className="w-6 aspect-square text-dark-gray" />
                </div>

                <div className="text-base font-roboto font-medium text-dark-gray">
                  Staff
                </div>
              </Link>
            )}

            <div className="flex">
              <Menu
                as="div"
                className="inline-block relative text-left font-roboto"
              >
                <Menu.Button className="flex w-14 aspect-square bg-light-gray items-center justify-center text-base text-dark-gray font-medium rounded-full overflow-hidden focus:outline-none">
                  {/* We don't have the concept of a profile picture, but one could use the active transaction customers profile picture here.
                  TODO: Figure out what to do. */}
                  {/* {me?.user.profilePicture?.url ? (
                    <img
                      src={me.user.profilePicture.url}
                      className="w-full h-full"
                      style={{ objectFit: "cover" }}
                    />
                  ) : (
                    <span>
                      {getInitials(me?.user.firstName, me?.user.lastName)}
                    </span>
                  )} */}

                  <span>
                    {getInitials(me?.user?.firstName, me?.user?.lastName)}
                  </span>
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 origin-top-right divide-y divide-light-gray rounded-lg bg-white shadow-lg focus:outline-none w-40 z-dropdown">
                    <Menu.Item>
                      {({ active }) => (
                        <Link to={"/profile"}>
                          <button
                            className={classNames(
                              "text-body w-full px-4 py-2 text-start text-dark-gray",
                              {
                                "bg-very-light-blue": active,
                              }
                            )}
                          >
                            Profile
                          </button>
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => void logoutAction()}
                          className={classNames(
                            "text-body w-full px-4 py-2 text-start text-dark-gray",
                            {
                              "bg-very-light-blue": active,
                            }
                          )}
                        >
                          Logout
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </nav>

      <Outlet />
    </main>
  );
}
