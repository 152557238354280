import { confirmSignIn } from "aws-amplify/auth";
import config from "~/config";
import { postAuthenticationAction } from "./postAuthenticationAction";

export const cognitoSubmitMfa = async (code: string) => {
  if (!config.cognito.clientId) {
    throw new Error("Cognito client id is not set");
  }
  const res = await confirmSignIn({
    challengeResponse: code,
  });

  await postAuthenticationAction();
  return res;
};
