import { FC } from "react";

const CheckMarkIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>Check mark icon</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 6.87894L9.66963 18.7093L4.25 13.8317L5.79108 12.1193L9.58615 15.5349L19.8711 5.25L21.5 6.87894Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckMarkIcon;
