import { ChangeEvent, FC } from "react";

type UploadFileButtonProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  file?: File | null;
};

const truncateString = (str: string, num: number): string => {
  if (str.length <= num) return str;
  return str.slice(0, num) + "...";
};

const UploadFileButton: FC<UploadFileButtonProps> = ({ onChange, file }) => {
  return (
    <div className="inline-flex items-center justify-center">
      <label className="flex items-center justify-center px-4 py-1 bg-white border-2 border-blue-500 rounded-md shadow-sm cursor-pointer text-blue-500 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
        <span className="font-semibold text-sm">
          {file ? truncateString(file.name, 50) : "Browse files"}
        </span>
        <input type="file" className="hidden" onChange={onChange} />
      </label>
    </div>
  );
};

export default UploadFileButton;
