import classNames from "classnames";
import React from "react";
import { ChevronRight } from "react-feather";

interface PaginationProps {
  currentPage: number;
  totalCount: number;
  pageSize: number;
  onPageChange: (page: number, direction: "before" | "after") => void;
}

const PaginationNavigator: React.FC<PaginationProps> = ({
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalCount / pageSize);

  return (
    <div className="flex flex-row space-x-2 items-center text-dark-gray">
      <button
        className={classNames(
          "flex w-8 aspect-square rounded bg-light-gray justify-center items-center",
          { "opacity-40": !(currentPage > 1) }
        )}
        disabled={!(currentPage > 1)}
        onClick={() => onPageChange(currentPage - 1, "before")}
      >
        <div className="relative">
          <ChevronRight className="w-4 aspect-square rotate-180" />
        </div>
      </button>

      <div className="flex flex-row space-x-2">
        <div className="flex h-8 px-2 rounded justify-center items-center bg-light-gray">
          {currentPage} / {totalPages}
        </div>
      </div>

      <button
        className={classNames(
          "flex w-8 aspect-square rounded bg-light-gray justify-center items-center",
          {
            "opacity-40": !(totalPages > currentPage),
          }
        )}
        disabled={!(totalPages > currentPage)}
        onClick={() => onPageChange(currentPage + 1, "after")}
      >
        <div className="relative">
          <ChevronRight className="w-4 aspect-square" />
        </div>
      </button>
    </div>
  );
};

export default PaginationNavigator;
