import { useForm } from "@tanstack/react-form";
import { Button } from "@thedealersconcierge/components";
import { useState } from "react";
import { cognitoLoginAction } from "~/actions/cognitoLoginAction";
import { loginAction } from "~/actions/loginAction";
import config from "~/config";
import { useNavigate } from "~/router";
import { UiState } from "./types";

type Props = {
  setUiState: (state: UiState) => void;
  setEmail: (password: string) => void;
};

export default function SignIn({ setUiState, setEmail }: Props) {
  const navigate = useNavigate();
  const [error, setError] = useState<null | string>(null);

  const handleFormChange = (key: string, value?: string | boolean) => {
    if (!value) {
      return "This field is required";
    }
  };

  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    onSubmit: async (value) => {
      if (config.useCognito) {
        try {
          const [res] = await cognitoLoginAction(value.email, value.password);

          if (res === "OK") {
            navigate("/dashboard");
            return;
          }
          setEmail(value.email);
          setUiState(res);
        } catch (e: any) {
          setError(e.message);
        }
      } else {
        try {
          await loginAction(value.email, value.password);

          navigate("/dashboard");
        } catch (e: any) {
          setError(e.message);
        }
      }
    },
  });

  return (
    <form.Provider>
      <form
        className="rounded-2xl shadow-2xl w-[444px] flex flex-col space-y-8 items-center p-6"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
      >
        <h2 className="text-2xl font-bold">Login</h2>

        <form.Field
          name="email"
          onChange={(value) => handleFormChange("email", value)}
          children={(field) => (
            <input
              data-test-id="signin-email"
              name={field.name}
              value={field.state.value ?? ""}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              placeholder="Email"
              type="email"
              className="w-full border border-[#E9EBED] p-4 rounded-lg"
            />
          )}
        />

        <form.Field
          name="password"
          onChange={(value) => handleFormChange("password", value)}
          children={(field) => (
            <input
              data-test-id="signin-password"
              name={field.name}
              value={field.state.value ?? ""}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              placeholder="Password"
              type="password"
              className="w-full border border-[#E9EBED] p-4 rounded-lg"
            />
          )}
        />

        {error && (
          <div className="w-full rounded-md bg-red-500 text-white font-semibold p-4">
            {error}
          </div>
        )}

        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit, isSubmitting]) => (
            <Button
              label="Login"
              dataTestId="signin-submit"
              type="submit"
              disabled={!canSubmit}
              isLoading={isSubmitting}
            />
          )}
        />

        {/* Only works with cognito */}
        <div>
          Forgot password?{" "}
          <button
            onClick={() => setUiState("FORGOT_PASSWORD")}
            className="text-blue-500 hover:underline"
          >
            Click here
          </button>
        </div>
      </form>
    </form.Provider>
  );
}
