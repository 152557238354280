import CheckMarkIcon from "./icons/CheckMarkIcon";
import CircleLineThroughIcon from "./icons/CircleLineThroughIcon";
import CrossIcon from "./icons/CrossIcon";

type Props = {
  val?: boolean;
};

/**
 *
 * @deprecated use DataStatus instead
 * @param param0
 * @returns
 */
export default function DataIndicator({ val }: Props) {
  return val === undefined || val === null ? (
    <CircleLineThroughIcon className="w-4 aspect-square text-gray-300" />
  ) : val ? (
    <CheckMarkIcon className="w-4 aspect-square text-success" />
  ) : (
    <CrossIcon className="w-4 aspect-square text-error" />
  );
}
