import { gqlMutationClient } from "~/lib/backend";
import store from "~/lib/store";
import { authStateAtom } from "~/state";
import { postAuthenticationAction } from "./postAuthenticationAction";

/**
 * Dev login
 *
 * @param email
 * @param password
 */
export const loginAction = async (email: string, password: string) => {
  const resp = await gqlMutationClient()({
    login: [
      {
        email,
        password,
      },
      {
        __typename: true,
        "...on GraphQLError": {
          message: true,
        },
        "...on MutationLoginSuccess": {
          data: {
            jwt: true,
          },
        },
      },
    ],
  });

  if (!resp.login || resp.login.__typename === "GraphQLError") {
    throw new Error(resp.login?.message ?? "An unexpected error happened");
  }

  store.instance.set(authStateAtom, {
    jwt: resp.login.data.jwt ?? "no-jwt",
  });

  await postAuthenticationAction();
};
