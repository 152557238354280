import { FC } from "react";

const TradeVehicleIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 20 16"
    >
      <title>Trade vehicle icon</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0163 1.50526L19.7367 7.22567C20.0878 7.5767 20.0878 8.14584 19.7367 8.49687L14.0163 14.2173C13.6653 14.5683 13.0961 14.5683 12.7451 14.2173C12.3941 13.8663 12.3941 13.2971 12.7451 12.9461L16.931 8.76015H10.7865V6.9624H16.931L12.7451 2.77646C12.3941 2.42543 12.3941 1.85629 12.7451 1.50526C13.0961 1.15422 13.6653 1.15422 14.0163 1.50526Z"
        fill="currentColor"
      />

      <path
        d="M9.88764 0H4.96357C4.63644 0 4.32189 0.0504178 4.01993 0.151253C3.72425 0.252089 3.45059 0.393889 3.19896 0.576653C2.94732 0.753115 2.72399 0.970542 2.52897 1.22893C2.34024 1.48102 2.19555 1.76147 2.09489 2.07028L1.39659 4.17837L0.858717 3.63008H0V4.8401H0.349149L0.971954 5.45457C0.959372 5.48608 0.927917 5.58061 0.87759 5.73817C0.827262 5.89573 0.764352 6.08164 0.688861 6.29592C0.61966 6.51019 0.544168 6.74022 0.462386 6.98601L0.235911 7.66665C0.166711 7.86832 0.110092 8.03848 0.0660551 8.17713L0 8.37565V14.5203C0 14.6905 0.0314548 14.848 0.0943645 14.993C0.157274 15.1379 0.242202 15.2671 0.349149 15.3806C0.462386 15.4877 0.591351 15.5728 0.736043 15.6358C0.880735 15.6988 1.03801 15.7303 1.20787 15.7303H2.41573C2.58559 15.7303 2.74286 15.6988 2.88755 15.6358C3.03225 15.5728 3.15806 15.4877 3.26501 15.3806C3.37825 15.2671 3.46632 15.1379 3.52923 14.993C3.59214 14.848 3.6236 14.6905 3.6236 14.5203H9.88764V13.3103H6.03933V12.8471L7.02072 10.8902H9.88764V9.68021H6.2658L4.83146 12.5635V13.3103H1.20787V8.56472L1.26448 8.39456C1.30223 8.27481 1.34941 8.12671 1.40603 7.95025C1.46894 7.77379 1.535 7.58157 1.6042 7.3736C1.6734 7.15932 1.73945 6.9608 1.80236 6.77804L1.96278 6.29592C2.00682 6.16357 2.03513 6.08164 2.04771 6.05013H9.88764V4.8401H2.44404L3.24614 2.44841C3.37196 2.07658 3.589 1.77723 3.89725 1.55035C4.2118 1.32347 4.56724 1.21003 4.96357 1.21003H9.88764V0Z"
        fill="currentColor"
      />

      <path
        d="M4.09542 7.35469C3.95073 7.29167 3.79345 7.26016 3.6236 7.26016C3.45374 7.26016 3.29647 7.29167 3.15177 7.35469C3.00708 7.41771 2.87812 7.50594 2.76488 7.61938C2.65793 7.72652 2.573 7.85256 2.5101 7.99752C2.44719 8.14247 2.41573 8.30002 2.41573 8.47018C2.41573 8.64034 2.44719 8.7979 2.5101 8.94285C2.573 9.0878 2.65793 9.217 2.76488 9.33043C2.87812 9.43757 3.00708 9.52265 3.15177 9.58568C3.29647 9.6487 3.45374 9.68021 3.6236 9.68021C3.79345 9.68021 3.95073 9.6487 4.09542 9.58568C4.24011 9.52265 4.36593 9.43757 4.47288 9.33043C4.58611 9.217 4.67419 9.0878 4.7371 8.94285C4.80001 8.7979 4.83146 8.64034 4.83146 8.47018C4.83146 8.30002 4.80001 8.14247 4.7371 7.99752C4.67419 7.85256 4.58611 7.72652 4.47288 7.61938C4.36593 7.50594 4.24011 7.41771 4.09542 7.35469Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TradeVehicleIcon;
