import { FC } from "react";
import { LendingPortalEmploymentSchema } from "../_utils";

const CreditApplicationEmploymentDetailCard: FC<{
  employment?: LendingPortalEmploymentSchema;
}> = ({ employment }) => {
  if (!employment) return;

  // This is done because inline condition will result in one of the timeline number to get printed
  // E.g: Current Employment0
  const getEmploymentStatus = (pos: number | null | undefined) => {
    if (typeof pos !== "number") {
      console.error("FAILED TO GET EMPLOYMENT TIMELINE POSITION", pos);
      return;
    }
    if (pos === 0) return "Current Employment";
    if (pos === 1) return "Previous Employment";
    if (pos === 2) return "2nd Previous Employment";
    if (pos === 3) return "3rd Previous Employment";
    if (pos > 3) return `${pos}th Previous Employment`;
  };

  const getHumanReadableEmploymentStatus = (
    employmentStatus: string | null | undefined
  ): string => {
    if (!employmentStatus) {
      console.error("FAILED TO GET EMPLOYMENT STATUS", employmentStatus);
      return "-";
    }
    const readableMap: { [key: string]: string } = {
      EMPLOYED: "Employed",
      SELF_EMPLOYED: "Self-Employed",
      STUDENT: "Student",
      RETIRED: "Retired",
      UNEMPLOYED: "Unemployed",
    };

    return readableMap[employmentStatus] ?? employmentStatus;
  };

  const getHumanReadableIncomeInterval = (
    incomeInterval: string | null | undefined
  ): string => {
    if (!incomeInterval) {
      console.error("FAILED TO GET INCOME INTERVAL", incomeInterval);
      return "-";
    }
    const readableMap: { [key: string]: string } = {
      YEARLY: "Yearly",
      MONTHLY: "Monthly",
      WEEKLY: "Weekly",
      // Deprecated
      BI_WEEKLY: "Bi-Weekly",
    };

    return readableMap[incomeInterval] ?? incomeInterval;
  };

  return (
    <div className="flex flex-col px-8 py-8 gap-8 rounded-lg shadow-border">
      <p className="text-heading-2 font-bold">
        {getEmploymentStatus(employment.timelinePosition)}
      </p>

      <div className="grid grid-cols-5 grid-rows-2 gap-8">
        <div className="flex flex-col gap-2">
          <p className="text-body text-dark-gray">Employer Name</p>
          <p>{employment.employerName}</p>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-body text-dark-gray">Occupation</p>
          <p>{employment.occupation}</p>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-body text-dark-gray">Employer Phone number</p>
          <p>{employment.employerPhoneNumber}</p>
        </div>

        <div className="flex flex-col gap-2 col-span-2">
          <p className="text-body text-dark-gray">Employer Address</p>
          <p>{employment.fullAddress}</p>
        </div>

        {/* This field is not implemented yet */}
        {/* <div className="flex flex-col gap-2">
          <p className="text-body text-dark-gray">Role</p>
          <p>{employment.occupation}</p>
        </div> */}

        <div className="flex flex-col gap-2">
          <p className="text-body text-dark-gray">Employment Status</p>
          <p>{getHumanReadableEmploymentStatus(employment.employmentStatus)}</p>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-body text-dark-gray">Employment Duration</p>
          <p>
            {employment.durationYears ?? "0"} years /{" "}
            {employment.durationMonths ?? "0"} months
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-body text-dark-gray">Salary</p>
          <p>
            {employment.incomeAmount
              ? `$${employment.incomeAmount.toLocaleString()}`
              : "$0"}
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-body text-dark-gray">Per Time Periode</p>
          <p>{getHumanReadableIncomeInterval(employment.incomeInterval)}</p>
        </div>
      </div>
    </div>
  );
};

export default CreditApplicationEmploymentDetailCard;
