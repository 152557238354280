import { FC } from "react";
import InitialOrProfileImage from "./InitialOrProfileImage";

const CustomerProfileCard: FC<{
  latestProfilePictureUrl?: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | undefined; // If there is no phone number then mark undefined
  email?: string; //
  residentialAddress: string;
}> = ({
  latestProfilePictureUrl,
  firstName,
  lastName,
  phoneNumber,
  email,
  residentialAddress,
}) => {
  return (
    <div className="rounded-2xl bg-white shadow-md p-10 space-y-8 flex flex-col lg:flex-row justify-between py-4 px-6">
      <div className="flex flex-col lg:flex-row justify-start w-full lg:space-x-16">
        <InitialOrProfileImage
          firstName={firstName}
          lastName={lastName}
          profilePictureUrl={latestProfilePictureUrl}
        />

        <div className="bg-primary w-full max-w-screen-lg p-4 rounded-lg space-y-4">
          <div className="flex flex-row gap-6 mb-4 lg:mb-12 items-center">
            <p className="lg:text-heading-1 text-heading-2 font-bold text-tertiary">{`${firstName} ${lastName}`}</p>
          </div>

          <div className="flex flex-col lg:flex-row w-full h-full gap-20">
            <div className="flex flex-col items-start space-y-8 w-full lg:w-6/12">
              <div className="flex flex-col lg:flex-row gap-2 w-full">
                <div className="w-2/12">
                  <p className="text-heading-2 text-tertiary">Phone</p>
                </div>
                <div className="flex flex-row space-x-4 items-start justify-start w-10/12">
                  <p className="text-heading-2 text-primary whitespace-nowrap overflow-ellipsis w-full">
                    {phoneNumber}
                  </p>
                </div>
              </div>

              <div className="flex flex-col lg:flex-row gap-2 w-full">
                <div className="w-2/12">
                  <p className="text-heading-2 text-tertiary">Email</p>
                </div>
                <div className="flex flex-row space-x-4 items-start justify-start w-10/12">
                  <p className="text-heading-2 text-primary whitespace-nowrap overflow-ellipsis">
                    {email}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col space-y-8 lg:space-6">
              <p className="text-heading-2 text-tertiary">Personal Address</p>

              <p className="text-heading-2 text-primary overflow-hidden overflow-ellipsis w-full">
                {residentialAddress}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Must do some design sync */}
      {/* <div className="flex flex-row justify-end lg:justify-start h-full">
        <Button
          variant="TEXT_ONLY"
          onClick={onEditPersonalInformation}
          className="pt-0 pr-0"
        >
          Edit
        </Button>
      </div> */}
    </div>
  );
};

export default CustomerProfileCard;
