import { authStateAtom, dealershipAtom, isCognitoAuth } from "~/state";
import {
  FetchFunction,
  GraphQLResponse,
  Thunder,
  ZeusScalars,
} from "../__generated__/backend/zeus";
import config from "../config";
import { getJwt } from "./auth";
import store from "./store";

const scalars = ZeusScalars({
  JSON: {
    encode: (e: unknown) => JSON.stringify(e),
    decode: (e: unknown) =>
      // The JSON fields in a response can be sent as objects and not strings.
      // Therefore, we have to check the type before trying to parse a JSON string.
      // If it's not a string, we are just returning the object
      (typeof e === "string" ? JSON.parse(e) : e) as unknown,
  },
  DateTime: {
    decode: (e: unknown) => new Date(e as string),
    // It inserts it verbatim, not with enclosing `"` for strings
    encode: (e: unknown) => `"${(e as Date).toISOString()}"`,
  },
});

type Options = {
  throwOnError?: boolean;
  dealershipId?: string | null;
};

const handleFetchResponse = async (
  response: Response
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    const msg = await response.text();
    throw new Error(msg);
  }
  return await response.json();
};

const fetchFunction: (options?: Options) => FetchFunction =
  (options) =>
  async (query, variables: Record<string, unknown> = {}) => {
    const authJwt = store.instance.get(authStateAtom);
    const authHeaders: HeadersInit = {};

    const jwt = isCognitoAuth(authJwt) ? await getJwt() : authJwt?.jwt;

    const dealershipIdFromStore =
      store.instance.get(dealershipAtom)?.activeDealershipPerms.dealershipId;

    // Needed a mechanism to overwrite this.
    if (dealershipIdFromStore && options?.dealershipId !== null) {
      authHeaders["DealershipId"] = dealershipIdFromStore;
    }

    // This one overwrites and takes explicit precedence
    if (options?.dealershipId) {
      authHeaders["DealershipId"] = options?.dealershipId;
    }

    if (jwt) {
      authHeaders["Authorization"] = `Bearer ${jwt}`;
    }

    const response = await fetch(config.backendUrl, {
      body: JSON.stringify(
        {
          query,
          variables: variables,
        },
        (_, value) => {
          return value;
        }
      ),
      method: "POST",
      headers: {
        ...authHeaders,
        "Content-Type": "application/json",
      },
    });

    const gqlResp = await handleFetchResponse(response);

    if (gqlResp.errors) {
      if (options?.throwOnError) {
        throw new Error(JSON.stringify(gqlResp.errors));
      } else {
        console.error(gqlResp.errors);
      }
    }

    return gqlResp.data;
  };

export const gqlQueryClient = (options?: Options) =>
  Thunder(fetchFunction(options), {
    scalars,
  })("query");

export const gqlMutationClient = (options?: Options) =>
  Thunder(fetchFunction(options), {
    scalars,
  })("mutation");
