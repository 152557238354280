import { FC } from "react";

const ClockIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 28 28"
    >
      <title>Clock icon</title>

      <path
        d="M13.9883 2.3335C7.54825 2.3335 2.33325 7.56016 2.33325 14.0002C2.33325 20.4402 7.54825 25.6668 13.9883 25.6668C20.4399 25.6668 25.6666 20.4402 25.6666 14.0002C25.6666 7.56016 20.4399 2.3335 13.9883 2.3335ZM13.9999 23.3335C8.84325 23.3335 4.66659 19.1568 4.66659 14.0002C4.66659 8.8435 8.84325 4.66683 13.9999 4.66683C19.1566 4.66683 23.3333 8.8435 23.3333 14.0002C23.3333 19.1568 19.1566 23.3335 13.9999 23.3335ZM14.5833 8.16683H12.8333V15.1668L18.9583 18.8418L19.8333 17.4068L14.5833 14.2918V8.16683Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ClockIcon;
