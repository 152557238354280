import { FC } from "react";
import { NumericFormat } from "react-number-format";

type FormattedNumberProps = {
  value: number | null | undefined; // Strictly formatted so passing value is a must eventhough it can be null / undefined
  suffix?: string;
  decimalScale?: number;
  className?: string;
};

const FormattedNumber: FC<FormattedNumberProps> = ({
  value,
  suffix = "",
  decimalScale = 2,
  className = "text-body",
}) => {
  return (
    <p className={className}>
      {value ? (
        <NumericFormat
          value={value}
          displayType={"text"}
          thousandSeparator={true}
          suffix={suffix}
          decimalScale={decimalScale}
          fixedDecimalScale={true}
        />
      ) : (
        "-"
      )}
    </p>
  );
};

export default FormattedNumber;
