import { confirmSignIn } from "aws-amplify/auth";
import { useTranslation } from "react-i18next";
import Button from "~/components/Button";
import { UiState } from "./types";

type Props = {
  setUiState: (state: UiState) => void;
  allowedMFATypes: ("SMS" | "TOTP")[];
};

export default function MfaSelector({ setUiState, allowedMFATypes }: Props) {
  const { t } = useTranslation();

  const continueWith = async (mfa: "SMS" | "TOTP") => {
    await confirmSignIn({
      challengeResponse: mfa,
    });

    if (mfa === "TOTP") {
      setUiState("MFA_REQUIRED_TOTP");
    } else {
      setUiState("MFA_REQUIRED_SMS");
    }
  };

  return (
    <div className="rounded-2xl shadow-2xl w-[444px] flex flex-col space-y-8 items-center p-6">
      <h2 className="text-2xl font-bold">{t("App Verification")}</h2>

      <p>{t("Enter the code from your authenticator app.")}</p>

      {allowedMFATypes.map((mfa) => {
        return (
          <Button variant="PRIMARY" onClick={() => continueWith(mfa)} key={mfa}>
            {mfa === "SMS"
              ? t("Continue with SMS")
              : t("Continue with Authenticator App")}
          </Button>
        );
      })}
    </div>
  );
}
