import { signOut } from "aws-amplify/auth";
import { RESET } from "jotai/utils";
import { queryClient } from "~/lib/query";
import store from "~/lib/store";
import { authStateAtom, dealershipAtom } from "~/state";

export default async function logoutAction() {
  // We can not just clear everything as we don't want to loose the kiosk mode
  store.instance.set(authStateAtom, RESET);
  store.instance.set(dealershipAtom, RESET);

  // Remove all data that is present cached
  queryClient.clear();

  // This AWS function does sp much Sh*t that we are placing it last. We can not rely on anything after this
  await signOut();
}
