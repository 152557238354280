import { FC } from "react";
import { AlertCircle } from "react-feather";

const WarningBanner: FC<{
  text: string;
}> = ({ text }) => {
  return (
    <div className="flex flex-row w-full px-4 py-2 rounded-md backgr bg-yellow-100 border-l-8 border-yellow-300 gap-4">
      <AlertCircle className="text-yellow-400 w-6" />
      <p>{text}</p>
    </div>
  );
};

export default WarningBanner;
