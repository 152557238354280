import classNames from "classnames";
import { FC, ReactElement } from "react";
import { twMerge } from "tailwind-merge";
import Spinner from "../Spinner";

const Button: FC<{
  children: ReactElement | string;
  onClick?: (() => void) | (() => Promise<void>);
  disabled?: boolean;
  loading?: boolean;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  variant?:
    | "PRIMARY"
    | "SECONDARY"
    | "DANGER"
    | "GHOST"
    | "GHOST_DANGER"
    | "WARNING"
    | "SUCCESS"
    | "LINK";
  size?: "LARGE" | "MEDIUM" | "SMALL" | "XS";
  className?: string;
  dataTestId?: string;
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
}> = ({
  children,
  onClick,
  disabled,
  loading,
  type,
  variant = "PRIMARY",
  size = "MEDIUM",
  className,
  dataTestId,
  iconLeft,
  iconRight,
}) => {
  return (
    <button
      data-test-id={dataTestId}
      type={type}
      className={twMerge(
        classNames(
          "relative flex items-center justify-center rounded-md font-semibold transition-colors duration-200",
          {
            // Primary variant styles
            "bg-blue-600 text-white hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2":
              variant === "PRIMARY" && !disabled && !loading,
            "bg-blue-700 text-white":
              variant === "PRIMARY" && (disabled || loading),

            // Secondary variant styles
            "bg-transparent text-blue-600 hover:text-blue-800 ring-2 ring-blue-600 hover:ring-blue-800 focus:ring-blue-900 focus:ring-offset-2":
              variant === "SECONDARY" && !disabled && !loading,
            "bg-gray-300 text-gray-600":
              variant === "SECONDARY" && (disabled || loading),

            // Destructive variant styles
            "bg-red-600 text-white hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2":
              variant === "DANGER" && !disabled && !loading,
            "bg-red-300 text-red-600":
              variant === "DANGER" && (disabled || loading),

            // Ghost variant styles
            "bg-transparent  text-blue-600 hover:bg-blue-50 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2":
              variant === "GHOST" && !disabled && !loading,
            "text-blue-300": variant === "GHOST" && (disabled || loading),

            "bg-transparent  text-red-600 hover:bg-red-50 focus:ring-2 focus:ring-red-500 focus:ring-offset-2":
              variant === "GHOST_DANGER" && !disabled && !loading,
            "text-red-300": variant === "GHOST_DANGER" && (disabled || loading),

            // Link variant styles
            "bg-transparent text-blue-600 hover:underline active:text-blue-800 focus:ring-2 focus:ring-blue-900 focus:ring-offset-2":
              variant === "LINK" && !disabled && !loading,
            "text-blue-600/40": variant === "LINK" && (disabled || loading),

            // Warning variant styles
            "bg-yellow-500 text-white hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2":
              variant === "WARNING" && !disabled && !loading,
            "bg-yellow-300 text-yellow-600":
              variant === "WARNING" && (disabled || loading),

            // Success variant styles
            "bg-green-600 text-white hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2":
              variant === "SUCCESS" && !disabled && !loading,
            "bg-green-300 text-green-600":
              variant === "SUCCESS" && (disabled || loading),

            // Size styles
            "text-sm": ["SMALL", "MEDIUM", "LARGE"].includes(size),
            "text-xs": size === "XS",
            "px-6 py-3": variant !== "LINK" && size === "LARGE",
            "px-5 py-2.5": variant !== "LINK" && size === "MEDIUM",
            "px-4 py-2": variant !== "LINK" && size === "SMALL",
            "px-3 py-1.5": variant !== "LINK" && size === "XS",

            // Disabled and loading states
            "w-24": loading,
            "opacity-50 cursor-not-allowed": disabled || loading,
          },
          className
        )
      )}
      disabled={disabled || loading}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        if (onClick) onClick();
      }}
    >
      <div className="flex items-center justify-center space-x-2">
        {iconLeft && <div>{iconLeft}</div>}
        {!loading ? (
          children
        ) : (
          <div className="flex absolute inset-0 items-center justify-center">
            <Spinner className="w-5 h-5" />
          </div>
        )}
        {iconRight && <div>{iconRight}</div>}
      </div>
    </button>
  );
};

export default Button;
