import { TransactionSource } from "@thedealersconcierge/lib/codecs/tdc";
import { getHumanReadableTransactionSource } from "@thedealersconcierge/lib/codecs/util";
import classNames from "classnames";
import { FC } from "react";

// TODO: Create and use general badge component
const TransactionSourceBadge: FC<{
  source: TransactionSource;
  size?: "DEFAULT" | "SMALL";
}> = ({ source, size = "DEFAULT" }) => {
  return (
    <div
      className={classNames("flex rounded-lg whitespace-nowrap", {
        "bg-fuchsia-50": source === "KIOSK",
        "bg-fuchsia-100": source === "ADMIN_DASHBOARD",
        "bg-fuchsia-200": source === "WEB_PREQUAL",
        "bg-fuchsia-300": source === "WEB_APPLICATION",
        "px-4 py-2 font-medium": size === "DEFAULT",
        "px-4 py-1 text-sm": size === "SMALL",
      })}
    >
      {getHumanReadableTransactionSource(source)}
    </div>
  );
};

export default TransactionSourceBadge;
