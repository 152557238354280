import classNames from "classnames";
import { FC } from "react";
import { Check, Minus } from "react-feather";

export enum MultiStateCheckboxState {
  SELECTED = "SELECTED",
  UNSELECTED = "UNSELECTED",
  UNSPECIFIED = "UNSPECIFIED",
}

type MultiStateCheckboxProps = {
  value: MultiStateCheckboxState;
  disabled?: boolean;
  onChange?: (value: MultiStateCheckboxState) => void;
  readOnly?: boolean;
  mode: "MULTI" | "SIMPLE";
};

export const MultiStateCheckbox: FC<MultiStateCheckboxProps> = ({
  value,
  disabled,
  onChange,
  readOnly,
  mode,
}) => {
  const handleChange = () => {
    if (readOnly) return;
    let newValue: MultiStateCheckboxState;
    if (mode === "MULTI") {
      switch (value) {
        case MultiStateCheckboxState.SELECTED:
          newValue = MultiStateCheckboxState.UNSELECTED;
          break;
        case MultiStateCheckboxState.UNSELECTED:
          newValue = MultiStateCheckboxState.UNSPECIFIED;
          break;
        case MultiStateCheckboxState.UNSPECIFIED:
          newValue = MultiStateCheckboxState.SELECTED;
          break;
        default:
          newValue = MultiStateCheckboxState.UNSPECIFIED;
      }
    } else {
      // Simple mode: only SELECTED and UNSELECTED
      newValue =
        value === MultiStateCheckboxState.SELECTED
          ? MultiStateCheckboxState.UNSELECTED
          : MultiStateCheckboxState.SELECTED;
    }
    if (onChange) onChange(newValue);
  };

  return (
    <div className={classNames("flex")}>
      <input
        type="checkbox"
        checked={value === MultiStateCheckboxState.SELECTED}
        disabled={disabled}
        className="hidden"
        readOnly
      />

      <label
        className={classNames(
          "flex items-center justify-center rounded-sm size-4",
          {
            "cursor-pointer border-primary": !disabled,
            "cursor-not-allowed border-primary": disabled,
            border: value !== MultiStateCheckboxState.SELECTED,
            "bg-interactive-primary":
              value === MultiStateCheckboxState.SELECTED && !disabled,
            "bg-primary-inverse":
              value === MultiStateCheckboxState.SELECTED && disabled,
            "bg-gray-800":
              (value === MultiStateCheckboxState.SELECTED ||
                value === MultiStateCheckboxState.UNSPECIFIED) &&
              !disabled,
          }
        )}
        onClick={handleChange}
      >
        {value === MultiStateCheckboxState.SELECTED && (
          <Check className="text-white size-2.5" />
        )}
        {value === MultiStateCheckboxState.UNSPECIFIED && mode === "MULTI" && (
          <Minus className="text-white size-2.5" />
        )}
        {value === MultiStateCheckboxState.UNSELECTED && (
          <span className="icon-inverse"></span>
        )}
      </label>
    </div>
  );
};
