import parsePhoneNumber from "libphonenumber-js";
import { z } from "zod";

export const phoneNumber = () =>
  z
    .string()
    // For Validation
    .refine(
      (phoneNumber) => {
        if (phoneNumber === undefined) {
          return true;
        }
        return parsePhoneNumber(phoneNumber, "US")?.isValid();
      },
      (phoneNumber) => ({
        message: `${phoneNumber} is not a valid phone number`,
      })
    )
    // For normalization
    .transform((phoneNumber) =>
      phoneNumber
        ? parsePhoneNumber(phoneNumber, "US")?.format("E.164") ?? ""
        : ""
    );
