import { GraphQLTypes } from "~/__generated__/backend/zeus";
import { gqlMutationClient } from "~/lib/backend";

const setStaffInformationAction = async (
  userId: string,
  staffInformation: GraphQLTypes["SetStaffInformationInput"]
) => {
  const resp = await gqlMutationClient()({
    setStaffInformation: [
      {
        userId,
        staffInformation,
      },
      {
        __typename: true,
        "...on GraphQLError": {
          message: true,
        },
        "...on MutationSetStaffInformationSuccess": {
          data: {
            status: true,
          },
        },
      },
    ],
  });

  if (
    !resp.setStaffInformation ||
    resp.setStaffInformation.__typename === "GraphQLError"
  ) {
    throw new Error(resp.setStaffInformation?.message ?? "Unexpected error");
  }
};

export default setStaffInformationAction;
