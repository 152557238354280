import { FC } from "react";

const MagnifierIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 19 20"
    >
      <title>Magnifier icon</title>

      <path
        d="M18.1314 18.4999L11.9562 12.3247"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />

      <circle
        cx="7.22628"
        cy="7.72628"
        r="6.47628"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default MagnifierIcon;
