import { useState } from "react";
import config from "~/config";
import ForgottenPassword from "./(authenticated)/_components/ForgottenPassword";
import ForgottenPasswordUpdatePassword from "./(authenticated)/_components/ForgottenPasswordUpdatePassword";
import MfaInput from "./(authenticated)/_components/MfaInput";
import MfaSelector from "./(authenticated)/_components/MfaSelector";
import SetNewPassword from "./(authenticated)/_components/SetNewPassword";
import SignIn from "./(authenticated)/_components/SignIn";
import { UiState } from "./(authenticated)/_components/types";

export default function LoginPage() {
  const [uiState, setUiState] = useState<UiState>("SIGN_IN");
  const [email, setEmail] = useState("");

  let logo = `/logo-${config.prodEnv ?? "local"}.png`;

  return (
    <main className="flex min-h-dvh flex-col p-24 space-y-8 items-center">
      <div>
        <img src={logo} alt="Logo" />
      </div>

      {uiState === "FORGOT_PASSWORD_RESET_CODE_SEND" && (
        <ForgottenPasswordUpdatePassword
          setUiState={setUiState}
          email={email}
        />
      )}
      {uiState === "SELECT_MFA" && (
        <MfaSelector
          setUiState={setUiState}
          // Currently, we assume that there are more
          allowedMFATypes={["SMS", "TOTP"]}
        />
      )}
      {uiState === "MFA_REQUIRED_SMS" && <MfaInput mfaType="SMS" />}
      {uiState === "MFA_REQUIRED_TOTP" && <MfaInput mfaType="TOTP" />}

      {/* If the email was reset by Cognito, we must know the email from this step also */}
      {uiState === "SIGN_IN" && (
        <SignIn setUiState={setUiState} setEmail={setEmail} />
      )}
      {uiState === "FORGOT_PASSWORD" && (
        <ForgottenPassword setUiState={setUiState} setEmail={setEmail} />
      )}
      {uiState === "NEW_PASSWORD_REQUIRED" && (
        <SetNewPassword setUiState={setUiState} />
      )}
      <div className="flex flex-row space-x-6">
        <a
          className="text-blue-500 hover:underline"
          href="https://files.mytdc.net/terms-and-conditions-tdc-digital-dealer-dervices-rev-jan-2024.pdf"
          target="_blank"
        >
          Terms & Conditions
        </a>
        <a
          className="text-blue-500 hover:underline"
          href="https://files.mytdc.net/privacy-policy-dealergenix-jan2024.pdf"
          target="_blank"
        >
          Privacy Policy
        </a>
      </div>
    </main>
  );
}
