import classNames from "classnames";
import {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import ChevronRightIcon from "./icons/ChevronRightIcon";

export const BreadCumbContext = createContext<{
  state: { elements: string[] };
  register: (key: string) => void;
  deregister: (key: string) => void;
} | null>(null);

type Props = {
  children: any;
};

export function BreadCrumbsContainer({ children }: Props) {
  const [state, setState] = useState<{ elements: string[] }>({ elements: [] });
  return (
    <BreadCumbContext.Provider
      value={{
        state,
        deregister: (key) =>
          setState((s) => ({ elements: s.elements.filter((e) => e !== key) })),
        register: (key) =>
          setState((s) => ({ elements: [...s.elements, key] })),
      }}
    >
      <div className="flex flex-row items-center space-x-2 text-2xl leading-none font-roboto h-10">
        {children}
      </div>
    </BreadCumbContext.Provider>
  );
}

type BcProps = {
  children?: any;
  title: string;
  className?: string;
};

export function BreadCrumb({ children, title, className }: BcProps) {
  const breadCrumbs = useContext(BreadCumbContext);

  if (!breadCrumbs) {
    throw new Error(
      "Please only use BreadCrumbs inside a BreadCrumbsContainer"
    );
  }

  useEffect(() => {
    breadCrumbs.register(title);

    return () => breadCrumbs.deregister(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isFirstBreadCrumb =
    breadCrumbs.state.elements.findIndex((e) => e === title) === 0;
  const isLastBreadCrumb =
    breadCrumbs.state.elements.findIndex((e) => e === title) ===
    breadCrumbs.state.elements.length - 1;

  return (
    <Fragment>
      {!isFirstBreadCrumb && (
        <div className="relative">
          <ChevronRightIcon className="w-6 aspect-square text-dark-gray" />
        </div>
      )}

      <span
        className={classNames(
          {
            "text-dark-gray": !isLastBreadCrumb,
            "text-very-dark-gray": isLastBreadCrumb,
          },
          className
        )}
      >
        {children ?? <span>{title}</span>}
      </span>
    </Fragment>
  );
}
