import { Role } from "~/__generated__/backend/zeus";
import AssignModal from "./AssignModal";

type Props = {
  transactionId: string;
  assignRole: Role;
  onDidAssign?: () => void | Promise<void>;
  disabled?: boolean;
};

export default function AssignButton(props: Props) {
  return (
    <AssignModal {...props}>
      {({ openModal }) => (
        <button
          className="bg-light-gray text-dark-gray py-1 px-2 rounded-sm text-sm"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            openModal();
          }}
          disabled={props.disabled}
        >
          Assign +
        </button>
      )}
    </AssignModal>
  );
}
