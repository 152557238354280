import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Spinner from "~/components/Spinner";
import FileErrorIcon from "~/components/icons/FileErrorIcon";
import { gqlQueryClient } from "~/lib/backend";
import { getReadableFormSubmissionType } from "~/lib/enumReadable";
import { customerFilesSelector } from "~/query/consumerDatabaseQuery";
import { Link, useParams } from "~/router";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const FileFormSubmissionPage = () => {
  const { transactionId, userId, formSubmissionId } = useParams(
    "/dashboard/customers/:userId/:transactionId/files/formSubmission/:formSubmissionId"
  );
  const { data } = useQuery({
    queryKey: ["userData", userId, transactionId],
    queryFn: async () =>
      gqlQueryClient()(customerFilesSelector(userId, transactionId)),
  });

  const [numPages, setNumPages] = useState(0);
  const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  const formSubmissions = data?.customer?.formSubmissions?.edges ?? [];
  const formSubmission = formSubmissions.find(
    ({ node: s }) => s?.id === formSubmissionId
  );

  const userFullName = `${data?.customer?.firstName ?? ""} ${data?.customer?.lastName ?? ""}`;

  return (
    <div className="flex flex-col space-y-8">
      <div className="flex flex-row justify-between">
        <BreadCrumbsContainer>
          <BreadCrumb title="Customer Database">
            <Link to={"/dashboard/customers"}>Customer Database</Link>
          </BreadCrumb>

          <BreadCrumb title={userFullName}>
            <Link to={"/dashboard/customers/:userId"} params={{ userId }}>
              {userFullName}
            </Link>
          </BreadCrumb>

          <BreadCrumb title="Files">
            <Link
              to={"/dashboard/customers/:userId/:transactionId/files"}
              params={{ userId, transactionId }}
            >
              Files
            </Link>
          </BreadCrumb>

          <BreadCrumb
            title={
              getReadableFormSubmissionType(
                formSubmission?.node?.type ?? "N/A"
              ) ?? "Document"
            }
          />
        </BreadCrumbsContainer>
      </div>

      <div className="flex flex-1 overflow-scroll flex-col items-center">
        <div className="flex flex-col w-full items-center">
          {formSubmission?.node?.file?.url ? (
            <Document
              file={formSubmission.node.file.url}
              onLoadSuccess={handleDocumentLoadSuccess}
              className="flex flex-col space-y-2"
              loading={
                <div className="flex w-full min-h-[80vh] justify-center items-center">
                  <Spinner />
                </div>
              }
              error={
                <div className="flex flex-col w-full min-h-[80vh] justify-center items-center">
                  <div className="flex flex-col space-y-4 items-center">
                    <div className="relative">
                      <FileErrorIcon className="w-20 text-dark-gray" />
                    </div>

                    <div className="text-subtitle text-dark-gray">
                      Failed to load document
                    </div>
                  </div>
                </div>
              }
            >
              {[...new Array(numPages)].map((_, index) => (
                <Page
                  key={index}
                  pageNumber={index + 1}
                  className="border border-very-light-gray"
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              ))}
            </Document>
          ) : (
            <div className="flex w-1/3 aspect-square items-center justify-center">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileFormSubmissionPage;
