import { useState } from "react";
import Button from "~/components/Button";
import Modal from "~/components/Modal";
import { gqlMutationClient } from "~/lib/backend";
import { queryClient } from "~/lib/query";
import { useModals, useParams } from "~/router";

const DealScenariosPage = () => {
  const modals = useModals();
  const { transactionId } = useParams("/dashboard/transaction/:transactionId");
  const [isRemoving, setIsRemoving] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);
  const handleRemovePrimaryBuyer = async () => {
    setIsRemoving(true);

    const resp = await gqlMutationClient()({
      removePrimaryBuyer: [
        {
          transactionId,
        },
        {
          __typename: true,
          "...on GraphQLError": {
            message: true,
          },
          "...on MutationRemovePrimaryBuyerSuccess": {
            data: {
              id: true,
              buyerId: true,
            },
          },
        },
      ],
    });

    if (
      !resp.removePrimaryBuyer ||
      resp.removePrimaryBuyer.__typename === "GraphQLError"
    ) {
      console.error(resp.removePrimaryBuyer?.message ?? "Unexpected error");

      window.alert(
        "Could not remove primary buyer. Please try again or contact support."
      );
    } else {
      queryClient.resetQueries({
        queryKey: ["transaction", transactionId],
      });

      modals.close({
        at: "/dashboard/transaction/:transactionId",
        params: {
          transactionId,
        },
      });
    }

    setIsRemoving(false);
  };
  const handleSwitchRoles = async () => {
    setIsSwitching(true);

    const resp = await gqlMutationClient()({
      switchCustomerRoles: [
        {
          transactionId,
        },
        {
          __typename: true,
          "...on GraphQLError": {
            message: true,
          },
          "...on MutationSwitchCustomerRolesSuccess": {
            data: {
              id: true,
              buyerId: true,
            },
          },
        },
      ],
    });

    if (resp.switchCustomerRoles?.__typename === "GraphQLError") {
      console.error(resp.switchCustomerRoles.message);

      window.alert(
        "Could not make co-buyer primary buyer. Please try again or contact support."
      );
    } else {
      queryClient.resetQueries({
        queryKey: ["transaction", transactionId],
      });

      modals.close({
        at: "/dashboard/transaction/:transactionId",
        params: {
          transactionId,
        },
      });
    }

    setIsSwitching(false);
  };

  return (
    <Modal
      title="Deal Scenarios"
      className="h-fit flex-grow-0"
      isOpen
      onClose={modals.close}
    >
      <div className="flex flex-col space-y-8 p-20">
        <div className="flex flex-col space-y-8 items-end p-8 bg-very-light-blue rounded-lg">
          <div className="flex flex-col space-y-4 w-full">
            <h2 className="text-heading-2">Scenario 1: Switch Roles</h2>

            <div className="text-body">
              When selected, the buyer & co-buyer will switch roles in the same
              deal transaction.
            </div>
          </div>

          <Button
            onClick={handleSwitchRoles}
            loading={isSwitching}
            disabled={isRemoving}
          >
            Switch Roles
          </Button>
        </div>

        <div className="flex flex-col space-y-8 items-end p-8 bg-very-light-blue rounded-lg">
          <div className="flex flex-col space-y-4 w-full">
            <h2 className="text-heading-2">Scenario 2: Remove Primary Buyer</h2>

            <div className="text-body">
              When selected, the primary buyer will be removed from the
              transaction and the co-buyer becomes the primary buyer.
            </div>
          </div>

          <Button
            variant="DESTRUCTIVE"
            onClick={handleRemovePrimaryBuyer}
            loading={isRemoving}
            disabled={isSwitching}
          >
            Remove Primary Buyer
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DealScenariosPage;
