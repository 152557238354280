import { FC } from "react";

const CrossIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>Cross icon</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7199 20.25L4.5 6.03011L6.03011 4.5L20.25 18.7199L18.7199 20.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.03011 20.25L20.25 6.03011L18.7199 4.5L4.5 18.7199L6.03011 20.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CrossIcon;
