import { getDefaultStore } from "jotai";

/**
 * In Jotai all data needs to belong to a context. Traditionally this context
 * was react. Since they have added the store concept.
 *
 * We use a single store for our global state
 */
const globalStore = getDefaultStore();

export default {
  instance: globalStore,
};
