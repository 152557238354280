import { DashboardUserRole } from "@thedealersconcierge/lib/codecs/tdc";
import classNames from "classnames";
import { FC } from "react";
import { getReadableRole } from "~/lib/enumReadable";

export const DashboardRoleBadge: FC<{
  role: DashboardUserRole | string | undefined;
  className?: string;
  size?: "DEFAULT" | "SMALL";
}> = ({ role, className, size = "SMALL" }) => {
  return (
    <div
      className={classNames(
        "flex rounded-xl px-2 capitalize text-gray-800",
        {
          "bg-red-400": role === "ADMIN",
          "bg-purple-200": role === "BDC",
          "bg-blue-200": role === "SALES_MANAGER",

          "bg-yellow-300": role === "SALES_PERSON",
          "bg-green-300": role === "FNI_MANAGER",

          "px-4 py-2 font-medium": size == "DEFAULT",
          "px-2 py-1 text-sm": size === "SMALL",
        },
        className
      )}
    >
      {getReadableRole(role)}
    </div>
  );
};
