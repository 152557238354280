import { useQuery } from "@tanstack/react-query";
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { format } from "date-fns";
import { useAtomValue } from "jotai";
import stringify from "json-stable-stringify";
import debounce from "lodash/debounce";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import Spinner from "~/components/Spinner";
import CheckMarkIcon from "~/components/icons/CheckMarkIcon";
import ChevronRightIcon from "~/components/icons/ChevronRightIcon";
import CircleLineThroughIcon from "~/components/icons/CircleLineThroughIcon";
import CrossIcon from "~/components/icons/CrossIcon";
import MagnifierIcon from "~/components/icons/MagnifierIcon";
import config from "~/config";
import { gqlQueryClient } from "~/lib/backend";
import meQuery from "~/query/meQuery";
import { useNavigate } from "~/router";
import { dealershipAtom } from "~/state";
import {
  Pagination,
  Row,
  dataTransform,
  selector,
  transformOrdering,
} from "./_customerUtil";

export default function Dashboard() {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [page, setPage] = useState<Pagination>({
    currentPage: 1,
    pageSize: 10,
    direction: "after",
  });
  const navigate = useNavigate();
  const { data: meData } = useQuery(meQuery());

  const dealership = useAtomValue(dealershipAtom);
  const dealershipId = dealership?.activeDealershipPerms.dealershipId;

  // This is a hack. Please avoid this construction anywhere else.
  // The reason we have it is to reset the pagination whenever the sorting
  // changes. It does not make sense to stay at page 4 when sorting changes to
  // first name, etc.
  useEffect(() => {
    setPage((p) => ({
      ...p,
      currentPage: 1,
      cursor: undefined,
      direction: "after",
    }));
  }, [sorting]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSearchString = useCallback(
    debounce((term: string) => {
      setSearchString(term);
    }, 500),
    []
  );

  const qKey = [
    "customers_table",
    dealershipId,
    stringify(transformOrdering(sorting)),
    stringify(page),
    searchString,
  ];

  const { data: tableData, isLoading: loading } = useQuery({
    queryKey: qKey,
    enabled: Boolean(dealershipId),
    queryFn: () => {
      if (dealershipId) {
        return gqlQueryClient()(
          selector(dealershipId, transformOrdering(sorting), page, searchString)
        );
      }
    },
  });

  const users = tableData?.dealership?.users?.edges ?? [];
  const totalCount = tableData?.dealership?.users?.totalCount ?? 0;

  const handleOpenConsumerDatabase = (userId: string) => {
    navigate("/dashboard/customers/:userId", {
      params: { userId },
    });
  };

  const columns = useMemo<ColumnDef<Row>[]>(
    () => [
      {
        header: () => <span>Date</span>,
        id: "creationDate",
        accessorFn: (row) => <>{format(row.creationDate, "MM/dd/yyyy")}</>,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        sortingFn: (a, b) =>
          a.original.creationDate.getTime() - b.original.creationDate.getTime(),
      },
      {
        header: () => <span>First Name</span>,
        id: "firstName",
        accessorFn: (row) => (
          <div className="flex justify-start w-full">
            {row.firstName ?? "-"}
          </div>
        ),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        sortingFn: (a, b) =>
          a.original.firstName?.localeCompare(b.original.firstName ?? "") ?? 0,
      },
      {
        header: () => <span>Last Name</span>,
        id: "lastName",
        accessorFn: (row) => (
          <div className="flex justify-start w-full">{row.lastName ?? "-"}</div>
        ),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        sortingFn: (a, b) =>
          a.original.lastName?.localeCompare(b.original.lastName ?? "") ?? 0,
      },

      // Transaction specific, not relevant here
      // {
      //   minSize: 200,
      //   header: () => <span>Active transaction</span>,
      //   id: "hasBeenRemovedFromTransaction",
      //   accessorFn: (row) => (
      //     <DataIndicator val={!row.hasBeenRemovedFromTransaction} />
      //   ),
      //   cell: (info) => info.getValue(),
      //   footer: (props) => props.column.id,
      //   sortingFn: (a, b) =>
      //     a.original.lastName?.localeCompare(b.original.lastName ?? "") ?? 0,
      // },

      // Also transaction specific
      // {
      //   header: () => <span>Was</span>,
      //   id: "disassociatedRole",
      //   accessorFn: (row) => (
      //     <div>
      //       {row.disassociatedRole === "BUYER"
      //         ? "Buyer"
      //         : row.disassociatedRole === "CO_BUYER"
      //           ? "Co Buyer"
      //           : "-"}
      //     </div>
      //   ),
      //   cell: (info) => info.getValue(),
      //   footer: (props) => props.column.id,
      //   sortingFn: (a, b) =>
      //     a.original.lastName?.localeCompare(b.original.lastName ?? "") ?? 0,
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const data = useMemo(() => dataTransform(tableData), [tableData]);

  const table = useReactTable({
    enablePinning: true,
    enableColumnPinning: true,
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: !config.isProduction,
  });
  const numberOfStickyHeaders =
    table
      .getHeaderGroups()
      .at(0)
      ?.headers.filter((h) => h.column.getIsPinned()).length ?? 0;

  return (
    <div className="flex flex-grow flex-col space-y-4 pb-16">
      {/* The search bar */}
      <div className="flex flex-row w-full max-w-lg bg-light-gray text-dark-gray rounded overflow-hidden px-4 space-x-4">
        <input
          onChange={(e) => updateSearchString(e.target.value)}
          className="bg-light-gray py-4 w-full text-body focus:outline-none"
          placeholder="Search"
        />

        <div className="relative">
          <MagnifierIcon className="w-4 aspect-square" />
        </div>
      </div>

      {loading && (
        <div className="flex relative flex-grow justify-center items-center">
          <Spinner />
        </div>
      )}

      {!loading && (
        <Fragment>
          {/* Main Table */}
          <div className="rounded-lg overflow-hidden shadow-md">
            <div className="relative flex flex-grow overflow-scroll">
              <table
                className="table-fixed bg-white"
                {...{
                  style: {
                    width: table.getCenterTotalSize(),
                  },
                }}
              >
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => {
                    return (
                      <tr
                        key={headerGroup.id}
                        className={"border-b border-light-gray"}
                      >
                        {headerGroup.headers.map((header) => {
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              className={classNames("bg-white h-14", {
                                "sticky z-sticky": header.column.getIsPinned(),
                              })}
                              style={{
                                left:
                                  header.column.getSize() *
                                  header.column.getPinnedIndex(),
                                width: header.column.getSize(),
                              }}
                            >
                              {header.isPlaceholder ? null : (
                                <div
                                  {...{
                                    className: classNames(
                                      "relative flex w-full h-full px-6 items-center font-medium text-start text-dark-gray",
                                      {
                                        "border-r border-light-gray":
                                          numberOfStickyHeaders - 1 ===
                                          header.index,
                                        "cursor-pointer select-none":
                                          header.column.getCanSort(),
                                      }
                                    ),
                                    onClick:
                                      header.column.getToggleSortingHandler(),
                                    colSpan: header.colSpan,
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}

                                  {{
                                    asc: (
                                      <div className="absolute flex top-0 right-4 bottom-0 items-center">
                                        ↑
                                      </div>
                                    ),
                                    desc: (
                                      <div className="absolute flex top-0 right-4 bottom-0 items-center">
                                        ↓
                                      </div>
                                    ),
                                  }[header.column.getIsSorted() as string] ??
                                    null}
                                </div>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>

                <tbody>
                  {table.getRowModel().rows.map((row) => {
                    return (
                      <tr
                        key={row.id}
                        className={"cursor-pointer"}
                        onClick={() => {
                          // No matter what the user role / disassociated role is go to consumer db
                          handleOpenConsumerDatabase(row.original.userId);
                        }}
                      >
                        {row.getVisibleCells().map((cell, index) => {
                          return (
                            <td
                              key={cell.id}
                              className={classNames("h-14", {
                                "bg-very-light-gray": row.index % 2 === 1,
                                "bg-white": row.index % 2 === 0,
                                "sticky z-sticky": cell.column.getIsPinned(),
                                "border-b border-light-gray":
                                  row.index !== page.pageSize - 1,
                              })}
                              style={{
                                left:
                                  cell.column.getSize() *
                                  cell.column.getPinnedIndex(),
                                width: cell.column.getSize(),
                              }}
                            >
                              <div
                                className={classNames(
                                  "flex items-center px-6 text-dark-gray h-full",
                                  {
                                    "py-0 border-r border-light-gray":
                                      numberOfStickyHeaders - 1 === index,
                                  }
                                )}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="w-full flex justify-between">
            {/* Explanatory legend */}
            <div className="flex items-center space-x-4 text-dark-gray">
              <div className="inline-flex items-center space-x-2">
                <CheckMarkIcon className="h-6 w-6 text-success" />

                <span className="uppercase">Action Completed</span>
              </div>

              <div className="inline-flex items-center space-x-2">
                <CrossIcon className="h-6 w-6 text-error" />

                <span className="uppercase">None / Invalid</span>
              </div>

              <div className="inline-flex items-center space-x-2">
                <CircleLineThroughIcon className="h-6 w-6 text-gray" />

                <span className="uppercase">No data</span>
              </div>
            </div>

            {/* Pagination */}
            <div>
              <div className="flex flex-row space-x-2 items-center text-dark-gray">
                <button
                  className={classNames(
                    "flex w-8 aspect-square rounded bg-light-gray justify-center items-center",
                    { "opacity-40": !(page.currentPage > 1) }
                  )}
                  disabled={!(page.currentPage > 1)}
                  onClick={() =>
                    setPage((p) => ({
                      ...p,
                      direction: "before",
                      cursor: users[0].cursor,
                      currentPage: p.currentPage - 1,
                    }))
                  }
                >
                  <div className="relative">
                    <ChevronRightIcon className="w-4 aspect-square rotate-180" />
                  </div>
                </button>

                <div className="flex flex-row space-x-2">
                  <div className="flex h-8 px-2 rounded justify-center items-center bg-light-gray">
                    {page.currentPage} / {Math.ceil(totalCount / page.pageSize)}
                  </div>
                </div>

                <button
                  className={classNames(
                    "flex w-8 aspect-square rounded bg-light-gray justify-center items-center",
                    {
                      "opacity-40": !(
                        Math.ceil(totalCount / page.pageSize) > page.currentPage
                      ),
                    }
                  )}
                  disabled={
                    !(Math.ceil(totalCount / page.pageSize) > page.currentPage)
                  }
                  onClick={() =>
                    setPage((p) => ({
                      ...p,
                      direction: "after",
                      cursor: users[users.length - 1].cursor,
                      currentPage: p.currentPage + 1,
                    }))
                  }
                >
                  <div className="relative">
                    <ChevronRightIcon className="w-4 aspect-square" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}
