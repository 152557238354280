import React, { ComponentType, ReactElement, Suspense } from "react";

// Define a type for the HOC
function withSuspense<P extends object>(
  WrappedComponent: ComponentType<P>,
  fallback: ReactElement | null = null
) {
  // The returned component doesn't introduce new props, so we can use the same type P
  const WithSuspense: React.FC<P> = (props) => {
    return (
      <Suspense fallback={fallback}>
        <WrappedComponent {...props} />
      </Suspense>
    );
  };

  return WithSuspense;
}

export default withSuspense;
