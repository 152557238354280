import classNames from "classnames";
import { ReactNode } from "react";

type TextInputProps = {
  label?: string;
  placeholder: string;
  icon?: ReactNode;
  iconRight?: ReactNode;
  required?: boolean;
  value?: string;
  fieldName?: string;
  onChange?: (value: string) => void;
  assistiveText?: string;
  dataTestId?: string;
};

export default function TextInput({
  placeholder,
  icon,
  iconRight,
  value,
  onChange,
  fieldName,
  label,
  required,
  assistiveText,
  dataTestId,
}: TextInputProps) {
  return (
    <div className="flex flex-col">
      <label
        className={classNames("block text-sm font-medium text-gray-700", {
          "mb-1": label || required,
        })}
        htmlFor={fieldName}
      >
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <div className="relative">
        {icon && (
          <div className="absolute top-1/2 left-2 transform -translate-y-1/2 text-gray-400">
            {icon}
          </div>
        )}
        <input
          data-test-id={dataTestId}
          type="text"
          placeholder={placeholder}
          aria-label={label}
          value={value}
          className={classNames(
            "w-full py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500",
            {
              "pl-12": icon,
              "pl-4": !icon,
              "pr-12": iconRight,
              "pr-4": !iconRight,
            }
          )}
          onChange={(e) => {
            if (onChange) onChange(e.target.value);
          }}
          aria-placeholder={placeholder}
          name={fieldName}
        />
        {iconRight && (
          <div className="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400">
            {iconRight}
          </div>
        )}
      </div>
      {assistiveText && (
        <label className="block text-xs font-light text-gray-500 mt-1">
          {assistiveText}
        </label>
      )}
    </div>
  );
}
