import { Block, CheckCircle, Error, Warning } from '@mui/icons-material';
import classNames from 'classnames';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { DataStatusSize, DataStatusType } from './dataStatusConfig';

const DataStatus: FC<{
  status: DataStatusType;
  size?: DataStatusSize;
  label?: string;
  labelClassName?: string;
}> = ({ status, size = 'LARGE', label, labelClassName }) => {
  return (
    <div
      className={classNames('flex flex-row items-center', {
        'space-x-2': size === 'SMALL',
        'space-x-3': size === 'LARGE'
      })}
    >
      <div
        className={classNames('flex relative', {
          'size-5': size === 'SMALL',
          'size-6': size === 'LARGE'
        })}
      >
        {status === 'COMPLETE' && (
          <CheckCircle className="size-full max-h-full max-w-full icon-success" />
        )}

        {status === 'INCOMPLETE' && (
          <Error className="size-full max-h-full max-w-full icon-danger" />
        )}

        {status === 'REQUIRES_ATTENTION' && (
          <Warning className="size-full max-h-full max-w-full icon-warning" />
        )}

        {status === 'NO_DATA' && (
          <Block className="size-full max-h-full max-w-full icon-tertiary" />
        )}
      </div>

      {label && (
        <p
          className={twMerge(
            classNames('text-secondary', {
              'body-02': size === 'SMALL',
              'body-01': size === 'LARGE'
            }),
            labelClassName
          )}
        >
          {label}
        </p>
      )}
    </div>
  );
};

export default DataStatus;
