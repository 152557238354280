import classNames from "classnames";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ChevronDown } from "react-feather";

export type DropdownCheckboxOption<T> = {
  key: string;
  label: string;
  value: T;
  deprecated?: boolean;
};

type DropdownCheckboxProps<T> = {
  options: DropdownCheckboxOption<T>[];
  onSelect: (selected: T[]) => void;
  onSearch?: (inputValue: string) => void;
  title?: string;
  displayFilter?: boolean;
  align?: "LEFT" | "RIGHT";
};

/**
 * A component to create dropdowns.
 *
 * The generic type T represents the values of the dropdown
 *
 * @param param0
 * @returns
 */
const DropdownCheckbox = <T,>({
  options,
  onSelect,
  onSearch,
  title,
  displayFilter,
  align = "RIGHT", // Default to "RIGHT" alignment
}: DropdownCheckboxProps<T>) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<
    DropdownCheckboxOption<T>[]
  >([]);
  const [inputValue, setInputValue] = useState("");
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target;
    if (!(target instanceof Node)) return;

    if (
      dropdownButtonRef.current &&
      !dropdownButtonRef.current.contains(target) &&
      dropdownMenuRef.current &&
      !dropdownMenuRef.current.contains(target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  const handleOptionChange = (
    event: ChangeEvent<HTMLInputElement>,
    option: DropdownCheckboxOption<T>
  ) => {
    const updatedSelection = event.target.checked
      ? [...selectedOptions, option]
      : selectedOptions.filter(
          (selectedOption) => selectedOption.key !== option.key
        );

    setSelectedOptions(updatedSelection);
    onSelect(updatedSelection.map((v) => v.value));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    if (onSearch) {
      onSearch(value);
    }
  };

  const clearAll = () => {
    setSelectedOptions([]);
    onSelect([]);
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="relative inline-block text-left"
      style={{
        zIndex: 2,
      }}
    >
      <button
        ref={dropdownButtonRef}
        onClick={toggleDropdown}
        className="inline-flex justify-between w-full items-center
        h-full px-2 py-2 font-medium text-gray-400 bg-white hover:bg-gray-100 border border-gray-300 rounded-md "
      >
        <p className="ml-2">{title ?? "Select Item"}</p>
        <ChevronDown className="w-5 h-5 ml-8 mr-1" />
      </button>
      <div
        ref={dropdownMenuRef}
        className={classNames(
          "origin-top-right absolute mt-2 w-80 rounded-md shadow-lg bg-white ",
          {
            hidden: !isDropdownOpen,
            "left-0": align === "LEFT", // Align left
            "right-0": align === "RIGHT", // Align right
          }
        )}
      >
        <div className="flex flex-col p-2 max-h-[600px] overflow-y-auto">
          <div
            className={classNames("px-2", {
              hidden: !displayFilter,
            })}
          >
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Search"
              className="w-full px-4 py-2 mb-2 border border-gray-300 rounded-md focus:outline-none"
            />
          </div>

          <div className="flex-grow overflow-y-auto">
            {options.map((option) => (
              <label
                key={option.key}
                className={classNames(
                  "flex flex-row items-center px-4 py-2 mb-1 text-sm bg-white hover:bg-gray-100 cursor-pointer",
                  {
                    "text-gray-700": !option.deprecated,
                    "text-gray-400": option.deprecated,
                  }
                )}
              >
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={selectedOptions.some(
                    (selectedOption) => selectedOption.key === option.key
                  )}
                  onChange={(event) => {
                    handleOptionChange(event, option);
                  }}
                />
                {option.label}
              </label>
            ))}
          </div>
          <button
            onClick={clearAll}
            className="block w-full px-4 py-2 text-sm text-blue-500 hover:text-blue-300 focus:outline-none"
          >
            Clear all
          </button>
        </div>
      </div>
    </div>
  );
};

export default DropdownCheckbox;
