import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Spinner from "~/components/Spinner";
import meQuery from "~/query/meQuery";
import { Link, useParams } from "~/router";
import { dealershipAtom } from "~/state";
import TransactionCompliance from "../_components/TransactionCompliance";
import TransactionCredit from "../_components/TransactionCredit";
import TransactionCustomerPersonalInformationBar from "../_components/TransactionCustomerPersonalInformationBar";
import { transactionQuery } from "../_queries/transactionQuery";

export default function TransactionPage() {
  const { transactionId } = useParams("/dashboard/transaction/:transactionId");
  const dealership = useAtomValue(dealershipAtom);
  const { data, isLoading } = useQuery(
    transactionQuery(transactionId, dealership?.activeDealershipPerms)
  );
  const { data: meData } = useQuery(meQuery());
  const transaction = data?.transaction;
  const customer = transaction?.coBuyer;

  return (
    <>
      {isLoading && (
        <div className="flex relative flex-grow justify-center items-center">
          <Spinner />
        </div>
      )}

      {!isLoading && (
        <div className="flex flex-col space-y-4 max-h-dvh overflow-hidden">
          <BreadCrumbsContainer>
            <BreadCrumb title="Transaction">
              <Link to={"/dashboard"}>Transactions</Link>
            </BreadCrumb>

            {/* Conditional breadcrumb since transaction.buyerId could be undefined */}
            <BreadCrumb title="Users">
              {transaction?.buyerId ? (
                <Link
                  to={"/dashboard/transaction/:transactionId"}
                  params={{
                    transactionId,
                  }}
                >
                  {transaction?.title}
                </Link>
              ) : (
                <span>{transaction?.title}</span>
              )}
            </BreadCrumb>

            <BreadCrumb title="Co-Buyer">
              <span>
                {transaction?.customerSharedData?.coBuyerName
                  ? `${transaction.customerSharedData?.coBuyerName} (Co-Buyer)`
                  : "Co-Buyer"}
              </span>
            </BreadCrumb>
          </BreadCrumbsContainer>

          <div className="grid grid-cols-2 gap-4 overflow-y-scroll">
            <div className="col-span-2">
              {transaction && customer && (
                <TransactionCustomerPersonalInformationBar
                  isPrimaryBuyer={false}
                  transaction={transaction}
                />
              )}
            </div>

            <div className="col-span-2 lg:col-span-1 h-full">
              {transaction && (
                <TransactionCredit
                  meData={meData}
                  transaction={transaction}
                  transactionRole="CO_BUYER"
                />
              )}
            </div>

            <div className="col-span-2 lg:col-span-1 h-full">
              {customer?.userId && (
                <TransactionCompliance
                  transactionId={transactionId}
                  userId={customer.userId}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
