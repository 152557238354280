import { FC } from "react";

const PulsingStatusIndicator: FC<{
  active: boolean;
  text: string;
}> = ({ active, text }) => {
  return (
    <div className="flex items-center">
      <span
        className={`w-2 h-2 rounded-full mr-2 ${
          active ? "bg-red-500 animate-pulse" : "bg-green-500 animate-pulse"
        }`}
      ></span>
      <p className="text-sm">{text}</p>
    </div>
  );
};

export default PulsingStatusIndicator;
