import {
  GraphQLTypes,
  InputType,
  Selector,
} from "~/__generated__/backend/zeus";
import { gqlQueryClient } from "~/lib/backend";

// Selector is separated so we can infer the selector type
const selector = Selector("Query")({
  me: {
    user: {
      id: true,
      email: true,
      firstName: true,
      lastName: true,
      // Used to identify if current user have a group level permission
      userGroup: [
        {
          first: 1,
          filter: {
            isSuspended: false,
          },
        },
        {
          edges: {
            node: {
              role: true,
              groupId: true,
            },
          },
        },
      ],
    },
    dealershipPerms: {
      dealershipId: true,
      dealershipName: true,
      role: true,
    },
  },
});

export default function meQuery() {
  return {
    enabled: true,
    queryKey: ["profile"],
    queryFn: () => {
      return gqlQueryClient({ throwOnError: true, dealershipId: null })(
        selector
      );
    },
  };
}

export type MeQueryType = InputType<GraphQLTypes["Query"], typeof selector>;
