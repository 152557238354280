import classNames from "classnames";
import { FC } from "react";

const Header: FC<{
  totalSteps: number;
  currentStep: number;
}> = ({ totalSteps, currentStep }) => {
  return (
    <div className="flex w-full justify-center items-center py-3.5 h-14">
      <div className="flex flex-row w-full -bottom-[5px] gap-3">
        {Array.from(Array(totalSteps)).map((_step, index) => {
          return (
            <div
              key={index}
              className={classNames("h-1 md:h-2.5 w-full", {
                "bg-very-light-gray": index + 1 > currentStep,
                "bg-primary-blue": index + 1 <= currentStep,
              })}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Header;
