import { gqlMutationClient } from "~/lib/backend";

const suspendStaffAction = async (userId: string, isSuspended: boolean) => {
  const resp = await gqlMutationClient()({
    suspendStaff: [
      {
        userId,
        isSuspended,
      },
      {
        __typename: true,
        "...on GraphQLError": {
          message: true,
        },
        "...on MutationSuspendStaffSuccess": {
          data: {
            id: true,
            status: true,
          },
        },
      },
    ],
  });

  if (!resp.suspendStaff || resp.suspendStaff.__typename === "GraphQLError") {
    throw new Error(resp.suspendStaff?.message ?? "Unexpected error");
  }

  return resp.suspendStaff.data;
};

export default suspendStaffAction;
