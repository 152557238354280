import { FC } from "react";

const BuyerBadge: FC<{
  isPrimaryBuyer: boolean;
}> = ({ isPrimaryBuyer }) => {
  return (
    <div className="flex flex-row px-2 py-1 items-center justify-center bg-green-100 rounded-md border-[0.5px] border-green-500">
      <p className="text-green-500 text-sm">
        {isPrimaryBuyer && "Primary Buyer"}
        {!isPrimaryBuyer && "Co-Buyer"}
      </p>
    </div>
  );
};

export default BuyerBadge;
