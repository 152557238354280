import React, { ReactNode } from "react";

interface DisplayOnHoverWrapperProps {
  children: ReactNode;
}

const DisplayOnHoverWrapper: React.FC<DisplayOnHoverWrapperProps> = ({
  children,
}) => {
  return (
    <div className="relative group">
      <div className="absolute inset-0 flex items-center justify-end opacity-0 group-hover:opacity-100 hover:cursor-pointer transition-opacity duration-100">
        {children}
      </div>
    </div>
  );
};

export default DisplayOnHoverWrapper;
