import classNames from "classnames";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { removeCoBuyerAction } from "~/actions/removeCoBuyerAction";
import Button from "~/components/Button";
import { queryClient } from "~/lib/query";
import { useModals, useNavigate } from "~/router";
import { CustomerType } from "../_queries/transactionQuery";

const TransactionCoBuyerCard: FC<{
  transactionId: string;
  coBuyerName: string;
  coBuyer?: CustomerType;
  isCoBuyer: boolean;
}> = ({ transactionId, coBuyerName, coBuyer, isCoBuyer }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isRemovingCoBuyer, setIsRemovingCoBuyer] = useState(false);
  const modals = useModals();

  const handleGotoCoBuyer = () => {
    if (!coBuyer) return;
    navigate("/dashboard/transaction/:transactionId/co-buyer", {
      params: {
        transactionId,
      },
    });
  };

  const handleRemoveCoBuyer = async () => {
    try {
      setIsRemovingCoBuyer(true);
      await removeCoBuyerAction(transactionId);
      void queryClient.resetQueries({
        queryKey: ["transaction", transactionId],
      });
      modals.close();
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setIsRemovingCoBuyer(false);
    }
  };

  return (
    <div className="rounded-2xl bg-white shadow-md p-8 space-y-8 h-full w-full">
      <div className="flex flex-row space-x-2 items-center">
        <h2 className="text-heading-1">Co-Buyer</h2>
      </div>

      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-3 lg:grid-cols-4 gap-8">
          <div className="text-body text-dark-gray lg:col-span-2">
            Co-Applicant
          </div>
          <div>
            {!coBuyer?.userId && (
              <Button
                variant="TEXT_ONLY"
                onClick={() => {
                  navigate(
                    "/dashboard/transaction/:transactionId/co-buyer/add",
                    { params: { transactionId } }
                  );
                }}
              >
                {t("+ Add ")}
              </Button>
            )}

            {!!coBuyer?.userId && (
              <Button
                variant="TEXT_ONLY"
                loading={isRemovingCoBuyer}
                onClick={() => {
                  void handleRemoveCoBuyer();
                }}
              >
                <span className="text-error">Remove</span>
              </Button>
            )}
          </div>
        </div>

        <div className="grid grid-cols-3 lg:grid-cols-4 gap-8">
          <div className="text-body text-dark-gray lg:col-span-2">Co-Buyer</div>
          <div
            className={classNames({
              "text-blue-400 cursor-pointer": coBuyerName.length,
            })}
            onClick={handleGotoCoBuyer}
          >
            <p>{coBuyerName.length ? coBuyerName : "-"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionCoBuyerCard;
