import { FC } from "react";

const LogoIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 90 45"
    >
      <title>Logo icon</title>

      <path
        d="M32.2738 9.09268H40.9319H46.8146H48.4348C49.1248 9.09268 49.7637 9.18201 50.3975 9.26609C53.1472 9.64444 55.4063 10.7953 57.1287 12.8079C57.3433 13.0601 57.5018 13.3544 57.696 13.6224C58.3451 14.5157 58.8817 15.4878 59.2804 16.5546C59.9397 18.3045 60.3077 20.2645 60.3077 22.4821C60.3077 24.6734 59.9499 26.6124 59.3059 28.3465C59.7199 29.0349 60.1953 29.697 60.7779 30.3223C62.4083 32.0669 64.2943 33.0496 66.4767 33.3754C68.2247 30.2067 69.1753 26.6071 69.1753 22.4821C69.1753 18.3675 68.2247 14.7627 66.4818 11.5835C65.9554 10.6271 65.3421 9.72326 64.6572 8.85095C64.233 8.3097 63.865 7.73692 63.3794 7.2272C61.6672 5.43529 59.7046 4.11107 57.5529 3.11264C56.362 2.56088 55.0996 2.12998 53.7707 1.81994C52.0892 1.42583 50.3361 1.16309 48.4399 1.16309H35.0133H32.2738H0.626465V9.02436H13.4755V43.7327H22.1336V9.01911H32.2738V9.09268Z"
        fill="currentColor"
      />

      <path
        d="M46.769 35.7973H40.9322V12.7021H32.2742V43.7269H48.4403C50.3058 43.7269 52.0333 43.4747 53.6893 43.0963C52.524 42.266 51.3842 41.3675 50.3263 40.306C48.9207 38.9029 47.7708 37.3843 46.769 35.7973Z"
        fill="currentColor"
      />

      <path
        d="M75.1095 34.4518C72.9986 35.8706 70.6527 36.58 68.0767 36.5852C68.0716 36.5852 68.0614 36.5905 68.0563 36.5905C66.8552 36.5905 65.7308 36.3908 64.6473 36.0965C62.3933 35.4922 60.3744 34.3099 58.6316 32.4444C58.284 32.0766 58.0234 31.6667 57.7218 31.2778C56.991 30.3267 56.3674 29.3283 55.9125 28.2563C55.1612 26.4906 54.7523 24.5726 54.7523 22.4864C54.7523 20.3582 55.151 18.4244 55.8921 16.6535C55.5701 16.0387 55.3401 15.3556 54.8903 14.8353C53.3724 13.0592 51.2871 12.2079 48.5425 12.1816C46.8865 15.2768 45.9563 18.6819 45.9563 22.4864C45.9563 26.2647 46.8712 29.6488 48.5016 32.7282C49.0331 33.7318 49.6311 34.704 50.3313 35.6341C50.9753 36.4801 51.6091 37.3314 52.3911 38.1144C53.9448 39.6698 55.6672 40.8417 57.4612 41.8348C58.5907 42.4602 59.7662 42.9804 60.9929 43.3798C63.2008 44.0997 65.5263 44.5569 68.0461 44.5569C73.0038 44.5569 77.4299 43.0855 81.3296 40.1428C85.2293 37.2001 87.8614 33.4166 89.2363 28.8028H79.9138C78.8303 31.1412 77.2254 33.0277 75.1095 34.4518Z"
        fill="currentColor"
      />

      <path
        d="M68.0966 8.38297C70.7083 8.38823 73.0696 9.08713 75.1754 10.4744C77.2913 11.8722 78.9013 13.7692 79.995 16.1654H89.2459C87.8506 11.5464 85.2083 7.77341 81.3188 4.84119C77.4344 1.90898 73.0134 0.442871 68.0557 0.442871C65.5769 0.442871 63.2872 0.889535 61.1099 1.58318C62.6789 2.56058 64.1714 3.67987 65.5053 5.07766C66.4918 6.11287 67.33 7.2269 68.0966 8.38297Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LogoIcon;
