import { FC, useState } from 'react';
import InputBase from '..';
import { InputBackgroundType } from '../inputConfig';

/**
 * @param button: Maximum height of 40 px
 * @returns
 */
const TextInput: FC<{
  value: string;
  label: string;
  assistiveMessage: string;
  errorMessage?: string;
  placeholder: string;
  onChange: (value: string) => void;
  required: boolean;
  disabled: boolean;
  backgroundType?: InputBackgroundType;
}> = ({
  value,
  label,
  assistiveMessage,
  errorMessage,
  placeholder,
  onChange,
  required,
  disabled,
  backgroundType = 'DARK'
}) => {
  const [isTyping, setIsTyping] = useState(false);

  return (
    <InputBase
      inputComponent={
        <input
          type="text"
          value={value}
          className="body-01 w-full px-spacing-02 py-[6px] text-primary outline-none placeholder:text-tertiary disabled:text-tertiary bg-transparent"
          placeholder={placeholder}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onFocus={() => {
            setIsTyping(true);
          }}
          onBlur={() => {
            setIsTyping(false);
          }}
          disabled={disabled}
        />
      }
      label={label}
      assistiveMessage={assistiveMessage}
      required={required}
      disabled={disabled}
      isBeingUpdated={isTyping}
      errorMessage={errorMessage}
      backgroundType={backgroundType}
    />
  );
};

export default TextInput;
