import { z } from "zod";
import { EmploymentStatus, IncomeInterval } from "../tdc";
import { phoneNumber } from "../validation/phonenumber";

export const EmploymentSchema = z.object({
  timelinePosition: z.number(),
  employmentStatus: EmploymentStatus,
  employerName: z.string(),
  occupation: z.string(),
  employerPhoneNumber: phoneNumber(),
  employerAddressId: z.string(),
  durationYears: z.number(),
  durationMonths: z.number(),
  incomeAmount: z.number(),
  incomeInterval: IncomeInterval,
  otherIncomeSource: z.string(),
  otherIncomeAmount: z.number(),
  otherIncomeInterval: IncomeInterval,
});

export type EmploymentSchema = z.TypeOf<typeof EmploymentSchema>;
