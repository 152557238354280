import { signIn } from "aws-amplify/auth";
import config from "~/config";
import { postAuthenticationAction } from "./postAuthenticationAction";

export const cognitoLoginAction = async (
  email: string,
  password: string
): Promise<
  [
    (
      | "OK"
      | "MFA_REQUIRED_TOTP"
      | "MFA_REQUIRED_SMS"
      | "NEW_PASSWORD_REQUIRED"
      | "SELECT_MFA"
      | "FORGOT_PASSWORD_RESET_CODE_SEND"
    ),
    ("TOTP" | "SMS" | "EMAIL")[] | null | undefined,
  ]
> => {
  if (!config.cognito.clientId) {
    throw new Error("Cognito client id is not set");
  }

  const { nextStep, isSignedIn } = await signIn({ username: email, password });

  if (isSignedIn) {
    await postAuthenticationAction();
    return ["OK", null];
  }

  if (nextStep.signInStep === "DONE") {
    await postAuthenticationAction();
    return ["OK", null];
  }

  if (nextStep.signInStep === "RESET_PASSWORD") {
    return ["FORGOT_PASSWORD_RESET_CODE_SEND", null];
  }

  if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_SMS_CODE") {
    return ["MFA_REQUIRED_SMS", null];
  }
  if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
    return ["MFA_REQUIRED_TOTP", null];
  }
  if (nextStep.signInStep === "CONTINUE_SIGN_IN_WITH_MFA_SELECTION") {
    return ["SELECT_MFA", nextStep.allowedMFATypes];
  }

  if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
    return ["NEW_PASSWORD_REQUIRED", null];
  }

  throw new Error(`Unexpected next step ${nextStep.signInStep}`);
};
