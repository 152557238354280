// Will safely select latest residential address from the customer

import { parseISO } from "date-fns";
import { ConsumerCustomer } from "~/query/consumerDatabaseQuery";

export type TransactionHistoryType = {
  buyerId?: string | undefined;
  coBuyerId?: string;
  disassociatedRole?: string;
  transactionId: string;
  createdAt?: Date;
  status: string;
};

const getDateFromUnkown = (date: unknown): Date => {
  // If the object is a date, typeof returns "object"
  if (typeof date === "object") {
    return date as Date;
  }

  return parseISO(String(date));
};

// timeline position = 0 indicates the latest address
export const getLatestResidentialAddress = (
  customers: ConsumerCustomer[] | undefined
) => {
  try {
    if (!customers || !customers.length) return null;

    const customer = customers[0];
    if (!customer.residentialAddresses?.edges) return null;

    const residentialAddress = customer.residentialAddresses.edges.find(
      (edge) => edge.node && edge.node.timelinePosition === 0
    );

    // Return the node of the found address or the first node if not found
    return residentialAddress
      ? residentialAddress.node
      : customer.residentialAddresses.edges[0].node;
  } catch (error) {
    return undefined;
  }
};
