import classNames from "classnames";
import React from "react";
import { ChevronRight } from "react-feather";

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  onNextPage: () => void;
  onPrevPage: () => void;
};

const DealJacketPageNav: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onNextPage,
  onPrevPage,
}) => {
  const hasPreviousPage = currentPage > 1;
  const hasNextPage = totalPages > currentPage;

  return (
    <div className="flex flex-row space-x-2 items-center text-dark-gray">
      <button
        className={classNames(
          "flex w-8 aspect-square rounded bg-light-gray justify-center items-center",
          { "opacity-40": !hasPreviousPage }
        )}
        disabled={!hasPreviousPage}
        onClick={() => onPrevPage()}
      >
        <div className="relative">
          <ChevronRight className="w-4 aspect-square rotate-180" />
        </div>
      </button>

      <div className="flex flex-row space-x-2">
        <div className="flex h-8 px-2 rounded justify-center items-center bg-light-gray">
          {currentPage} / {totalPages}
        </div>
      </div>

      <button
        className={classNames(
          "flex w-8 aspect-square rounded bg-light-gray justify-center items-center",
          {
            "opacity-40": !hasNextPage,
          }
        )}
        disabled={!hasNextPage}
        onClick={() => onNextPage()}
      >
        <div className="relative">
          <ChevronRight className="w-4 aspect-square" />
        </div>
      </button>
    </div>
  );
};

export default DealJacketPageNav;
