import { Role } from "~/__generated__/backend/zeus";
import { gqlMutationClient } from "~/lib/backend";

const updateUserGroupRoleAction = async (userId: string, role: Role) => {
  const resp = await gqlMutationClient()({
    updateGroupUser: [
      {
        userId,
        role: role,
      },
      {
        __typename: true,
        "...on GraphQLError": {
          message: true,
        },
        "...on MutationUpdateGroupUserSuccess": {
          data: {
            status: true,
          },
        },
      },
    ],
  });

  if (
    !resp.updateGroupUser ||
    resp.updateGroupUser.__typename === "GraphQLError"
  ) {
    throw new Error(resp.updateGroupUser?.message ?? "Unexpected error");
  }

  return resp.updateGroupUser.data;
};

export default updateUserGroupRoleAction;
