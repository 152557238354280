import { flexRender, Table as ReactTableInstance } from "@tanstack/react-table";
import classNames from "classnames";

type TableProps<T> = {
  table: ReactTableInstance<T>;
  size?: "SM" | "MD" | "LG";
  stickyColumns?: string[];
  rowOnClick: (row: T) => void;
  disableEventPropagationOnColumns?: string[];
  verticalSeparatorColumns?: string[];
};

const sizeClasses = {
  SM: {
    th: "py-2 text-xs",
    td: "py-2 text-xs",
  },
  MD: {
    th: "py-3 text-sm",
    td: "py-3 text-sm",
  },
  LG: {
    th: "py-4 text-base",
    td: "py-4 text-base",
  },
};

// TODO: This table does not support sorting yet
const Table = <T,>({
  table,
  size = "MD",
  stickyColumns = [],
  disableEventPropagationOnColumns = [],
  verticalSeparatorColumns = [],
  rowOnClick,
}: TableProps<T>) => {
  return (
    <div className="rounded-md overflow-hidden shadow-md">
      <div className="relative flex flex-grow overflow-x-auto">
        <table
          className="table-fixed bg-white min-w-full"
          {...{
            style: {
              width: table.getCenterTotalSize(),
            },
          }}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b border-light-gray">
                {headerGroup.headers.map((header) => {
                  const hasVerticalSeparator =
                    verticalSeparatorColumns.includes(header.column.id);

                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={classNames(
                        "bg-white font-bold",
                        sizeClasses[size].th,
                        {
                          "sticky left-0": header.column.getIsPinned(),
                          "border-r-2 border-light-gray": hasVerticalSeparator,
                        }
                      )}
                      style={{
                        left:
                          header.column.getSize() *
                          header.column.getPinnedIndex(),

                        width: header.column.getSize(),
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className={classNames(
                            "relative flex w-full h-full px-6 items-center font-bold text-start text-dark-gray",
                            {
                              "border-r border-light-gray":
                                headerGroup.headers.length - 1 === header.index,
                            }
                          )}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={classNames("cursor-pointer hover:bg-gray-100", {
                  "bg-very-light-gray": row.index % 2 === 1,
                  "bg-white": row.index % 2 === 0,
                })}
                onClick={() => {
                  if (rowOnClick) {
                    rowOnClick(row.original);
                  }
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  const disablePropagation =
                    disableEventPropagationOnColumns.includes(cell.column.id);
                  const hasVerticalSeparator =
                    verticalSeparatorColumns.includes(cell.column.id);

                  return (
                    <td
                      key={cell.id}
                      className={classNames(sizeClasses[size].td, {
                        "border-b border-light-gray":
                          row.index !== table.getRowModel().rows.length - 1,
                        "border-r-2 border-light-gray": hasVerticalSeparator,
                      })}
                      onClick={(e) => {
                        if (disablePropagation) {
                          e.stopPropagation();
                        }
                      }}
                    >
                      <div className="flex items-center px-6 text-dark-gray h-full">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
