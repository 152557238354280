import { DataStatus, Tooltip } from "@thedealersconcierge/components";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import classNames from "classnames";
import { t } from "i18next";
import { useAtomValue } from "jotai";
import { FC } from "react";
import Button from "~/components/Button";
import { MeQueryType } from "~/query/meQuery";
import { useModals, useNavigate } from "~/router";
import { dealershipAtom } from "~/state";
import { TransactionQueryType } from "../_queries/transactionQuery";

/**
 * Be aware that this card is used both by a buyer and a cobuyer.
 *
 * @param param0
 * @returns
 */
const TransactionCredit: FC<{
  transaction: TransactionQueryType["transaction"];
  meData?: MeQueryType;
  transactionRole: "BUYER" | "CO_BUYER";
}> = ({ transaction, transactionRole, meData }) => {
  const navigate = useNavigate();
  const modals = useModals();
  const transactionId = transaction?.id ?? "no-transaction-id";
  const ds = useAtomValue(dealershipAtom);
  const canSeeCreditData =
    ds?.activeDealershipPerms &&
    permissionChecker("viewCreditReports", ds.activeDealershipPerms);

  const customer =
    transactionRole === "BUYER" ? transaction?.buyer : transaction?.coBuyer;

  const currentHardCreditApplication =
    customer?.hardCreditApplications?.edges?.at(0)?.node;
  const prequalData = canSeeCreditData
    ? customer?.prequalApplications?.edges?.at(0)?.node
    : undefined;
  const prequalScore = prequalData?.newestReportPrequalify?.score;
  const hasPrequalReport = Boolean(prequalData?.newestReportPrequalify);

  const isCoBuyer = meData?.me?.user?.id === transaction?.coBuyerId;

  const handleLendingPortalModal = () => {
    if (isCoBuyer) {
      modals.open(
        "/dashboard/transaction/[transactionId]/lending-portal/coBuyer",
        {
          params: { transactionId },
        }
      );
    } else {
      modals.open(
        "/dashboard/transaction/[transactionId]/lending-portal/buyer",
        {
          params: { transactionId },
        }
      );
    }
  };

  const handleGoToCreditReport = () => {
    if (transactionRole === "BUYER") {
      navigate("/dashboard/transaction/:transactionId/credit-report", {
        params: { transactionId },
      });
    } else {
      navigate("/dashboard/transaction/:transactionId/co-buyer/credit-report", {
        params: { transactionId },
      });
    }
  };

  // Sales and BDC can not see credit data
  if (!canSeeCreditData) {
    return (
      <div className="flex flex-col rounded-2xl bg-white shadow-md p-8 space-y-8 w-full h-full">
        <h1 className="text-heading-1 text-very-dark-gray">Credit</h1>

        <div className="space-y-4">
          <div className="flex flex-row space-x-2">
            <DataStatus
              status={
                customer?.hasPrequalApplication ? "COMPLETE" : "INCOMPLETE"
              }
            />

            <p className="text-dark-gray">Submitted Prequal</p>
          </div>

          <div className="flex flex-row space-x-2">
            <DataStatus
              status={
                customer?.hasSubmittedHardCreditApplication
                  ? "COMPLETE"
                  : "INCOMPLETE"
              }
            />

            <p className="text-dark-gray">Submitted Credit Application</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col rounded-2xl bg-white shadow-md p-8 space-y-8 w-full h-full">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row justify-between">
          <h2 className="text-heading-1 text-very-dark-gray">Credit</h2>
          {/* TODO: Need more design clarification and feature clarification for this button */}
          {hasPrequalReport && (
            <Button variant="SECONDARY" size="XS" className="hidden">
              View 700 credit
            </Button>
          )}
        </div>

        <div className="grid grid-rows-3 gap-4">
          {/* Credit Score */}
          <div className="grid grid-cols-4 justify-start items-center">
            <div className="flex flex-row gap-4 col-span-2">
              <Tooltip
                anchor={
                  <div>
                    <DataStatus
                      status={
                        hasPrequalReport && prequalScore
                          ? "COMPLETE"
                          : !prequalScore
                            ? "REQUIRES_ATTENTION"
                            : "INCOMPLETE"
                      }
                    />
                  </div>
                }
                content={
                  <>
                    {hasPrequalReport && prequalScore
                      ? t(
                          "Prequalification has been submitted and a score is available."
                        )
                      : !prequalScore
                        ? t(
                            "The prequalification has been submitted but no score is available."
                          )
                        : t("No prequalification has been submitted.")}
                  </>
                }
              />

              <div className="text-body text-dark-gray col-span-1">
                Credit Score
              </div>
            </div>

            <div className="col-span-1">{prequalScore}</div>
          </div>

          {/* Prequalification */}
          <div className="grid grid-cols-4 justify-start items-center">
            <div className="flex flex-row gap-4 col-span-2">
              <Tooltip
                anchor={
                  <div>
                    <DataStatus
                      status={
                        hasPrequalReport ? "COMPLETE" : "REQUIRES_ATTENTION"
                      }
                    />
                  </div>
                }
                content={
                  <>
                    {hasPrequalReport
                      ? "Prequal successfully submitted"
                      : "No prequal has been submitted"}{" "}
                  </>
                }
              />
              <div className="text-body text-dark-gray col-span-1">
                Prequalification Application
              </div>
            </div>

            <div>
              <Button
                size="DEFAULT"
                variant="TEXT_ONLY"
                onClick={handleGoToCreditReport}
              >
                View 700 Credit Report
              </Button>
            </div>
          </div>

          <div className="grid grid-cols-4 justify-start items-center">
            <div className="flex flex-row gap-4 col-span-2">
              <Tooltip
                anchor={
                  <div>
                    <DataStatus
                      status={
                        currentHardCreditApplication?.formSubmissionId
                          ? "COMPLETE"
                          : "REQUIRES_ATTENTION"
                      }
                    />
                  </div>
                }
                content={
                  <>
                    {currentHardCreditApplication?.formSubmissionId
                      ? "Credit application successfully submitted"
                      : "No credit application has been submitted"}
                  </>
                }
              />
              <div className="text-body text-dark-gray col-span-1">
                Credit Application
              </div>
            </div>

            {currentHardCreditApplication?.formSubmissionId &&
              permissionChecker(
                "pushHardCreditApplication",
                ds.activeDealershipPerms
              ) && (
                <div
                  className={classNames(
                    "flex space-x-2",
                    "xl:flex-col xl:space-x-0 xl:space-y-1 xl:items-start",
                    "2xl:flex-row 2xl:space-x-2 2xl:space-y-0"
                  )}
                >
                  {!transaction?.hardCreditApplDmsSubmittedTo && (
                    <Button
                      dataTestId="lending-portal-button"
                      variant="TEXT_ONLY"
                      className="text-block text-primary-blue"
                      onClick={handleLendingPortalModal}
                    >
                      <div className="text-start line-clamp-1">
                        Lending Portal
                      </div>
                    </Button>
                  )}
                  {transaction?.hardCreditApplDmsSubmittedTo && (
                    <p
                      className="text-start line-clamp-1"
                      data-test-id="lending-portal-is-submitted"
                    >
                      Submitted
                      {transaction.hardCreditApplDmsSubmittedTo === "ROUTE_ONE"
                        ? " to Route One"
                        : " "}
                    </p>
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionCredit;
