import { UserRole } from "@thedealersconcierge/lib/codecs/tdc";
import stringify from "json-stable-stringify";
import {
  GraphQLTypes,
  InputType,
  Selector,
  StringFilterMode,
} from "~/__generated__/backend/zeus";
import { gqlQueryClient } from "~/lib/backend";
import { tokenizeQuery } from "~/lib/helpers";
import { Pagination } from "~/pages/(authenticated)/dashboard/_dashboardUtils";

export const employeeUserSelector = Selector("User")({
  id: true,
  firstName: true,
  lastName: true,
});

// Create User "OR" filter based on preselected user id and tokenized name
// if no condition exists then return undefined
// Undefined returns are expected to ensure we don't query an empty or statement
function getUserFilter(tokenizedNameFilter: string[], userId?: string) {
  const conditions = [];

  if (tokenizedNameFilter.length > 0) {
    for (const token of tokenizedNameFilter) {
      conditions.push(
        {
          firstName: {
            contains: token,
            mode: StringFilterMode.Insensitive,
          },
        },
        {
          lastName: {
            contains: token,
            mode: StringFilterMode.Insensitive,
          },
        }
      );
    }
  }

  if (userId) {
    conditions.push({
      id: {
        equals: userId,
      },
    });
  }

  return conditions.length > 0 ? { OR: conditions } : undefined;
}

export default function employeeSelector(
  role: UserRole,
  delershipId?: string,
  pagination?: Pagination,
  namefilter?: string,
  userId?: string
) {
  return {
    enabled: Boolean(delershipId),
    queryKey: [
      "employees",
      delershipId,
      role,
      stringify(pagination),
      namefilter,
      userId,
    ],
    queryFn: async () => {
      if (!delershipId) {
        return;
      }
      const tokenizedNameFilter = namefilter ? tokenizeQuery(namefilter) : [];
      const userFilter = getUserFilter(tokenizedNameFilter, userId);

      return gqlQueryClient()({
        dealership: [
          { id: delershipId },
          {
            users: [
              {
                first: pagination?.pageSize ?? 10,
                rolesToInclude: [role],
                isSuspended: false,
                filter: {
                  ...(userFilter && { user: userFilter }),
                },
              },
              {
                edges: {
                  node: {
                    role: true,
                    user: employeeUserSelector,
                  },
                },
              },
            ],
          },
        ],
      });
    },
  };
}

export type EmployeeType = InputType<
  GraphQLTypes["User"],
  typeof employeeUserSelector
>;
