import { captureException } from "@sentry/react";
import { useForm } from "@tanstack/react-form";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  NumberInput,
  Select,
  TextInput,
} from "@thedealersconcierge/components";
import {
  VehicleCondition,
  VehiclePrincipalPriorUse,
} from "@thedealersconcierge/lib/codecs/schema/vehicle";
import { VehicleBodyTypeSchema } from "@thedealersconcierge/lib/codecs/tdc";
import { useAtomValue } from "jotai";
import { FC, Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { VehicleType } from "~/__generated__/backend/zeus";
import updateVehicleAction from "~/actions/vehicles/updateVehicleAction";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Spinner from "~/components/Spinner";
import Typeahead, { TypeaheadOption } from "~/components/inputs/Typeahead";
import vehicleBodyTypeOptions from "~/config/formSelectionOptions/vehicleBodyTypeOptions";
import { gqlMutationClient } from "~/lib/backend";
import {
  stringToVehicleBodyType,
  stringToVehicleCondition,
  stringToVehiclePrincipalPriorUse,
} from "~/lib/enumMap";
import { queryClient } from "~/lib/query";
import { Link, useNavigate, useParams } from "~/router";
import { VehicleType as Vehicle } from "~/selectors/vehicleSelector";
import { dealershipAtom } from "~/state";
import { transactionQuery } from "../../_queries/transactionQuery";
import {
  SingleHomenetVehicleData,
  fetchHomenetVehicleDataByStockNumber,
} from "./_queries/fetchHomenetByStocknumberQuery";

const VehiclePageContent: FC<{
  transactionId: string;
  transactionTitle?: string;
  vehicleType: VehicleType;
  vehicle: Partial<Vehicle | undefined>;
}> = ({ transactionId, transactionTitle, vehicleType, vehicle }) => {
  const { t } = useTranslation();
  const queryParams = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, []);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleCancel = () => {
    navigate("/dashboard/transaction/:transactionId", {
      params: { transactionId },
    });
  };
  const form = useForm({
    defaultValues: {
      stockNumber: vehicle?.stockNumber ?? "",
      vin: vehicle?.vin ?? "",
      bodyType: vehicle?.bodyType,
      make: vehicle?.make ?? "",
      model: vehicle?.model ?? "",
      year: vehicle?.year ?? "",
      color: vehicle?.color ?? "",
      condition: vehicle?.condition,
      isUsed:
        vehicle?.isUsed !== undefined
          ? vehicle.isUsed
            ? "USED"
            : "NEW"
          : undefined,
      mileage: vehicle?.mileage?.toString() ?? "",
      trim: vehicle?.trim ?? "",
      principalPriorUse: vehicle?.principalPriorUse,
    },
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);

        if (!queryParams.get("create")) {
          if (!vehicle?.id) {
            throw new Error("No vehicle to update");
          }

          await updateVehicleAction(transactionId, vehicleType, vehicle.id, {
            ...values,
            isUsed: values.isUsed ? values.isUsed === "USED" : undefined,
            mileage:
              values.mileage.length > 0
                ? parseFloat(values.mileage)
                : undefined,
            stockNumber: searchStockNumber,
          });
          await queryClient.invalidateQueries({
            queryKey: ["transaction", transactionId],
          });

          toast.success("Successfully updated vehicle");

          navigate("/dashboard/transaction/:transactionId", {
            params: { transactionId },
          });
        } else {
          const resp = await gqlMutationClient()({
            createVehicle: [
              {
                transactionId,
                vehicleType,
                vehicle: {
                  ...values,
                  isUsed: values.isUsed ? values.isUsed === "USED" : undefined,
                  mileage:
                    values.mileage.length > 0
                      ? parseFloat(values.mileage)
                      : undefined,
                  stockNumber: searchStockNumber,
                },
              },
              {
                __typename: true,
                "...on GraphQLError": {
                  message: true,
                },
                "...on MutationCreateVehicleSuccess": {
                  data: {
                    status: true,
                  },
                },
              },
            ],
          });

          if (
            !resp.createVehicle ||
            resp.createVehicle.__typename === "GraphQLError"
          ) {
            throw Error(resp.createVehicle?.message);
          } else {
            await queryClient.invalidateQueries({
              queryKey: ["transaction", transactionId],
            });

            toast.success("Successfully created vehicle");
            navigate("/dashboard/transaction/:transactionId", {
              params: { transactionId },
            });
          }
        }
      } catch (e) {
        console.error(e);
        captureException(e);
        toast.error("Failed to save vehicle data");
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  type FormKeys = keyof typeof form.state.values;

  const dealership = useAtomValue(dealershipAtom);
  const [searchStockNumber, setSearchStockNumber] = useState(
    vehicle?.stockNumber ?? ""
  );
  const { data, isLoading } = useQuery(
    fetchHomenetVehicleDataByStockNumber(
      dealership?.activeDealershipPerms.dealershipId,
      searchStockNumber
    )
  );
  const vehicleOptions: TypeaheadOption<SingleHomenetVehicleData>[] =
    data?.dealership?.homenetVehicles?.edges
      ?.map((node) => {
        const e = node.node;
        return {
          key: e?.stockNumber ?? e?.id ?? "no-key",
          label: `${e?.stockNumber}`,
          subtext: `${e?.make} ${e?.model} (${e?.year}) - ${e?.color}, ${e?.bodyType}`,
          value: e,
        };
      })
      .filter(
        (e): e is TypeaheadOption<SingleHomenetVehicleData> => !!e.value
      ) ?? [];

  const handleSelect = (
    selected: TypeaheadOption<SingleHomenetVehicleData>
  ) => {
    const bodyType = vehicleBodyTypeOptions.find(
      (el) => el.label === selected.value.bodyType
    );
    const stockNumber = selected.value.stockNumber ?? "";

    /**
     * Setting "touch" to "true" ensures a re-render of the form
     */
    form.setFieldValue("stockNumber", stockNumber, { touch: true });
    form.setFieldValue("vin", selected.value.vin ?? "", { touch: true });
    form.setFieldValue("make", selected.value.make ?? "", { touch: true });
    form.setFieldValue("model", selected.value.model ?? "", { touch: true });
    form.setFieldValue("year", selected.value.year ?? "", { touch: true });
    form.setFieldValue("color", selected.value.color ?? "", { touch: true });
    form.setFieldValue(
      "isUsed",
      selected.value.type
        ? selected.value.type.toUpperCase() === "USED"
          ? "USED"
          : "NEW"
        : undefined,
      { touch: true }
    );
    form.setFieldValue("mileage", selected.value.mileage ?? "", {
      touch: true,
    });
    form.setFieldValue("trim", selected.value.trim ?? "", { touch: true });
    form.setFieldValue(
      "bodyType",
      stringToVehicleBodyType(bodyType?.value ?? ""),
      { touch: true }
    );
    form.setFieldValue("condition", undefined, { touch: true }); // Condition is not part of the homenet data
    form.setFieldValue("principalPriorUse", undefined, { touch: true }); // Principle prior use is not part of the homenet data: https://developer.homenetauto.com/#!/doc-gateway
    setSearchStockNumber(stockNumber);
  };

  return (
    <Fragment>
      <BreadCrumbsContainer>
        <BreadCrumb title="Transaction">
          <Link to={"/dashboard"}>Transactions</Link>
        </BreadCrumb>

        <BreadCrumb title="Users">
          <Link
            to={"/dashboard/transaction/:transactionId"}
            params={{ transactionId }}
          >
            {transactionTitle}
          </Link>
        </BreadCrumb>

        <BreadCrumb title="Co-Buyer">
          <span>
            {vehicleType === VehicleType.PURCHASE
              ? t("Purchase Vehicle")
              : t("Trade Vehicle")}
          </span>
        </BreadCrumb>
      </BreadCrumbsContainer>

      <div className="flex justify-center p-8">
        <div className="flex w-1/2 min-w-fit flex-col bg-white rounded-xl shadow-md p-10 space-y-6">
          <h2 className="text-heading-2">
            {vehicleType === VehicleType.PURCHASE
              ? "Purchase Vehicle"
              : "Trade Vehicle"}
          </h2>

          <form.Provider>
            <form
              className="flex flex-col space-y-8"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                void form.handleSubmit();
              }}
            >
              <div className="grid grid-cols-2 gap-6 w-full">
                {vehicleType === VehicleType.PURCHASE && (
                  <div className="col-span-2 w-full">
                    <div className="flex flex-row gap-4 w-full">
                      <div className="flex-grow">
                        <div className="w-full">
                          <Typeahead
                            options={vehicleOptions}
                            onSelect={handleSelect}
                            onSearch={(searchString: string) => {
                              setSearchStockNumber(searchString);
                            }}
                            title="Enter Stock Number"
                            isLoading={isLoading}
                            selectedItemKey={vehicle?.stockNumber} // To preselect
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {vehicleType === VehicleType.TRADE && (
                  <form.Field name="condition">
                    {(field) => {
                      return (
                        <Select
                          options={VehicleCondition.options.map((o) => {
                            return {
                              value: o.value,
                              label: o.description ?? o.value,
                            };
                          })}
                          value={field.state.value ?? ""}
                          label="Condition"
                          assistiveMessage="Select the correct option"
                          placeholder="E.g., Excellent"
                          required={false}
                          disabled={isSubmitting}
                          onSelect={(option) => {
                            field.handleChange(
                              stringToVehicleCondition(option.value)
                            );
                          }}
                        />
                      );
                    }}
                  </form.Field>
                )}

                {vehicleType === VehicleType.PURCHASE && (
                  <form.Field name="principalPriorUse">
                    {(field) => {
                      return (
                        <form.Subscribe>
                          {(f) => {
                            return (
                              <Select
                                options={VehiclePrincipalPriorUse.options.map(
                                  (o) => {
                                    return {
                                      value: o.value,
                                      label: o.description ?? o.value,
                                    };
                                  }
                                )}
                                value={field.state.value ?? ""}
                                label="Principal Prior Use"
                                assistiveMessage="Select the correct option"
                                placeholder="E.g., Rental Vehicle"
                                required={
                                  f.values.isUsed?.toUpperCase() === "USED"
                                }
                                disabled={isSubmitting}
                                onSelect={(option) => {
                                  field.handleChange(
                                    stringToVehiclePrincipalPriorUse(
                                      option.value
                                    )
                                  );
                                }}
                              />
                            );
                          }}
                        </form.Subscribe>
                      );
                    }}
                  </form.Field>
                )}

                <form.Field name="isUsed">
                  {(field) => {
                    return (
                      <Select
                        options={[
                          { value: "USED", label: "Used" },
                          { value: "NEW", label: "New" },
                        ]}
                        value={field.state.value}
                        label="Type"
                        assistiveMessage="Select the correct option"
                        placeholder="E.g., New"
                        required={false}
                        disabled={isSubmitting}
                        onSelect={(option) => {
                          field.handleChange(option.value);
                        }}
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="vin">
                  {(field) => {
                    return (
                      <TextInput
                        value={field.state.value}
                        label="VIN#"
                        assistiveMessage="Type in the value"
                        placeholder="Start typing"
                        required={vehicleType === VehicleType.PURCHASE}
                        disabled={isSubmitting}
                        onChange={field.handleChange}
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="bodyType">
                  {(field) => {
                    return (
                      <Select
                        options={VehicleBodyTypeSchema.options.map((o) => {
                          return {
                            value: o.value,
                            label: o.description ?? o.value,
                          };
                        })}
                        value={field.state.value ?? ""}
                        label="Body Type"
                        assistiveMessage="Select the correct option"
                        placeholder="E.g., Coupe"
                        required={false}
                        disabled={isSubmitting}
                        onSelect={(option) => {
                          field.handleChange(
                            stringToVehicleBodyType(option.value)
                          );
                        }}
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="make">
                  {(field) => {
                    return (
                      <TextInput
                        value={field.state.value}
                        label="Make"
                        assistiveMessage="Type in the value"
                        placeholder="Start typing"
                        required={vehicleType === VehicleType.PURCHASE}
                        disabled={isSubmitting}
                        onChange={field.handleChange}
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="model">
                  {(field) => {
                    return (
                      <TextInput
                        value={field.state.value}
                        label="Model"
                        assistiveMessage="Type in the value"
                        placeholder="Start typing"
                        required={vehicleType === VehicleType.PURCHASE}
                        disabled={isSubmitting}
                        onChange={field.handleChange}
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="year">
                  {(field) => {
                    return (
                      <TextInput
                        value={field.state.value}
                        label="Year"
                        assistiveMessage="Type in the value"
                        placeholder="E.g., 2023"
                        required={vehicleType === VehicleType.PURCHASE}
                        disabled={isSubmitting}
                        onChange={field.handleChange}
                      />
                    );
                  }}
                </form.Field>

                {vehicleType === VehicleType.TRADE && (
                  <form.Field name="color">
                    {(field) => {
                      return (
                        <TextInput
                          value={field.state.value}
                          label="Color"
                          assistiveMessage="Type in the value"
                          placeholder="E.g., White"
                          required={false}
                          disabled={isSubmitting}
                          onChange={field.handleChange}
                        />
                      );
                    }}
                  </form.Field>
                )}

                <form.Field
                  name="mileage"
                  /**
                   * For some reason, we have to specify the default value here as well
                   * because in the onSubmit it was passed as undefined when it wasn't touched.
                   * The type system however expects it to be defined and I could also not
                   * find the reason for it being undefined.
                   * It might be a bug in tanstack forms.
                   */
                  defaultValue={vehicle?.mileage?.toString() ?? ""}
                >
                  {(field) => {
                    return (
                      <NumberInput
                        value={field.state.value}
                        label="Mileage"
                        assistiveMessage="Type in the value"
                        placeholder="E.g., 32,004"
                        required={false}
                        disabled={isSubmitting}
                        onChange={field.handleChange}
                      />
                    );
                  }}
                </form.Field>

                {vehicleType === VehicleType.PURCHASE && (
                  <form.Field name="trim">
                    {(field) => {
                      return (
                        <TextInput
                          value={field.state.value}
                          label="Trim"
                          assistiveMessage="Type in the value"
                          placeholder="E.g., Touring"
                          required={false}
                          disabled={isSubmitting}
                          onChange={field.handleChange}
                        />
                      );
                    }}
                  </form.Field>
                )}
              </div>

              <div className="flex flex-row justify-between">
                <Button
                  label="Cancel"
                  variant="SECONDARY"
                  type="button"
                  disabled={isSubmitting}
                  onClick={handleCancel}
                />

                <form.Subscribe>
                  {(f) => {
                    let canSubmit = true;

                    if (vehicleType === VehicleType.PURCHASE) {
                      const requiredFields: FormKeys[] = [
                        "make",
                        "model",
                        "year",
                        "vin",
                      ];

                      if (f.values.isUsed?.toUpperCase() === "USED") {
                        requiredFields.push("principalPriorUse");
                      }

                      canSubmit = requiredFields.every((field) =>
                        Boolean(f.values[field])
                      );
                    }

                    return (
                      <Button
                        label="Save"
                        type="submit"
                        disabled={!canSubmit}
                        isLoading={isSubmitting}
                      />
                    );
                  }}
                </form.Subscribe>
              </div>
            </form>
          </form.Provider>
        </div>
      </div>
    </Fragment>
  );
};

const VehiclePage = () => {
  const { transactionId, vehicleType } = useParams(
    "/dashboard/transaction/:transactionId/vehicle/:vehicleType"
  );
  const dealership = useAtomValue(dealershipAtom);
  const { data: transactionData, isLoading } = useQuery(
    transactionQuery(transactionId, dealership?.activeDealershipPerms)
  );

  return (
    <div className="flex flex-col space-y-4">
      {!isLoading && transactionData?.transaction ? (
        <VehiclePageContent
          transactionId={transactionId}
          transactionTitle={transactionData.transaction.title}
          vehicleType={
            vehicleType.toUpperCase() === "PURCHASE"
              ? VehicleType.PURCHASE
              : VehicleType.TRADE
          }
          vehicle={
            vehicleType.toUpperCase() === "PURCHASE"
              ? transactionData.transaction.vehicle
              : transactionData.transaction.tradeVehicle
          }
        />
      ) : (
        <div className="flex justify-center min-h-dvh">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default VehiclePage;
