// This file is a collection of mapping from Enum to Human readable string

import {
  DashboardUserRole,
  FinanceType,
} from "@thedealersconcierge/lib/codecs/tdc";
import {
  VehicleBodyType,
  VehicleCondition,
  VehiclePrincipalPriorUse,
} from "~/__generated__/backend/zeus";

export const getReadableFinanceType = (financeType?: FinanceType): string => {
  if (!financeType) return "-";

  const map = {
    CASH: "Cash",
    LEASE: "Lease",
    FINANCE: "Finance",
    OUTSIDE_FINANCING: "Outside Financing",
    UNDECIDED: "Undecided",
  };

  return map[financeType];
};

export const getReadableHoustingStatus = (
  housingStatus: string | null | undefined
) => {
  if (!housingStatus) {
    console.error("FAILED TO GET HOUSING STATUS", housingStatus);
    return "-";
  }
  const readableMap: { [key: string]: string } = {
    OWNING: "Owning",
    RENTING: "Renting",
    LIVING_WITH_RELATIVES: "Living with Relatives",
    OTHER: "Other",
  };

  return readableMap[housingStatus] ?? housingStatus;
};

export const getReadableFormSubmissionType = (formType: string) => {
  const formTypeMap: { [key: string]: string } = {
    PRIVACY_POLICY: "Privacy Policy",
    CONTACT_ACKNOWLEDGEMENT: "Contact Acknowledgement",
    IDENTITY_THEFT_PREVENTION: "Identity Theft Prevention",
    DMV_DOCUMENTS: "Driver's License DMV",
    IDENTITY_VERIFICATION: "Identity Verification",
    PREQUAL: "Prequal",
    HARD_CREDIT_APPLICATION: "Credit Application",
    HARD_CREDIT_APPLICATION_MODIFICATION: "Credit Application Modification",
    TEST_DRIVE_DISCLOSURE: "Test Drive Disclosure",
    UTILITY_BILLS: "Utility Bills",
    PROOF_OF_INCOME: "Proof of Income",
    BANK_STATEMENTS: "Bank Statements",
    PRE_PURCHASE: "Pre purchase",
    POST_PURCHASE: "Post purchase",
    OTHER: "Other",
  };

  return formTypeMap[formType] ?? formType;
};

export const getReadableRole = (role?: string | DashboardUserRole) => {
  if (!role) return;
  switch (role) {
    case "FNI_MANAGER":
      return "F&I Manager";
    case "SALES_MANAGER":
      return "Sales Manager";
    case "SALES_PERSON":
      return "Sales Person";
    case "ADMIN":
      return "Admin";
    case "BDC":
      return "BDC";
    default:
      return role;
  }
};

export const getReadableBodyType = (bodyType: VehicleBodyType): string => {
  const bodyTypeMap: { [key in VehicleBodyType]: string } = {
    COUPE: "Coupe",
    MINIVAN: "Minivan",
    OTHER: "Other",
    SEDAN: "Sedan",
    SUV: "SUV",
  };

  return bodyTypeMap[bodyType];
};

export const getReadablePrincipalPriorUse = (
  principalPriorUse: VehiclePrincipalPriorUse
): string => {
  const principalPriorUseMap: { [key in VehiclePrincipalPriorUse]: string } = {
    PRIVATE_PASSENGER_VEHICLE: "Private Passenger Vehicle",
    RENTAL_VEHICLE: "Rental vehicle",
    TAXI: "Taxi",
    LIVERY_VEHICLE: "Livery vehicle",
    COMMERCIAL: "Commercial",
    FLEET: "Fleet",
    DEMO: "Demo",
    OTHER: "Other",
  };

  return principalPriorUseMap[principalPriorUse];
};

export const getReadableVehicleCondition = (
  vehicleCondition: VehicleCondition
): string => {
  const vehicleConditionMap: { [key in VehicleCondition]: string } = {
    EXCELLENT: "Excellent",
    GOOD: "Good",
    SATISFACTORY: "Satisfactory",
    UNSATISFACTORY: "Unsatisfactory",
  };

  return vehicleConditionMap[vehicleCondition];
};
