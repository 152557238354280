import { confirmResetPassword } from "aws-amplify/auth";

export const resetForgottenPasswordAction = (
  email: string,
  otp: string,
  password: string
) => {
  return confirmResetPassword({
    username: email,
    confirmationCode: otp,
    newPassword: password,
  });
};
