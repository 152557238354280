import classNames from "classnames";
import { FC } from "react";
import CheckMarkIcon from "../icons/CheckMarkIcon";

const Checkbox: FC<{
  inputId: string;
  value: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  readOnly?: boolean;
  variant?: "DEFAULT" | "RADIO_BUTTON";
  inputClassName?: string;
  containerClassName?: string;
}> = ({
  inputId,
  value,
  disabled,
  onChange,
  readOnly,
  variant = "DEFAULT",
  inputClassName,
  containerClassName,
}) => {
  // Internal change handler to convert ChangeEvent to boolean
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  return (
    <div className={classNames("flex", containerClassName)}>
      <input
        id={inputId}
        type="checkbox"
        checked={value}
        disabled={disabled}
        className="hidden"
        onChange={handleChange}
        readOnly={readOnly}
      />

      {variant === "DEFAULT" && (
        <label
          htmlFor={inputId}
          className={classNames(
            "flex items-center justify-center rounded size-4",
            // We can't use the pseudo-class :disabled because we have to style the label and not the input itself
            {
              "cursor-pointer border-primary": !disabled,
              "cursor-not-allowed border-inactive": disabled,
              border: !value,
              "bg-interactive-primary": value && !disabled,
              "bg-primary-inverse": value && disabled,
            },
            inputClassName
          )}
        >
          {value && <CheckMarkIcon className="size-2.5 icon-inverse" />}
        </label>
      )}

      {variant === "RADIO_BUTTON" && (
        <label
          htmlFor={inputId}
          className={classNames(
            "flex items-center justify-center rounded-full size-5",
            // We can't use the pseudo-class :disabled because we have to style the label and not the input itself
            {
              "cursor-pointer": !disabled,
              "cursor-not-allowed border-inactive": disabled,
              "border-2": !value,
              "border-[0.375rem]": value,
              "border-interactive": value && !disabled,
              "border-primary": !value && !disabled,
            }
          )}
        />
      )}
    </div>
  );
};

export default Checkbox;
