import { Dialog, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { format, isBefore } from "date-fns";
import { Fragment, useState } from "react";
import { OrderBy } from "~/__generated__/backend/zeus";
import Button from "~/components/Button";
import { gqlQueryClient } from "~/lib/backend";

type Props = {
  transactionId: string;
  vehicleRole: "TRADE" | "PURCHASE";
};

export default function RecallCheckModal({
  transactionId,
  vehicleRole,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { data } = useQuery({
    // We can safely reuse the same queryKey for both the trade and purchase vehicle
    queryKey: ["recallChecks", transactionId],
    queryFn: async () =>
      gqlQueryClient()({
        transaction: [
          {
            id: transactionId,
          },
          {
            tradeVehicle: {
              updatedAt: true,
              vehicleRecallChecks: [
                {
                  first: 1,
                  orderby: {
                    createdAt: OrderBy.Desc,
                  },
                },
                {
                  edges: {
                    node: {
                      id: true,
                      createdAt: true,
                      updatedAt: true,
                      response: true,
                    },
                  },
                },
              ],
            },
            vehicle: {
              updatedAt: true,
              vehicleRecallChecks: [
                {
                  first: 1,
                  orderby: {
                    createdAt: OrderBy.Desc,
                  },
                },
                {
                  edges: {
                    node: {
                      id: true,
                      createdAt: true,
                      updatedAt: true,
                      response: true,
                    },
                  },
                },
              ],
            },
          },
        ],
      }),
  });

  const vehicle =
    vehicleRole === "PURCHASE"
      ? data?.transaction?.vehicle
      : data?.transaction?.tradeVehicle;

  const nonvalidatedCheck = vehicle?.vehicleRecallChecks?.edges?.at(0)?.node;

  // We only show information, when the recall check i newer than the vehicle to avoid showing stale information
  const validatedRecall =
    vehicle?.updatedAt && nonvalidatedCheck?.updatedAt
      ? isBefore(vehicle?.updatedAt, nonvalidatedCheck.updatedAt)
        ? nonvalidatedCheck
        : undefined
      : undefined;

  const fetchedAt = validatedRecall?.createdAt;
  const numberOfRecalls = (validatedRecall?.response as any)?.Count;

  return (
    <>
      {/* Only show the button if there are recalls */}
      {numberOfRecalls !== undefined && (
        <Button variant="TEXT_ONLY" onClick={() => setIsOpen(true)}>
          Recalls
        </Button>
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-modal"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25 backdrop-blur" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h2" className="text-heading-2">
                    Recalls
                  </Dialog.Title>

                  <div className="py-2 flex flex-row justify-center text-lg">
                    <div>
                      {fetchedAt && (
                        <h4 className="text-heading-4">
                          Found{" "}
                          <b
                            className={
                              (numberOfRecalls ?? 0) > 0
                                ? "text-negative"
                                : "text-green-500"
                            }
                          >
                            {numberOfRecalls ?? 0}
                          </b>{" "}
                          at {format(fetchedAt, "MM/dd/yyyy")}
                        </h4>
                      )}
                    </div>
                  </div>

                  <hr />
                  <div className="flex flex-col divide-y gap-6">
                    {/* The response is the raw JSON response from the recall check lookup.
                    We coerce to any here to be able to use it. Ideally we would have passed
                    it using Zod. This is something we want to do in the future */}
                    {(validatedRecall?.response as any)?.results?.map(
                      (result: any, i: number) => {
                        return (
                          <div key={i} className="flex flex-col space-y-2">
                            <div className="flex flex-row justify-between">
                              <div>At: {result.ReportReceivedDate}</div>
                              <div>
                                Park Vehicle: {result.parkIt ? "Yes" : "No"}
                              </div>
                            </div>
                            <div>
                              <b>Consequence:</b> {result?.Consequence}
                            </div>
                            <div>
                              <b>Remedy:</b> {result?.Remedy}
                            </div>
                            <div>
                              <b>Notes:</b> {result?.Notes}
                            </div>

                            <div className="flex flex-row justify-between">
                              <div>Action No.: {result?.NHTSAActionNumber}</div>
                              <div>
                                {result?.Make} {result?.Model}{" "}
                                {result?.ModelYear}
                              </div>
                              <div>
                                Campaign No.: {result?.NHTSACampaignNumber}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="flex flex-col space-y-2 p-4">
                    <div className="text-center text-sm">
                      Source{" "}
                      <a
                        href="https://www.safercar.gov/"
                        className="hover:underline"
                      >
                        www.safercar.gov
                      </a>{" "}
                      powered by NHTSA
                    </div>
                    <div className="text-center text-xs">
                      Information is based on make/model/year and does not
                      consider VIN specific recalls or repairs.
                    </div>
                  </div>
                  <div className="mt-4 flex flex-row justify-end w-full space-x-4">
                    <button
                      type="button"
                      className="button-primary"
                      onClick={() => setIsOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
