import { useForm } from "@tanstack/react-form";
import { Button } from "@thedealersconcierge/components";
import { useState } from "react";
import { toast } from "react-toastify";
import { resetForgottenPasswordAction } from "~/actions/resetForgottenPasswordAction";
import { UiState } from "./types";

type Props = {
  setUiState: (state: UiState) => void;
  email: string;
};

export default function ForgottenPasswordUpdatePassword({
  setUiState,
  email,
}: Props) {
  const [error, setError] = useState<null | string>(null);
  const form = useForm({
    defaultValues: {
      code: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
    onSubmit: async (value) => {
      try {
        if (value.newPassword !== value.newPasswordConfirmation) {
          throw new Error("Password do not match");
        }
        await resetForgottenPasswordAction(
          email,
          value.code,
          value.newPassword
        );
        toast.success("New password set. You can now login");
        setUiState("SIGN_IN");
      } catch (e: any) {
        setError(e.message);
      }
    },
  });

  return (
    <form.Provider>
      <form
        className="rounded-2xl shadow-2xl w-[444px] flex flex-col space-y-8 items-center p-6"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
      >
        <h2 className="text-2xl font-bold">Verification</h2>

        <p>
          Enter the code we sent to you and your new password to conclude
          password reset.
        </p>

        <form.Field
          name="code"
          children={(field) => (
            <input
              name={field.name}
              value={field.state.value ?? ""}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              placeholder="Code from email"
              type="text"
              className="w-full border border-[#E9EBED] p-4 rounded-lg"
            />
          )}
        />

        <form.Field
          name="newPassword"
          children={(field) => (
            <input
              name={field.name}
              value={field.state.value ?? ""}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              placeholder="New password"
              type="password"
              className="w-full border border-[#E9EBED] p-4 rounded-lg"
            />
          )}
        />

        <form.Field
          name="newPasswordConfirmation"
          children={(field) => (
            <input
              name={field.name}
              value={field.state.value ?? ""}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              placeholder="Confirm new password"
              type="password"
              className="w-full border border-[#E9EBED] p-4 rounded-lg"
            />
          )}
        />

        {error && (
          <div className="w-full rounded-md bg-red-500 text-white font-semibold p-4">
            {error}
          </div>
        )}

        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit, isSubmitting]) => (
            <Button
              label="Reset Password"
              type="submit"
              disabled={!canSubmit}
              isLoading={isSubmitting}
            />
          )}
        />
      </form>
    </form.Provider>
  );
}
