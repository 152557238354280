import classNames from "classnames";
import React from "react";

interface RadioButtonProps {
  label: string;
  value: string;
  checked: boolean;
  onChange: (value: string) => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  value,
  checked,
  onChange,
}) => {
  return (
    <label className="flex items-center mb-2 cursor-pointer">
      <input
        type="radio"
        value={value}
        checked={checked}
        onChange={() => onChange(value)}
        className="hidden"
      />
      <div
        className={classNames(
          "w-5 h-5 mr-2 rounded-full border-2 flex items-center justify-center",
          {
            "border-blue-500": checked,
            "border-gray-400": !checked,
          }
        )}
      >
        {checked && <div className="w-3 h-3 bg-blue-500 rounded-full"></div>}
      </div>
      <span className="text-gray-700">{label}</span>
    </label>
  );
};

export default RadioButton;
