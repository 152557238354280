import { useQuery } from "@tanstack/react-query";
import {
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import {
  DashboardUserRole,
  DashboardUserRoleSchema,
} from "@thedealersconcierge/lib/codecs/tdc";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { Plus } from "react-feather";
import Button from "~/components/design-system-components/Button";
import { useConfirmationModal } from "~/components/design-system-components/ConfirmationModal";
import PulsingStatusIndicator from "~/components/design-system-components/PulsingStatusIndicator";
import Table from "~/components/design-system-components/Table"; // Import the generic Table component
import DropdownCheckbox, {
  DropdownCheckboxOption,
} from "~/components/inputs/DropdownCheckbox";
import { getReadableRole } from "~/lib/enumReadable";
import meQuery from "~/query/meQuery";
import { useModals, useNavigate } from "~/router";
import { dealershipAtom } from "~/state";
import { Pagination } from "../_dashboardUtils";
import PaginationNavigator from "./_components/Pagination";
import { DashboardRoleBadge } from "./_components/UserRoleBadge";
import { UserDashboardSingularNode, userQuery } from "./_queries/userQuery";

export default function DashboardUser() {
  const [sorting, setSorting] = useState<SortingState>([]);
  const modals = useModals();
  const { data: meData } = useQuery(meQuery());
  const currentDealership = useAtomValue(dealershipAtom);
  const userGroup = meData?.me?.user?.userGroup?.edges?.at(0)?.node;
  const navigate = useNavigate();

  const dealershipId = currentDealership?.activeDealershipPerms.dealershipId;
  const isGroupAdmin = !!userGroup && userGroup.role === "ADMIN";
  const { showModal, ConfirmationModalComponent } = useConfirmationModal();
  const initiallyDisplayedRoles: DashboardUserRole[] = [
    "ADMIN",
    "BDC",
    "FNI_MANAGER",
    "SALES_MANAGER",
    "SALES_PERSON",
  ];
  const [displayRole, setDisplayRole] = useState<DashboardUserRole[]>(
    initiallyDisplayedRoles
  );
  const dealership = useAtomValue(dealershipAtom);
  const [page, setPage] = useState<Pagination>({
    currentPage: 1,
    pageSize: 10,
    direction: "after",
  });
  const { data, refetch } = useQuery(
    userQuery(displayRole, page, dealershipId)
  );

  const totalCount = data?.dealership?.users?.totalCount ?? 0;
  const users = data?.dealership?.users;

  const roleOptions: DropdownCheckboxOption<DashboardUserRole>[] =
    DashboardUserRoleSchema.options.map(
      (role) =>
        ({
          label: getReadableRole(role.value),
          value: DashboardUserRoleSchema.parse(role.value),
          key: role.value,
        }) as DropdownCheckboxOption<DashboardUserRole>
    );

  const onSelectRoleToDisplay = (seletedRoles: DashboardUserRole[]) => {
    if (seletedRoles.length <= 0) {
      setDisplayRole(initiallyDisplayedRoles);
    } else {
      setDisplayRole(seletedRoles);
    }

    setPage((p) => ({
      ...p,
      direction: "after",
      cursor: undefined,
      currentPage: 1,
    }));
  };

  // This is required because react table visibility state does not listen to state changes of isGroupAdmin
  useEffect(() => {
    setColumnVisibility({
      userGroupRole: isGroupAdmin,
      groupAccountStatus: isGroupAdmin,
    });
  }, [isGroupAdmin]);

  // This already count for super seeded admin roles of group user
  const canManageUser = permissionChecker(
    "manageUsers",
    dealership?.activeDealershipPerms
  );

  const userData = useMemo(
    () => users?.edges?.map((el) => el.node) ?? [],
    [data]
  );

  const columns = useMemo<ColumnDef<UserDashboardSingularNode>[]>(
    () => [
      {
        header: () => <span>First Name</span>,
        accessorKey: "userFirstName",
        cell: ({ row }) => (
          <div className="flex justify-start w-full">
            {row.original?.user?.firstName ?? "-"}
          </div>
        ),
        size: 120,
      },
      {
        header: () => <span>Last Name</span>,
        accessorKey: "userLastName",
        cell: ({ row }) => (
          <div className="flex justify-start w-full">
            {row.original?.user?.lastName ?? "-"}
          </div>
        ),
        size: 120,
      },
      {
        header: () => <span>Phone Number</span>,
        accessorKey: "userPhoneNumber",
        cell: ({ row }) => (
          <div className="flex justify-start w-full">
            {row.original?.user?.phoneNumber ?? "-"}
          </div>
        ),
        size: 120,
      },
      {
        header: () => <span>Email</span>,
        accessorKey: "userEmail",
        cell: ({ row }) => (
          <div className="flex justify-start w-full">
            {row.original?.user?.email ?? "-"}
          </div>
        ),
        size: 300,
      },
      {
        header: () => <span>Dealership Role</span>,
        accessorKey: "dealershipRole",
        cell: ({ row }) => (
          <div className="flex justify-start w-full">
            <DashboardRoleBadge role={row.original?.role} />
          </div>
        ),
        size: 140,
      },
      {
        header: () => <span>Account Status</span>,
        accessorKey: "dealershipAccountStatus",
        cell: ({ row }) => (
          <div className="flex justify-start w-full">
            <PulsingStatusIndicator
              active={row.original?.isSuspended ?? false}
              text={row.original?.isSuspended ? "Inactive" : "Active"}
            />
          </div>
        ),
        size: 140,
      },
      {
        header: () => <span>Group Role</span>,
        accessorKey: "userGroupRole",
        cell: ({ row }) => (
          <div className="flex justify-start w-full">
            {row.original?.user?.userGroup?.edges?.at(0)?.node?.role ? (
              <DashboardRoleBadge
                role={row.original.user.userGroup.edges.at(0)?.node?.role}
              />
            ) : (
              "-"
            )}
          </div>
        ),
        size: 140,
      },
      {
        header: () => <span>Account Status</span>,
        accessorKey: "groupAccountStatus",
        cell: ({ row }) => (
          <div className="flex justify-start w-full" onClick={() => {}}>
            <PulsingStatusIndicator
              active={
                row.original?.user?.userGroup?.edges?.at(0)?.node
                  ?.isSuspended ?? false
              }
              text={
                row.original?.user?.userGroup?.edges?.at(0)?.node?.isSuspended
                  ? "Inactive"
                  : "Active"
              }
            />
          </div>
        ),
        size: 140,
      },
    ],
    []
  );

  const [columnVisibility, setColumnVisibility] = useState({});
  const table = useReactTable({
    data: userData,
    columns,
    state: {
      sorting,
      columnVisibility,
      columnPinning: {
        left: ["userFirstName", "userLastName"],
      },
    },
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleRowClick = (row: UserDashboardSingularNode) => {
    if (!row) return;

    navigate("/dashboard/users/:userId", {
      params: {
        userId: row.user?.id ?? "no-user-id",
      },
    });
  };

  return (
    <div className="flex flex-grow flex-col space-y-4 pb-8 ">
      <div className="flex flex-row w-full justify-between gap-4">
        <div className="space-x-4">
          <DropdownCheckbox<DashboardUserRole>
            displayFilter={false}
            title="Select Role to Display"
            options={roleOptions}
            onSelect={onSelectRoleToDisplay}
            align="LEFT"
          />
        </div>

        {canManageUser && (
          <div className="flex flex-row items-center">
            <Button
              onClick={() => modals.open("/dashboard/users/create")}
              size="SMALL"
              variant="SECONDARY"
            >
              <div className="flex flex-row gap-2 items-center">
                <Plus className="h-4" />
                <p>New User</p>
              </div>
            </Button>
          </div>
        )}
      </div>

      <div>
        <Table
          table={table}
          size="LG"
          rowOnClick={handleRowClick}
          verticalSeparatorColumns={["userEmail", "dealershipAccountStatus"]}
        />
      </div>

      <div className="flex flex-row items-center w-full justify-end">
        <PaginationNavigator
          currentPage={page.currentPage}
          onPageChange={(page, direction) => {
            if (!users) return;
            setPage((p) => ({
              ...p,
              direction: direction,
              cursor:
                direction === "after"
                  ? users.edges?.at(users.edges.length - 1)?.cursor
                  : users.edges?.at(0)?.cursor,
              currentPage: page,
            }));
          }}
          pageSize={10}
          totalCount={totalCount}
        />
      </div>
      {ConfirmationModalComponent}
    </div>
  );
}
