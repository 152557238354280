import { z } from "zod";
import { UserRole } from "../tdc";
import { nullableObject } from "../util";
import { email } from "../validation/email";
import { phoneNumber } from "../validation/phonenumber";

// Only partially implemented, feel free to add in more fields as long
// as they are congruent with the prisma model
export const UserDealershipSchema = z.object({
  dealershipId: z.string().uuid(),
  role: UserRole,
});
export type UserDealershipSchema = z.TypeOf<typeof UserDealershipSchema>;

export const UserSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: phoneNumber(),
  email: email(),
  selfieCaptureId: z.string(),
});
export type UserSchema = z.TypeOf<typeof UserSchema>;

export const AdfXmlUserSchema = nullableObject(
  UserSchema.pick({
    email: true,
    phoneNumber: true,
  })
);

export type AdfXmlUserSchema = z.TypeOf<typeof AdfXmlUserSchema>;
