import classNames from 'classnames';
import { FC, ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';
import { InputBackgroundType } from './inputConfig';
import Select from './select';
import TextInput from './textInput';

type InputComponent = typeof TextInput | typeof Select;

const InputBase: FC<{
  inputComponent: ReactElement<InputComponent>;
  label: string;
  assistiveMessage: string;
  required: boolean;
  isBeingUpdated: boolean;
  errorMessage?: string;
  backgroundType?: InputBackgroundType;
  disabled: boolean;
  inputContainerClassName?: string;
}> = ({
  inputComponent,
  label,
  assistiveMessage,
  required,
  isBeingUpdated,
  errorMessage,
  backgroundType,
  disabled,
  inputContainerClassName
}) => {
  return (
    <div
      className={classNames('flex flex-col w-full space-y-spacing-01', {
        'opacity-40': disabled
      })}
    >
      <div className="flex flex-row items-center">
        <p className="body-02 text-secondary">{label}</p>

        {required && <p className="label-02 text-danger">*</p>}
      </div>

      <div
        className={twMerge(
          classNames('flex flex-row border-b border-primary items-center', {
            'border-interactive': isBeingUpdated,
            'border-danger': Boolean(errorMessage),
            'bg-secondary': backgroundType === 'DARK',
            'bg-primary': backgroundType === 'LIGHT'
          }),
          inputContainerClassName
        )}
      >
        {inputComponent}
      </div>

      <p
        className={classNames('label-02 font-normal text-start', {
          'text-tertiary': !errorMessage,
          'text-danger': Boolean(errorMessage)
        })}
      >
        {errorMessage ?? assistiveMessage}
      </p>
    </div>
  );
};

export default InputBase;
