// Matches the pulumi env names + dev
type ProdEnv = "dev" | "staging" | "prod" | "e2e-test";

export type Config = {
  backendUrl: string;
  cognito: {
    userPoolId?: string;
    clientId?: string;
  };
  useCognito: boolean;
  isProduction: boolean;
  prodEnv?: ProdEnv;
};

const config: Config = {
  cognito: {
    userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
    clientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
  },
  useCognito:
    Boolean(import.meta.env.VITE_COGNITO_CLIENT_ID) &&
    Boolean(import.meta.env.VITE_COGNITO_USER_POOL_ID),
  prodEnv: import.meta.env.VITE_PROD_ENV,
  backendUrl: import.meta.env.VITE_BACKEND_URL
    ? `${import.meta.env.VITE_BACKEND_URL}/graphql`
    : "http://127.0.0.1:3000/graphql",
  isProduction: import.meta.env.MODE === "production",
};

export default config;
