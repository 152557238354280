import { Dialog, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { Fragment, useState } from "react";
import { Role } from "~/__generated__/backend/zeus";
import { gqlMutationClient } from "~/lib/backend";
import { getReadableRole } from "~/lib/enumReadable";
import { Pagination } from "~/pages/(authenticated)/dashboard/_dashboardUtils";
import employeeSelector from "~/selectors/employeeSelector";
import { dealershipAtom } from "~/state";
import Typeahead, { TypeaheadOption } from "../inputs/Typeahead";

type Props = {
  transactionId: string;
  assignRole: Role;
  onDidAssign?: () => void;
  selectedUserId?: string;
  children: ({ openModal }: { openModal: () => void }) => any;
};

const readableRole = (tr: Role) => {
  switch (tr) {
    case Role.FNI_MANAGER:
      return "F&I manager";
    case Role.SALES_MANAGER:
      return "sales manager";
    case Role.SALES_PERSON:
      return "sales person";
    case Role.BDC:
      return "BDC";
  }
};

export default function AssignModal({
  children,
  assignRole,
  transactionId,
  onDidAssign,
  selectedUserId,
}: Props) {
  const dealership = useAtomValue(dealershipAtom);
  const dealershipId = dealership?.activeDealershipPerms.dealershipId;
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newAssignee, setNewAssignee] = useState<null | string>(null);
  const [preselectedUserId, setPreselectedUserId] = useState(selectedUserId);

  const NUMBER_OF_USER_DISPLAYED = 5;
  const [page, setPage] = useState<Pagination>({
    currentPage: 1,
    direction: "after",
    pageSize: 10,
  });

  const [searchName, setSearchName] = useState<string | undefined>(undefined);
  const { data, isLoading } = useQuery(
    employeeSelector(
      assignRole,
      dealershipId,
      page,
      searchName,
      preselectedUserId
    )
  );

  const userOptions: TypeaheadOption<string>[] = data?.dealership?.users?.edges
    ? data.dealership.users.edges.map((e) => ({
        key: e.node?.user?.id ?? "no-user-id",
        label: `${e.node?.user?.firstName} ${e.node?.user?.lastName}`,
        subtext: `${getReadableRole(e.node?.role)}`,
        value: e.node?.user?.id ?? "no-user-id",
      }))
    : [];

  const handleSelect = (selected: TypeaheadOption<string>) => {
    setNewAssignee(selected.value);
  };

  const closeModal = () => {
    if (!loading) {
      setIsOpen(false);
    }
  };

  const assignNewUser = async () => {
    try {
      setLoading(true);

      if (!newAssignee) {
        return;
      }

      await gqlMutationClient()({
        assignStaffToTransaction: [
          {
            transactionId,
            assigneeId: newAssignee,
            role: assignRole,
          },
          {
            __typename: true,
            "...on GraphQLError": {
              message: true,
            },
            "...on MutationAssignStaffToTransactionSuccess": {
              data: {
                status: true,
              },
            },
          },
        ],
      });

      if (onDidAssign) {
        onDidAssign();
      }

      setIsOpen(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {children({ openModal: () => setIsOpen(true) })}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25 backdrop-blur" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h2" className="text-heading-2">
                    Assign {readableRole(assignRole)} to transaction
                  </Dialog.Title>

                  <div className="flex flex-row gap-4 w-full justify-between items-center">
                    <div className="flex-grow mt-4 w-full h-full">
                      <Typeahead<string>
                        key={`user_dropdown_for_role_${assignRole}`}
                        options={userOptions ?? []}
                        onSelect={handleSelect}
                        onSearch={(searchString: string) => {
                          if (searchString.length <= 0) {
                            setSearchName(undefined);
                            setPreselectedUserId(undefined);
                          } else {
                            setSearchName(searchString);
                          }
                        }}
                        displayItemCount={NUMBER_OF_USER_DISPLAYED}
                        displayMoreType="WHEN_HAVE_REMAINING"
                        displayMoreHandler={() => {
                          setPage((prev) => {
                            return {
                              ...prev,
                              pageSize:
                                prev.pageSize + NUMBER_OF_USER_DISPLAYED,
                            };
                          });
                        }}
                        title={`Select ${getReadableRole(assignRole)}`}
                        isLoading={isLoading}
                        selectedItemKey={selectedUserId}
                        isOpenInitially={true}
                      />
                    </div>
                  </div>

                  <div className="mt-4 flex flex-row justify-end w-full space-x-4">
                    <button
                      type="button"
                      className="button-secondary"
                      disabled={loading}
                      onClick={closeModal}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      disabled={loading}
                      className="button-primary"
                      onClick={assignNewUser}
                      data-test-id="assign-modal-submit-button"
                    >
                      {loading ? "... Assigning" : "Assign"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
