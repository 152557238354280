import { confirmSignIn } from "aws-amplify/auth";
import config from "~/config";

export const newPasswordAction = async (
  newPassword: string
): Promise<"OK" | "MFA_REQUIRED_TOTP" | "MFA_REQUIRED_SMS"> => {
  if (!config.cognito.clientId) {
    throw new Error("Cognito client id is not set");
  }

  const res = await confirmSignIn({
    challengeResponse: newPassword,
  });

  if (res.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_SMS_CODE") {
    return "MFA_REQUIRED_SMS";
  }
  if (res.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
    return "MFA_REQUIRED_TOTP";
  }

  return "OK";
};
