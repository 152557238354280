import { TransactionStatusSchema } from "@thedealersconcierge/lib/codecs/tdc";
import { FC } from "react";
import { formatPhoneNumber } from "react-phone-number-input";
import InitialOrProfileImage from "~/components/InitialOrProfileImage";
import StatusBadge from "~/components/badges/StatusBadge";
import TransactionSourceBadge from "~/components/badges/TransactionSourceBadge";
import { TransactionQueryType } from "../_queries/transactionQuery";

const TransactionCustomerPersonalInformationBar: FC<{
  isPrimaryBuyer: boolean;
  transaction: TransactionQueryType["transaction"];
}> = ({ isPrimaryBuyer, transaction }) => {
  const customer = isPrimaryBuyer ? transaction?.buyer : transaction?.coBuyer;

  const firstName = customer?.firstName;
  const lastName = customer?.lastName;
  const email = customer?.user?.email;
  const phoneNumber = customer?.user?.phoneNumber;

  const transactionStatus = TransactionStatusSchema.parse(transaction?.status);

  const latestProfilePictureUrl = customer?.selfieCapture?.url ?? "";

  const residentialAddress = customer?.residentialAddresses?.edges?.find(
    (edge) => edge.node?.timelinePosition === 0
  )?.node?.fullAddress;

  const vehicleRegistrationAddress = customer?.wantsRegistrationAtSameAddress
    ? (residentialAddress ?? "-")
    : (customer?.vehicleRegistrationAddress?.fullAddress ?? "-");

  return (
    <div className="rounded-2xl bg-white shadow-md p-8 flex flex-col lg:flex-row justify-between">
      <div className="flex flex-col lg:flex-row justify-start w-full gap-16">
        <div>
          <InitialOrProfileImage
            firstName={firstName ?? ""}
            lastName={lastName ?? ""}
            profilePictureUrl={latestProfilePictureUrl}
          />
        </div>

        <div className="w-full space-y-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row items-center justify-between w-full">
              <div className="flex flex-row gap-6 items-center">
                <p className="text-heading-2">
                  {isPrimaryBuyer ? "Primary Buyer" : "Co-Buyer"}
                </p>

                <StatusBadge
                  status={transactionStatus}
                  size="SMALL"
                  dataTestid="transaction-personal-bar-status"
                />

                {transaction?.source && (
                  <TransactionSourceBadge
                    source={transaction.source}
                    size="SMALL"
                  />
                )}
              </div>
            </div>
            <p
              className="text-heading-1 font-normal text-secondary"
              data-test-id="transaction-personal-info-name"
            >{`${firstName ?? ""} ${lastName ?? ""}`}</p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-16 lg:gap-4 pt-8">
            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-4 gap-2">
                <p className="text-heading-2 col-span-1 text-tertiary">Phone</p>
                <p className="text-heading-3 col-span-3 text-primary whitespace-nowrap overflow-ellipsis">
                  {phoneNumber
                    ? formatPhoneNumber(phoneNumber)
                    : "No Phone Number"}
                </p>
              </div>

              <div className="grid grid-cols-4 gap-2">
                <p className="text-heading-2 col-span-1 text-tertiary">Email</p>
                {email && (
                  <div className="group flex flex-row justify-between items-center text-block text-very-dark-gray gap-2">
                    <a
                      className="overflow-ellipsis text-primary-blue"
                      title={email}
                      href={`mailto:${email}`}
                    >
                      {email}
                    </a>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <p className="text-heading-2 text-tertiary">Personal Address</p>

              <p className="text-heading-3 text-primary overflow-hidden overflow-ellipsis w-full">
                {residentialAddress ?? "-"}
              </p>
            </div>

            <div className="flex flex-col gap-4">
              <p className="text-heading-2 text-tertiary">
                Vehicle Registration Address
              </p>

              <p className="text-heading-3 text-primary overflow-hidden overflow-ellipsis w-full">
                {vehicleRegistrationAddress}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionCustomerPersonalInformationBar;
