import classNames from "classnames";
import { debounce } from "lodash";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import Spinner from "../Spinner";

const Typeahead = <T,>({
  options,
  selectedItemKey,
  onSelect,
  displayMoreHandler,
  title,
  displayItemCount = 10,
  onSearch,
  isLoading = false,
  displayMoreType = "DISABLED",
  isOpenInitially = false,
}: TypeaheadProps<T>) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [displayedOptionsCount, setDisplayedOptionsCount] =
    useState(displayItemCount);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Pre select option with existing key
  useEffect(() => {
    if (selectedItemKey && inputValue.length <= 0) {
      const selectedOption = options.find(
        (option) => option.key === selectedItemKey
      );
      if (selectedOption) {
        setInputValue(selectedOption.label);
      }
    }

    // Only open modal initially when no preselected item
    if (isOpenInitially && !selectedItemKey) {
      openDropdown();
    }
  }, []);

  const openDropdown = () => {
    setIsDropdownOpen(true);
    filterOptions(inputValue);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target;
    if (!(target instanceof Node)) return;

    if (
      inputRef.current &&
      !inputRef.current.contains(target) &&
      dropdownMenuRef.current &&
      !dropdownMenuRef.current.contains(target)
    ) {
      closeDropdown();
    }
  };

  const handleOptionClick = (option: TypeaheadOption<T>) => {
    setInputValue(option.label);
    closeDropdown();
    onSelect(option);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    if (!isDropdownOpen) openDropdown();
    debouncedFilterOptions(value);
  };

  const filterOptions = async (input: string) => {
    if (onSearch) {
      await onSearch(input);
    } else {
      if (!input.length) {
        setFilteredOptions(options);
        return;
      }

      const lowercasedInput = input.toLowerCase();

      const filtered = options.filter(
        (option) =>
          option.label.toLowerCase().includes(lowercasedInput) ||
          option.subtext.toLowerCase().includes(lowercasedInput)
      );
      setFilteredOptions(filtered);
      setDisplayedOptionsCount(displayItemCount); // Reset display count when filtering
    }
  };

  const debouncedFilterOptions = useCallback(debounce(filterOptions, 200), [
    options,
  ]);

  const hasRemainingItems = filteredOptions.length > displayedOptionsCount;
  const shouldDisplayMoreButton =
    displayMoreType === "ALWAYS_EXISTS" ||
    (displayMoreType === "WHEN_HAVE_REMAINING" && hasRemainingItems);

  const handleDisplayMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent default button action
    if (displayMoreHandler) {
      displayMoreHandler();
    }
    setDisplayedOptionsCount(displayedOptionsCount + displayItemCount);
  };

  const getHighlightedText = (text: string, highlight: string) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts.map((part, i) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <b key={i}>{part}</b>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  return (
    <div
      className="relative inline-block text-left h-full w-full"
      key={`typeahead_${title}`}
      id={`typeahead_${title}`}
      data-test-id={`typeahead-${title}`}
    >
      <div
        className="relative w-full"
        style={{
          zIndex: 100,
        }}
      >
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onClick={openDropdown}
          placeholder={title ?? "Select Item"}
          className="w-full px-6 py-4 text-sm font-medium text-gray-700 bg-white border rounded-md focus:outline-none pr-10"
          data-test-id={`typeahead-${title}-text-input`}
        />
        {isLoading && (
          <div
            className="absolute right-4 top-1/2 transform -translate-y-1/2"
            data-test-id={"typahead-spinner"}
          >
            <Spinner className="w-6" />
          </div>
        )}
      </div>

      {isDropdownOpen && (
        <div
          ref={dropdownMenuRef}
          className={classNames(
            "absolute left-0 w-full rounded-md shadow-lg bg-white transition-opacity duration-300 ease-in-out",
            {
              "opacity-0": !isDropdownOpen,
              "opacity-100": isDropdownOpen,
            }
          )}
          // We set a static max height to determine the initial height of the dropdown
          style={{
            zIndex: 110,
            maxHeight: `${displayItemCount * 68}px`,
            overflowY: "auto",
          }}
          data-test-id={`typeahead-${title}-dropdown-body`}
        >
          <div className="py-1">
            {filteredOptions.length > 0 ? (
              filteredOptions
                .slice(0, displayedOptionsCount)
                .map((option, index) => (
                  <div
                    key={option.key}
                    className="flex flex-col px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleOptionClick(option)}
                    data-test-id={`typeahead-${title}-dropdown-item-body-${index}`}
                  >
                    <span
                      data-test-id={`typeahead-${title}-dropdown-item-content-${index}`}
                    >
                      {getHighlightedText(option.label, inputValue)}
                    </span>
                    <span
                      className="text-xs text-gray-500"
                      data-test-id={`typeahead-${title}-dropdown-item-sub-content-${index}`}
                    >
                      {getHighlightedText(option.subtext, inputValue)}
                    </span>
                  </div>
                ))
            ) : (
              <div
                className="px-4 py-2 text-sm text-gray-700 cursor-default"
                data-test-id={`typeahead-${title}-dropdown-no-item`}
              >
                No items available
              </div>
            )}
          </div>

          {shouldDisplayMoreButton && (
            <div className="border-t border-gray-200">
              <button
                onClick={handleDisplayMore}
                className="block w-full px-4 py-2 text-sm text-blue-500 hover:text-blue-300 focus:outline-none"
                data-test-id={`typeahead-${title}-dropdown-display-more-button`}
              >
                Display more
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export type TypeaheadOption<T> = {
  key: string;
  label: string;
  subtext: string;
  value: T;
};

type TypeaheadProps<T> = {
  options: TypeaheadOption<T>[];
  selectedItemKey?: string;
  onSelect: (selected: TypeaheadOption<T>) => void;
  onSearch?: (inputValue: string) => void;
  displayMoreHandler?: () => void;
  displayMoreType?: "ALWAYS_EXISTS" | "WHEN_HAVE_REMAINING" | "DISABLED";
  title?: string;
  displayItemCount?: number;
  isLoading?: boolean;
  isOpenInitially?: boolean;
};

export default Typeahead;
