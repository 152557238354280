import { useForm } from "@tanstack/react-form";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Button from "~/components/Button";
import FormInputField from "~/components/FormInputField";
import { gqlMutationClient } from "~/lib/backend";
import { queryClient } from "~/lib/query";
import { Link, useNavigate, useParams } from "~/router";
import { dealershipAtom } from "~/state";
import { transactionQuery } from "../_queries/transactionQuery";

const AddCoBuyerPage = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { transactionId } = useParams(
    "/dashboard/transaction/:transactionId/deal"
  );
  const dealership = useAtomValue(dealershipAtom);
  const { data } = useQuery(
    transactionQuery(transactionId, dealership?.activeDealershipPerms)
  );
  const transaction = data?.transaction;

  const handleSubmit = async (values: {
    [key: string]: string | number | boolean;
  }) => {
    setIsSubmitting(true);

    const resp = await gqlMutationClient()({
      createCoBuyer: [
        {
          transactionId,
          coBuyer: {
            firstName: values["firstName"].toString(),
            lastName: values["lastName"].toString(),
            email: values["email"].toString(),
            phoneNumber: values["phoneNumber"].toString(),
          },
        },
        {
          __typename: true,
          "...on GraphQLError": {
            message: true,
          },
          "...on MutationCreateCoBuyerSuccess": {
            data: {
              status: true,
            },
          },
        },
      ],
    });

    if (
      !resp.createCoBuyer ||
      resp.createCoBuyer.__typename === "GraphQLError"
    ) {
      console.error(resp.createCoBuyer?.message ?? "Unexpected error");

      window.alert(
        "Could not add co-buyer. Please try again or contact support."
      );
    } else {
      queryClient.invalidateQueries({
        queryKey: ["transaction", transactionId],
      });
      navigate("/dashboard/transaction/:transactionId", {
        params: { transactionId },
      });
    }

    setIsSubmitting(false);
  };
  const handleCancel = () => {
    navigate("/dashboard/transaction/:transactionId", {
      params: { transactionId },
    });
  };
  const form = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    },
    onSubmit: handleSubmit,
  });

  return (
    <div className="flex flex-col space-y-4 max-h-dvh overflow-hidden">
      <BreadCrumbsContainer>
        <BreadCrumb title="Transaction">
          <Link to={"/dashboard"}>Transactions</Link>
        </BreadCrumb>

        <BreadCrumb title="Users">
          <Link
            to={"/dashboard/transaction/:transactionId"}
            params={{ transactionId }}
          >
            {transaction?.title}
          </Link>
        </BreadCrumb>

        <BreadCrumb title="Add Co-Buyer" />
      </BreadCrumbsContainer>

      <div className="flex justify-center p-8 overflow-y-scroll">
        <div className="flex w-1/2 min-w-fit flex-col bg-white rounded-xl shadow-md p-10 space-y-6">
          <h2 className="text-heading-2">Add Co-Buyer</h2>

          <form.Provider>
            <form
              className="flex flex-col space-y-8"
              onSubmit={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                void form.handleSubmit();
              }}
            >
              <div className="flex flex-col space-y-6">
                <FormInputField
                  form={form}
                  fieldName="firstName"
                  type="text"
                  labelText="First name"
                  placeholder="First name"
                  subtitleText="First name"
                  disabled={isSubmitting}
                />

                <FormInputField
                  form={form}
                  fieldName="lastName"
                  type="text"
                  labelText="Last name"
                  placeholder="Last name"
                  subtitleText="Last name"
                  disabled={isSubmitting}
                />

                <FormInputField
                  form={form}
                  fieldName="email"
                  type="email"
                  labelText="Email"
                  placeholder="Email"
                  subtitleText="Email"
                  disabled={isSubmitting}
                />

                {/*  */}
                <form.Field
                  name="phoneNumber"
                  onChange={(v) =>
                    !v ? "Phone Number is required" : undefined
                  }
                  children={(field) => (
                    <>
                      <label htmlFor={field.name} className="hidden">
                        Phone Number
                      </label>
                      <PhoneInput
                        defaultCountry="US"
                        placeholder="Phone Number"
                        name={field.name}
                        value={field.state.value ?? ""}
                        onBlur={field.handleBlur}
                        onChange={(v) => field.handleChange(v ?? "")}
                        className={classNames(
                          "w-full px-3 text-placeholder appearance-none outline-none border-b-[0.5px] border-light-gray py-2",
                          {
                            "bg-gray-300 text-dark-gray": isSubmitting,
                          }
                        )}
                      />
                    </>
                  )}
                />
              </div>

              <div className="flex flex-row justify-between">
                <Button
                  variant="TERTIARY"
                  type="button"
                  disabled={isSubmitting}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>

                <form.Subscribe
                  selector={(state) => [state.values]}
                  children={([values]) => {
                    const canContinue =
                      !!values.firstName &&
                      !!values.lastName &&
                      !!values.email &&
                      !!values.phoneNumber;

                    return (
                      <Button
                        type="submit"
                        disabled={!canContinue}
                        loading={isSubmitting}
                      >
                        Save
                      </Button>
                    );
                  }}
                />
              </div>
            </form>
          </form.Provider>
        </div>
      </div>
    </div>
  );
};

export default AddCoBuyerPage;
