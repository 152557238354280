import { Tooltip } from "@thedealersconcierge/components";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import { useAtom } from "jotai";
import { FC } from "react";
import { Download, Eye, Printer, Upload, X } from "react-feather";
import { useTranslation } from "react-i18next";
import { TransactionStatus } from "~/__generated__/backend/zeus";
import Button from "~/components/design-system-components/Button";
import { dealershipAtom } from "~/state";

type ActionBarProps = {
  selectedCount: number;
  onSelectAll: () => void;
  onView: () => void;
  onDownload: (() => void) | (() => Promise<void>);
  onPrint: (() => void) | (() => Promise<void>);
  onPushToDMS: (() => void) | (() => Promise<void>);
  onCloseActionBar: () => void;
  showPushToDms?: boolean;
  isPushingToDms?: boolean;
  cdkDmsDealId?: string;
  transactionStatus?: TransactionStatus | undefined;
  hasEnabledPostPurchaseDocs: boolean;
};

const ActionBar: FC<ActionBarProps> = ({
  selectedCount,
  onSelectAll,
  onView,
  onDownload,
  onPrint,
  onPushToDMS,
  onCloseActionBar,
  showPushToDms,
  isPushingToDms,
  cdkDmsDealId,
  transactionStatus,
  hasEnabledPostPurchaseDocs,
}) => {
  const { t } = useTranslation();
  const [dealership] = useAtom(dealershipAtom);
  const canDownloadDealJacket = permissionChecker(
    "downloadDealJacket",
    dealership?.activeDealershipPerms
  );
  const disablePushToDms =
    // Transaction status check is for typing. We still want to show the action bar when the transaction status is still undefined.
    !transactionStatus ||
    // The CDK DMS deal ID has to be set
    !cdkDmsDealId ||
    // When the dealership uses post purchase docs, then the transaction has to be "Delivered" in order to push the docs to the DMS
    (hasEnabledPostPurchaseDocs &&
      transactionStatus !== TransactionStatus.DELIVERED) ||
    // When the dealership does not use post purchase docs, then the transaction has to be either "Ready for F&I" or "Delivered"
    (!hasEnabledPostPurchaseDocs &&
      ![TransactionStatus.READY_FOR_FNI, TransactionStatus.DELIVERED].includes(
        transactionStatus
      ));

  return (
    <div className="flex items-center justify-between px-6 py-3 bg-blue-100 rounded-md shadow-sm">
      <div className="flex flex-row space-x-4 items-center">
        <div className="text-sm font-medium text-gray-700">
          {selectedCount} {t("Selected")}
        </div>
        <Button variant="GHOST" onClick={onSelectAll}>
          {t("Select All")}
        </Button>
      </div>

      <div className="flex flex-row space-x-2 items-center">
        <Button onClick={onView} iconLeft={<Eye size={16} />} variant="GHOST">
          <span>{t("View")}</span>
        </Button>

        {canDownloadDealJacket && (
          <Button
            onClick={onDownload}
            iconLeft={<Download size={16} />}
            variant="GHOST"
          >
            <span>{t("Download")}</span>
          </Button>
        )}

        <Button
          onClick={onPrint}
          iconLeft={<Printer size={16} />}
          variant="GHOST"
        >
          <span>{t("Print")}</span>
        </Button>

        {showPushToDms && (
          <Tooltip
            anchor={
              <div>
                <Button
                  onClick={onPushToDMS}
                  iconLeft={<Upload size={16} />}
                  variant="GHOST"
                  disabled={disablePushToDms}
                  loading={isPushingToDms}
                >
                  <span>{t("Push to DMS")}</span>
                </Button>
              </div>
            }
            hide={!disablePushToDms}
            content={
              <p>
                {!cdkDmsDealId
                  ? t("The Deal Number is not set")
                  : hasEnabledPostPurchaseDocs
                    ? t("Transaction needs to be 'Delivered'")
                    : t("Transaction needs to be 'Ready for F&I'")}
              </p>
            }
          />
        )}

        <div className="cursor-pointer px-2 py-2" onClick={onCloseActionBar}>
          <X className="text-blue-400 hover:text-blue-600" />
        </div>
      </div>
    </div>
  );
};

export default ActionBar;
