import { FC } from "react";
import { getInitials } from "~/lib/helpers";

const InitialOrProfileImage: FC<{
  profilePictureUrl?: string;
  firstName: string;
  lastName: string;
}> = ({ profilePictureUrl, firstName, lastName }) => {
  const renderProfilePictOrInitials = () => {
    if (!profilePictureUrl) {
      return (
        <div className="w-48 h-48 object-cover flex items-center justify-center rounded-lg text-3xl font-roboto font-medium bg-very-light-gray text-dark-gray">
          {getInitials(firstName, lastName)}
        </div>
      );
    }

    return (
      <img
        className="rounded-lg w-48 h-48 object-cover"
        src={profilePictureUrl}
        style={{ objectFit: "cover" }}
      />
    );
  };

  return (
    <div className="flex flex-row space-x-2 w-full h-full justify-center">
      <div className="flex items-center justify-center w-48 h-48">
        {renderProfilePictOrInitials()}
      </div>
    </div>
  );
};

export default InitialOrProfileImage;
