import { FC } from "react";

const CircleLineThroughIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>Circle line through icon</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.375 18.3333C16.0799 18.3333 19.0833 15.3299 19.0833 11.625C19.0833 7.92009 16.0799 4.91667 12.375 4.91667C8.67009 4.91667 5.66667 7.92009 5.66667 11.625C5.66667 15.3299 8.67009 18.3333 12.375 18.3333ZM12.375 20.25C17.1385 20.25 21 16.3885 21 11.625C21 6.86154 17.1385 3 12.375 3C7.61154 3 3.75 6.86154 3.75 11.625C3.75 16.3885 7.61154 20.25 12.375 20.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2663 6.56753L8.24545 17.1092L6.9206 15.7241L17.9414 5.18247L19.2663 6.56753Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CircleLineThroughIcon;
